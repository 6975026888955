import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  subscriptionData: [],
  isFetchingSubscription: false,
  isSuccessSubscription: false,
  isErrorSubscription: false,
  errorMessageSubscription: "",

  createSubscription: {},
  isSuccessCreateSubscription: false,
  isErrorCreateSubscription: false,
  errorMessageCreateSubscription: "",

  deleteSubscription: {},
  isSuccessDeleteSubscription: false,
  isErrorDeleteSubscription: false,
  errorMessageDeleteSubscription: "",

  editSubscription: {},
  isSuccessEditSubscription: false,
  isErrorEditSubscription: false,
  errorMessageEditSubscription: "",
};

export const getSubscriptionData = createAsyncThunk(
  "subscription/listSubscibeEntry",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/subscription/listSubscibeEntry`;

      if (udata) {
        url = `/subscription/listSubscibeEntry${udata}`;
      }

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const addSubscriptionData = createAsyncThunk(
  "subscription/save",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/subscription/addSubscriptionEntry";

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const editSubscriptionData = createAsyncThunk(
  "subscription/edit-subscription",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/subscription/updateSubscribeEntry";

      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubscriptionData = createAsyncThunk(
  "subscription/delete-subscription",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const response = await axios.delete(
        `/subscription/deleteSubscribeEntry/${udata.id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      let data = response;
      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const subscriptionSlice = createSlice({
  name: "Subscription",
  initialState,
  reducers: {
    clearStateSubscription: (state, { payload }) => {
      state.subscriptionData = [];
      state.isFetchingSubscription = false;
      state.isSuccessSubscription = false;
      state.isErrorSubscription = false;
      state.errorMessageSubscription = "";
    },
    clearStateCreateSubscription: (state, { payload }) => {
      state.createSubscription = {};
      state.isFetchingSubscription = false;
      state.isSuccessCreateSubscription = false;
      state.isErrorCreateSubscription = false;
      state.errorMessageCreateSubscription = "";
    },
    clearStateDeleteSubscription: (state, { payload }) => {
      state.deleteSubscription = {};
      state.isFetchingSubscription = false;
      state.isSuccessDeleteSubscription = false;
      state.isErrorDeleteSubscription = false;
      state.errorMessageDeleteSubscription = "";
    },
    clearStateEditSubscription: (state, { payload }) => {
      state.editSubscription = {};
      state.isFetchingSubscription = false;
      state.isSuccessEditSubscription = false;
      state.isErrorEditSubscription = false;
      state.errorMessageEditSubscription = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionData.fulfilled, (state, action) => {
        state.subscriptionData = action.payload.data;
        state.isFetchingSubscription = false;
        state.isSuccessSubscription = true;
        state.isErrorSubscription = false;
      })
      .addCase(getSubscriptionData.pending, (state) => {
        state.isFetchingSubscription = true;
      })
      .addCase(getSubscriptionData.rejected, (state, action) => {
        state.subscriptionData = [];
        state.isFetchingSubscription = false;
        state.isSuccessSubscription = false;
        state.isErrorSubscription = true;
        state.errorMessageSubscription = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(addSubscriptionData.fulfilled, (state, action) => {
        state.createSubscription = action.payload;
        state.isFetchingSubscription = false;
        state.isSuccessCreateSubscription = true;
        state.isErrorCreateSubscription = false;
      })
      .addCase(addSubscriptionData.pending, (state) => {
        state.isFetchingSubscription = true;
      })
      .addCase(addSubscriptionData.rejected, (state, action) => {
        state.createSubscription = {};
        state.isFetchingSubscription = false;
        state.isSuccessCreateSubscription = false;
        state.isErrorCreateSubscription = true;
        state.errorMessageCreateSubscription = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(editSubscriptionData.fulfilled, (state, action) => {
        state.editSubscription = action.payload;
        state.isFetchingSubscription = false;
        state.isSuccessEditSubscription = true;
        state.isErrorEditSubscription = false;
      })
      .addCase(editSubscriptionData.pending, (state) => {
        state.isFetchingSubscription = true;
      })
      .addCase(editSubscriptionData.rejected, (state, action) => {
        state.editSubscription = {};
        state.isFetchingSubscription = false;
        state.isSuccessEditSubscription = false;
        state.isErrorEditSubscription = true;
        state.errorMessageEditSubscription = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(deleteSubscriptionData.fulfilled, (state, action) => {
        state.deleteSubscription = action.payload;
        state.isFetchingSubscription = false;
        state.isSuccessDeleteSubscription = true;
        state.isErrorDeleteSubscription = false;

        if (state.subscriptionData.length === 1) state.subscriptionData = [];
      })
      .addCase(deleteSubscriptionData.pending, (state) => {
        state.isFetchingSubscription = true;
      })
      .addCase(deleteSubscriptionData.rejected, (state, action) => {
        state.deleteSubscription = {};
        state.isFetchingSubscription = false;
        state.isSuccessDeleteSubscription = false;
        state.isErrorDeleteSubscription = true;
        state.errorMessageDeleteSubscription = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const {
  clearStateSubscription,
  clearStateCreateSubscription,
  clearStateDeleteSubscription,
  clearStateEditSubscription,
} = subscriptionSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateSubscription());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateSubscription());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteSubscription());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditSubscription());
  };
}

export const subscriptionSelector = (state) => state.subscription;

export default subscriptionSlice.reducer;
