import { Suspense } from "react";
import Container from "../../Layouts/Container";

const SuspenseLoader = ({ children }) => {
  return (
    <>
      <Suspense fallback={globalThis.isAuthenticated ? <Container /> : <></>}>
        {children}
      </Suspense>
    </>
  );
};
export default SuspenseLoader;
