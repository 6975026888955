import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../redux/axios";

const initialState = {
  isFetchingResend: false,
  errorMessageResend: "",
  isFetchingResendDlr: false,
  errorMessageResendDlr: "",
};

export const getResend = createAsyncThunk(
  "msg/resend",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const url = `/sms/resend`;
      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getResendDlr = createAsyncThunk(
  "msg/resend-dlr",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const url = `/sms/resendDlr`;
      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resendSlice = createSlice({
  name: "resend",
  initialState,
  reducers: {
    clearStateResend: (state) => {
      state.isFetchingResend = false;
      state.isFetchingResendDlr = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResend.pending, (state) => {
        state.isFetchingResend = true;
      })
      .addCase(getResend.fulfilled, (state, action) => {
        state.isFetchingResend = false;
        state.errorMessageResend = "";
      })
      .addCase(getResend.rejected, (state, action) => {
        state.isFetchingResend = false;
        state.errorMessageResend = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getResendDlr.pending, (state) => {
        state.isFetchingResendDlr = true;
      })
      .addCase(getResendDlr.fulfilled, (state, action) => {
        state.isFetchingResendDlr = false;
        state.errorMessageResendDlr = "";
      })
      .addCase(getResendDlr.rejected, (state, action) => {
        state.isFetchingResendDlr = false;
        state.errorMessageResendDlr = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const resendSelector = (state) => state.resend;

export const { clearStateResend } = resendSlice.actions;

export default resendSlice.reducer;
