import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  bsfmData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  createBsfm: {},
  isSuccessCreateBsfm: false,
  isErrorCreateBsfm: false,
  isErrorMessageCreateBsfm: "",

  deleteBsfm: {},
  isSuccessDeleteBsfm: false,
  isErrorDeleteBsfm: false,
  isErrorMessageDeleteUser: "",

  editBsfm: {},
  isSuccessEditBsfm: false,
  isErrorEditBsfm: false,
  isErrorMessageEditBsfm: "",

  isFetchingSingleBsfm: false,
  singleBsfmData: {},
  isSucessSingleBsfm: false,
  isErrorSingleBsfm: false,
  isErrorMessageSingleBsfm: "",

  isFetchingSMS: false,
  isSuccessbsfm: false,
  BsfmData: [],

  //updateApplyFirewall

  updateApplyFirewallData: [],
  isFetchingFirewall: false,
  isSuccessFirewall: false,
};

export const getBsfmData = createAsyncThunk(
  "bsfm/get-bsfm-data",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/firewall/list/profiles${udata || ""}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createBsfmData = createAsyncThunk(
  "bsfm/create-bsfm",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/firewall/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteBsfmData = createAsyncThunk(
  "bsfm/delete-profile",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/firewall/delete?profilename=${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editBsfmData = createAsyncThunk(
  "bsfm/update-template",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/firewall/update`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSingleBsfmData = createAsyncThunk(
  "bsfm/get-single",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        "/firewall/profile?id=" + udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const changeBsfmProfileFlag = createAsyncThunk(
  "bsfm/change-flag",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        "/firewall/update-flag?flag=" + udata,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getPreBsfmInformation = createAsyncThunk(
  "bsfm/get-pre-information",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        "/firewall/profile/pre-information",
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateApplyFirewall = createAsyncThunk(
  "bsfm/update/firewall",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(
        "/firewall/update/priority",
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("Unexpected error:", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const bsfmSlice = createSlice({
  name: "bsfm",
  initialState,
  reducers: {
    clearStateBsfm: (state) => {
      state.bsfmData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateBsfm: (state) => {
      state.createBsfm = {};
      state.isFetching = false;
      state.isSuccessCreateBsfm = false;
      state.isErrorCreateBsfm = false;
      state.isErrorMessageCreateBsfm = "";
    },
    clearStateDeleteBsfm: (state) => {
      state.deleteBsfm = {};
      state.isFetching = false;
      state.isSuccessDeleteBsfm = false;
      state.isErrorDeleteBsfm = false;
      state.isErrorMessageDeleteBsfm = "";
    },
    clearStateEditBsfm: (state) => {
      state.editBsfm = {};
      state.isFetching = false;
      state.isSuccessEditBsfm = false;
      state.isErrorEditBsfm = false;
      state.isErrorMessageEditBsfm = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBsfmData.fulfilled, (state, action) => {
        state.bsfmData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getBsfmData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getBsfmData.rejected, (state, action) => {
        state.bsfmData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(createBsfmData.fulfilled, (state, action) => {
        state.createUser = action.payload;
        state.isFetching = false;
        state.isSuccessCreateUser = true;
      })
      .addCase(createBsfmData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createBsfmData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateUser = true;
        state.isErrorMessageCreateUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(deleteBsfmData.fulfilled, (state, action) => {
        state.deleteUser = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteUser = true;

        if (state.bsfmData.length === 1) state.bsfmData = [];
      })
      .addCase(deleteBsfmData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteBsfmData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteUser = true;
        state.isErrorMessageDeleteUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(editBsfmData.fulfilled, (state, action) => {
        state.editUser = action.payload;
        state.isFetching = false;
        state.isSuccessEditUser = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(editBsfmData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editBsfmData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditUser = true;
        state.isErrorMessageEditUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getSingleBsfmData.fulfilled, (state, action) => {
        state.singleBsfmData = action.payload;
        state.isFetchingSingleBsfm = false;
        state.isSucessSingleBsfm = true;
      })
      .addCase(getSingleBsfmData.pending, (state) => {
        state.isFetchingSingleBsfm = true;
      })
      .addCase(getSingleBsfmData.rejected, (state, action) => {
        state.isFetchingSingleBsfm = false;
        state.isErrorSingleBsfm = true;
        state.isErrorMessageSingleBsfm = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(changeBsfmProfileFlag.fulfilled, (state, action) => {
        state.smsIdentify = action.payload;
        state.isFetchingSMS = false;
        state.isSuccessSmsIdentify = true;
        state.isErrorMessageSmsIdentify = action.payload.data;
      })
      .addCase(changeBsfmProfileFlag.pending, (state) => {
        state.isFetchingSMS = true;
      })
      .addCase(changeBsfmProfileFlag.rejected, (state, action) => {
        state.isFetchingSMS = false;
        state.isErrorSmsIdentify = true;
        state.isErrorMessageSmsIdentify = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getPreBsfmInformation.fulfilled, (state, action) => {
        state.isFetching = false;
        (state.BsfmData = action.payload.data), (state.isSuccessbsfm = true);
      })
      .addCase(getPreBsfmInformation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getPreBsfmInformation.rejected, (state, action) => {
        state.isFetching = false;
        state.BsfmData = [];
      })
      .addCase(updateApplyFirewall.fulfilled, (state, action) => {
        state.isSuccessFirewall = true;
        state.updateApplyFirewallData = action.payload.data;
        state.isErrorFirewall = false;
      })
      .addCase(updateApplyFirewall.pending, (state, action) => {
        state.isSuccessFirewall = false;
        state.isErrorFirewall = false;
      })
      .addCase(updateApplyFirewall.rejected, (state, action) => {
        state.isSuccessFirewall = false;
        state.updateApplyFirewallData = [];
        state.isErrorFirewall = true;
      });
  },
});

export const {
  clearStateBsfm,
  clearStateCreateBsfm,
  clearStateDeleteBsfm,
  clearStateEditBsfm,
} = bsfmSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateBsfm());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateBsfm());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteBsfm());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditBsfm());
  };
}

export const bsfmSelector = (state) => state.bsfm;

export default bsfmSlice.reducer;
