import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import Container from "../../Layouts/Container";
import { isArray } from "../../lib/utility";
import { middleware } from "../../redux/store";
import CommonHeader from "../Common/CommonHeader";
import TableWithHeader from "../Common/TableWithHeader";
import { companySelector, getCTxnHistory } from "./CompanySlice";
import CompanyTxnFilter from "./CompanyTxnFilter";
import CompanyTxnHistoryTable from "./CompanyTxnHistoryTable";

const CompanyTxnHistory = () => {
  const { isFetchingCompanyTxns } = useSelector(companySelector);
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [companyTxnData, setCompanyTxnData] = useState([]);

  const getCompanyTransactionsOnce = (obj) => {
    const { startDate, endDate, statusType, types } = obj ?? {};
    dispatch(
      getCTxnHistory({ companyId, startDate, endDate, statusType, types })
    ).then((action) =>
      middleware(
        action,
        "Company Transaction History",
        (action) => {
          if (isArray(action.payload.data)) {
            setCompanyTxnData(action.payload.data);
          } else {
            setCompanyTxnData([]);
          }
        },
        undefined
      )
    );
  };
  const handleApplyFilter = (obj, search) => {
    const { startDate, endDate, statusType, types } = obj;
    getCompanyTransactionsOnce({
      startDate: startDate.split(" ")[0],
      endDate: endDate.split(" ")[0],
      statusType,
      types,
    });
  };
  const handleResetFilters = () => {
    getCompanyTransactionsOnce();
  };

  useFetch(() => {
    getCompanyTransactionsOnce();
  });

  return (
    <Container>
      <CommonHeader
        name={companyId ? "Transaction History" : "Company Transaction History"}
      ></CommonHeader>
      <TableWithHeader
        name={
          companyId
            ? companyTxnData[0]?.companyName ?? "N/A"
            : "Transaction History"
        }
        setShowFilterModal={setShowFilterModal}
        // search={{
        //   searchVal,
        //   setSearchVal,
        //   saveSearchKey: "companyTransactionHistories",
        //   handleSearch,
        // }}
      >
        <CompanyTxnHistoryTable
          isFetching={isFetchingCompanyTxns}
          companyTxnData={companyTxnData}
          hasID={companyId}
        />
      </TableWithHeader>
      {showFilterModal && (
        <CompanyTxnFilter
          modal={showFilterModal}
          closeModal={() => setShowFilterModal(false)}
          canSelectUsers={false}
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilters}
        />
      )}
    </Container>
  );
};

export default CompanyTxnHistory;
