import React, { useEffect, useState } from "react";
import CloseButton from "../../components/Common/CloseButton";
import dangerAlert from "../../assets/img/danger-alert.svg";
import WalletNotify from "../../assets/img/wallet-notify.svg";
import Modal from "../../components/Common/Modal";
import {
  checkForWallet,
  getItem,
  getLocalUser,
  isArray,
  showToast,
} from "../../lib/utility";
import { useDispatch } from "react-redux";
import { postNotificationAlertToAdmin } from "./Notifications/NotificationSlice";
import { userListGet } from "../../components/User/UserSlice";
import { middleware } from "../../redux/store";
export default function LowBalanceAlertModal({
  modal,
  toggleModal,
  deleteNotificationOnce,
  profileData,
  notifyId,
  handleNotificationClick,
}) {
  const dispatch = useDispatch();

  const addBalanceLater = () => {
    deleteNotificationOnce(notifyId);
    toggleModal();
  };

  const handleAddBalanceRequest = () => {
    const body = {
      message: `User ${profileData?.masterID}, your request has been sent to admins`,
      id: crypto.randomUUID(),
      type: "add_balance_request_data",
      timestamp: new Date().toISOString(),
      status: "SUCCESS",
      read: false,
      reference: "/user?showModal=balance",
      referenceId: `${getItem("userId")} ${profileData.walletFlag} ${
        profileData.lowAmount
      } ${
        checkForWallet(profileData.walletFlag)
          ? profileData.wallets
          : profileData.credits
      }`,
      title: "Add Balance Request Alert",
      ipAddress: "192.168.1.1",
    };

    dispatch(
      postNotificationAlertToAdmin({
        body,
        username: profileData?.masterID,
      })
    )
      .then((action) => {
        if (action?.payload?.status.toString().startsWith("2")) {
          showToast("Request sent to admin successfully", "success");
          deleteNotificationOnce(notifyId);
          toggleModal();
        } else {
          showToast("Failed to send request", "error");
        }
      })
      .catch((error) => {
        showToast("An error occurred while sending the request", "error");
      });
  };

  const currBalance = checkForWallet(profileData?.walletFlag)
    ? profileData?.wallets?.toString()
    : profileData?.credits?.toString();
  const currency = checkForWallet(profileData?.walletFlag)
    ? profileData?.currency
    : "CRE";
  const walletType =
    profileData && checkForWallet(profileData?.walletFlag)
      ? "Wallet"
      : "Credit";

  return (
    <>
      <Modal show={modal} close={toggleModal} justify="center" width={500}>
        <div className="modal-header" style={{ backgroundColor: "#CC2929" }}>
          {/* #CC2929 */}
          <h6 className="modal-title d-flex align-items-center gap-2 fs-22">
            <img src={dangerAlert} alt="" loading="lazy" />
            {notifyId?.title ? notifyId?.title : "Low Balance Alert"}
          </h6>
          <CloseButton theme={"dark"} onClick={addBalanceLater} />
        </div>
        <div className="modal-body bg-white d-flex flex-column justify-content-center align-items-center gap-2 p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <img src={WalletNotify} alt="" loading="lazy" />
            <p
              style={{
                marginBottom: "5px",
                marginLeft: "15px",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              Dear {profileData?.userName}, Your {walletType} balance is{" "}
              {currBalance === "0" ? "Exhausted" : "Low"} ({currBalance}
              {currency}).
            </p>
          </div>
          <p
            style={{
              marginBottom: "0px",
              marginTop: "10px",
              lineHeight: 1,
              fontWeight: "400",
              fontSize: "20px",
            }}
          >
            To continue enjoying our services, request {profileData?.masterID}{" "}
            to recharge your wallet now.
          </p>
        </div>
        <div className="modal-footer bg-white">
          <div className="row g-3 w-100">
            <div className="col-6 mt-0">
              <button
                onClick={addBalanceLater}
                className="btn fs-16 fw-500 br-12 w-100 h-100 px-4 py-2 border_lightgray_1 me-2"
              >
                Later
              </button>
            </div>
            <div className="col-6 mt-0">
              <button
                type="button"
                className="bg-success text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                onClick={() => {
                  handleAddBalanceRequest();
                }}
              >
                Request to Recharge
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
