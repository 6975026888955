import "nprogress/nprogress.css";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { startProgress, stopProgress } from "../../lib/utility";

const ProgressBar = () => {
  const locationMap = useRef(new Map());
  const location = useLocation();

  const getFullURL = () => location.pathname + location.search;

  useEffect(() => {
    if (!locationMap.current.get(getFullURL())) {
      startProgress();
      locationMap.current.set(getFullURL(), true);
    }
    return () => {
      stopProgress();
    };
  }, [location]);

  return null;
};

export default ProgressBar;
