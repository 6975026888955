import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dltIcon from "../../assets/icons/dlt.svg";
import firewallIcon from "../../assets/icons/firewall.svg";
import salesIcon from "../../assets/icons/sale.svg";
import company from "../../assets/icons/company.svg";
import mobToggleIcon from "../../assets/icons/sideToggle.svg";
import utilityIcon from "../../assets/icons/utility.svg";
import addressBookIcon from "../../assets/img/address-book.svg";
import dashboardIcon from "../../assets/img/dashboard.svg";
import downloadIcon from "../../assets/img/downloadSidebarIcon.svg";
import logoutIcon from "../../assets/img/logout.svg";
import manageIcon from "../../assets/img/manage.svg";
import messagingIcon from "../../assets/img/messaging.svg";
import networkIcon from "../../assets/img/Network.svg";
import reportIcon from "../../assets/img/report.svg";
import routingIcon from "../../assets/img/routing.svg";
import smscIcon from "../../assets/img/SMSC.svg";
import templateIcon from "../../assets/img/templateIcon.svg";
import transactionHistoryIcon from "../../assets/img/transaction.svg";
import userIcon from "../../assets/img/User.svg";
import sellerBatchIcon from "../../assets/seller-img/sellerbatch.svg";
import sellerCoverageIcon from "../../assets/seller-img/sellercoverage.svg";
import sellerDeliveryIcon from "../../assets/seller-img/sellerdelivery.svg";
import sellerPerformanceIcon from "../../assets/seller-img/sellerperformance.svg";
import sellerStatusIcon from "../../assets/seller-img/sellerstatus.svg";
import sellerSubmissionIcon from "../../assets/seller-img/sellersub.svg";
import {
  authSelector,
  clearLogoutAuthState,
  getNavOptionsData,
  logoutUser,
} from "../../components/Auth/AuthSlice";
import CloseButton from "../../components/Common/CloseButton";
import { useFetch } from "../../hooks/useFetch";
import {
  getItem,
  hasRoles,
  isArray,
  isManager,
  isSeller,
  someTrue,
  storeItem,
} from "../../lib/utility";
import { middleware, persistor } from "../../redux/store";
import "./Sidebar.scss";
import { getSmscNames } from "../../components/User/UserSlice";

const Sidebar = () => {
  const initialValue = {
    report: false,
    batch: false,
    delivery: false,
    utility: false,
    edit: false,
  };

  const [submenusOpen, setSubmenusOpen] = useState(
    getItem("sideActive", true) || initialValue
  );
  const [shouldShowDlvSmsc, setShouldShowDlvSmsc] = useState(
    getItem("shouldShowDlvSmsc", true) ?? false
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user, navOptions } = useSelector(authSelector);

  const getNavOptionsDataOnce = () => {
    dispatch(getNavOptionsData()).then((action) =>
      middleware(action, "Nav Options")
    );
  };
  const toggleSubMenu = (submenu) => {
    setSubmenusOpen({
      ...submenusOpen,
      [submenu]: !submenusOpen[submenu],
    });
    storeItem("sideActive", {
      ...submenusOpen,
      [submenu]: !submenusOpen[submenu],
    });
  };
  const logout = () => {
    dispatch(logoutUser()).then((action) =>
      middleware(action, "Logout User", (action) => {
        dispatch(clearLogoutAuthState());
        localStorage.clear();
        persistor.purge();
        window.location.href = "/login";
        globalThis.profileData = null;
      })
    );
  };
  const routerToSide = (side) => {
    return pathname.includes(side);
  };
  const sidebarOptionOpen = () => {
    let obj = {};
    Object.keys(submenusOpen).forEach((submenu) => {
      obj[submenu] = routerToSide(submenu);
    });
    return obj;
  };
  const getSmscList = () => {
    dispatch(getSmscNames()).then((action) =>
      middleware(action, "Message", (action) => {
        if (isArray(action.payload.data)) {
          setShouldShowDlvSmsc(action.payload.data.length > 0);
          storeItem("shouldShowDlvSmsc", action.payload.data.length > 0);
        }
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      let activeElemet = document.querySelector(".link.active");
      activeElemet &&
        activeElemet.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 500);
  }, []);

  useFetch(() => {
    if (hasRoles("admin", user)) getSmscList();
    setSubmenusOpen(sidebarOptionOpen());

    if (!isManager(user) && !isSeller(user)) {
      getNavOptionsDataOnce();
    }
  });
  const closeSidebar = (e) => {
    e.stopPropagation();
    document.querySelector(".sidebarContainer").classList.toggle("show");
  };

  const LinkItem = ({
    name,
    icon,
    to,
    navOption,
    roleCondition,
    customValidation,
    toggle,
    children,
  }) => {
    let shouldRender = navOption ? navOptions?.[navOption] : true;
    if (roleCondition !== undefined)
      shouldRender = shouldRender && hasRoles(roleCondition, user);
    if (customValidation !== undefined)
      shouldRender = shouldRender && customValidation;

    return (
      <>
        {shouldRender && (
          <>
            {to ? (
              <NavLink
                to={"/" + to}
                className={({ isActive }) =>
                  "link collapsed" + (isActive ? " active" : "")
                }
              >
                {icon && <img loading="lazy" src={icon} alt="" />}
                <span className="text-light-gray">{name}</span>
              </NavLink>
            ) : (
              children && (
                <div className="dropdown">
                  <div
                    className="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSubMenu(toggle);
                    }}
                  >
                    {icon && <img src={icon} alt="" loading="lazy" />}
                    <span className="text-light-gray">{name}</span>
                    {children && submenusOpen[toggle] ? (
                      <IoIosArrowDown />
                    ) : (
                      <IoIosArrowForward />
                    )}
                  </div>
                  {submenusOpen[toggle] && (
                    <div className="submenu">{children}</div>
                  )}
                </div>
              )
            )}
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div className={"sidebarContainer"} onClick={closeSidebar}>
        <div className="sidebar ">
          <div className="profileHeader" onClick={() => navigate("/profile")}>
            {globalThis.profileData &&
            globalThis.profileData?.profileName &&
            globalThis.profileData?.profilePath ? (
              <img
                src={`data:image/${globalThis.profileData?.profileName};base64,${globalThis.profileData?.profilePath}`}
                alt="userImg"
                className="userImage prevent-dark-icon"
                loading="lazy"
              />
            ) : (
              <img
                src="/3d-avatar.jpg"
                alt="user"
                className="userImage prevent-dark-icon"
                loading="lazy"
              />
            )}
            {!isSeller(user) && !isManager(user) ? (
              <div>
                <p
                  className="fs-18 fw-600 text-gray mb-0 lh-1 userNameDetail-name"
                  title={`${globalThis.profileData?.firstName || "N"} ${
                    globalThis.profileData?.lastName || "N/A"
                  }`}
                >
                  {globalThis.profileData?.firstName || "N"}{" "}
                  {globalThis.profileData?.lastName || "/A"}
                </p>
                <p className="fs-12 fw-600 text-light-gray mb-0">
                  {globalThis.profileData?.roles || "N/A"}
                </p>
              </div>
            ) : (
              <div>
                <p
                  className="fs-18 fw-600 text-gray mb-0 lh-1 userNameDetail-name"
                  title={globalThis.profileData?.userName || "N/A"}
                >
                  {globalThis.profileData?.userName || "N/A"}
                </p>
                <p className="fs-12 fw-600 text-light-gray mb-0">
                  {globalThis.profileData?.role || "N/A"}
                </p>
              </div>
            )}
            <div className="closeBtn" onClick={closeSidebar}>
              <CloseButton />
            </div>
          </div>
          {!(isSeller(user) || isManager(user)) ? (
            <div className="links">
              <LinkItem
                name={"Dashboard"}
                to={"dashboard"}
                icon={dashboardIcon}
              />
              <LinkItem
                name={"Messaging"}
                to={"message"}
                icon={messagingIcon}
                navOption={"sms"}
              />
              <LinkItem
                name={"Utilities"}
                icon={utilityIcon}
                navOption={"utility"}
                toggle="utilities"
              >
                <LinkItem
                  name={"Campaign Manager"}
                  to={"utilities/edit/bulk"}
                />
                <LinkItem
                  name={"Schedule Manager"}
                  to={"utilities/edit/schedule"}
                />
                <LinkItem
                  name={"Two Way"}
                  to={"utilities/two-way"}
                  navOption={"twoWay"}
                />
                <LinkItem
                  name={"Character Converter"}
                  to={"utilities/converter"}
                />
                <LinkItem name={"Subscription"} to={"utilities/subscription"} />
              </LinkItem>
              <LinkItem
                name={"Reports"}
                icon={reportIcon}
                navOption={"report"}
                toggle="report"
              >
                <LinkItem name={"Campaign"} toggle={"campaign"}>
                  <LinkItem name={"Aborted"} to={"reports/campaign/aborted"} />
                  <LinkItem
                    name={"Campaign"}
                    to={"reports/campaign/campaign"}
                  />
                  <LinkItem
                    name={"Schedule"}
                    to={"reports/campaign/schedule"}
                  />
                </LinkItem>
                <LinkItem name={"Summary"} toggle={"summary"}>
                  <LinkItem name={"Delivery"} to={"reports/summary/delivery"} />
                  <LinkItem name={"Batch"} to={"reports/summary/batch"} />
                </LinkItem>
                <LinkItem name={"Detailed Analytics"} toggle={"detailed"}>
                  <LinkItem
                    name={"Delivery Advance"}
                    to={"reports/detailed/advanced"}
                  />
                  <LinkItem
                    name={"Delivery Content"}
                    to={"reports/detailed/content"}
                  />
                  <LinkItem
                    name={"Delivery SMSC"}
                    to={"reports/detailed/smsc"}
                    roleCondition={"admin"}
                    customValidation={shouldShowDlvSmsc}
                  />
                </LinkItem>
                <LinkItem name={"Delivery Latency"} to={"reports/latency"} />
                <LinkItem name={"Consumption"} to={"reports/consumption"} />
                <LinkItem name={"Look-up"} to={"reports/lookup"} />
                <LinkItem
                  name={"Aggregator"}
                  to={"reports/aggregated"}
                  roleCondition={"superadmin,admin,system"}
                />
                <LinkItem
                  name={"Profit"}
                  to={"reports/profit"}
                  roleCondition={"superadmin,admin,system"}
                />
                <LinkItem
                  name={"Performance"}
                  toggle={"performance"}
                  roleCondition={"superadmin,system"}
                >
                  <LinkItem name={"User"} to={"reports/performance/user"} />
                  <LinkItem name={"Route"} to={"reports/performance/route"} />
                </LinkItem>
                <LinkItem
                  name={"Blocked"}
                  to={"reports/blocked"}
                  roleCondition={"superadmin,system"}
                />
                <LinkItem
                  name={"Coverage"}
                  to={"reports/coverage-report"}
                  roleCondition={"superadmin,system"}
                />
                {/* <LinkItem
                  name={"Traffic & Delivery"}
                  to={"reports/traffic-and-delivery"}
                  roleCondition={"superadmin,system"}
                />
                <LinkItem
                  name={"Latency Comparison"}
                  to={"reports/latency-comparison"}
                  roleCondition={"superadmin,system"}
                />
                <LinkItem
                  name={"Cost Efficiency"}
                  to={"reports/cost-efficiency"}
                  roleCondition={"superadmin,system"}
                />
                <LinkItem
                  name={"Hourly Comparison"}
                  to={"reports/hourly-comparison"}
                  roleCondition={"superadmin,system"}
                /> */}
              </LinkItem>
              <LinkItem
                name={"Address Book"}
                to={"address-book"}
                icon={addressBookIcon}
                navOption={"addBook"}
              />
              <LinkItem
                name={"Templates"}
                to={"template"}
                icon={templateIcon}
                navOption={"template"}
              />
              <LinkItem
                name={"Approvals"}
                icon={manageIcon}
                customValidation={someTrue(
                  navOptions,
                  "bulk_act,bulk_mgmt,sender_act,sender_mgmt"
                )}
                roleCondition={"admin"}
                toggle={"approvals"}
              >
                <LinkItem
                  name={"Bulk"}
                  customValidation={someTrue(navOptions, "bulk_act,bulk_mgmt")}
                  toggle={"bulk"}
                >
                  <LinkItem
                    name={"Approve/Reject"}
                    to={"approvals/bulk/approved-reject"}
                  />
                  <LinkItem name={"Log"} to={"approvals/bulk/log"} />
                </LinkItem>
                <LinkItem
                  name={"Sender"}
                  customValidation={someTrue(
                    navOptions,
                    "sender_act,sender_mgmt"
                  )}
                  toggle={"sender"}
                >
                  <LinkItem
                    name={"Approve/Reject"}
                    to={"approvals/sender/approved-reject"}
                  />
                  <LinkItem name={"Log"} to={"approvals/sender/log"} />
                </LinkItem>
              </LinkItem>
              <LinkItem
                name={"Approvals"}
                icon={manageIcon}
                roleCondition={"superadmin,system"}
                toggle={"approvals"}
              >
                <LinkItem name={"Bulk"} toggle={"bulk"}>
                  <LinkItem
                    name={"Approve/Reject"}
                    to={"approvals/bulk/approved-reject"}
                  />
                  <LinkItem name={"Log"} to={"approvals/bulk/log"} />
                </LinkItem>
                <LinkItem name={"Sender"} toggle={"sender"}>
                  <LinkItem
                    name={"Approve/Reject"}
                    to={"approvals/sender/approved-reject"}
                  />
                  <LinkItem name={"Log"} to={"approvals/sender/log"} />
                </LinkItem>
              </LinkItem>
              <LinkItem
                name={"User"}
                to={"user"}
                icon={userIcon}
                navOption={"user"}
                roleCondition={"admin"}
              />
              <LinkItem
                name={"User"}
                icon={userIcon}
                navOption={"user"}
                roleCondition={"superadmin,system"}
              >
                <LinkItem name={"List"} to={"user"} />
                <LinkItem name={"Limit"} to={"user-limit"} />
              </LinkItem>
              <LinkItem
                name={"Routing"}
                icon={routingIcon}
                roleCondition={"superadmin,system,admin"}
                toggle={"routing"}
                navOption={"routing"}
              >
                <LinkItem
                  name={"Add"}
                  to={"routing/add"}
                  roleCondition={"superadmin,system"}
                />
                <LinkItem name={"Edit"} to={"routing/edit"} />
                <LinkItem name={"Copy"} to={"routing/copy"} />
                {/* <LinkItem
                  name={"Package"}
                  to={"routing/package"}
                  roleCondition={"superadmin,system"}
                /> */}
                <LinkItem
                  name={"Sender"}
                  toggle={"sender"}
                  roleCondition={"superadmin,system"}
                >
                  <LinkItem name={"Add"} to={"routing/sender/add"} />
                  <LinkItem name={"Edit"} to={"routing/sender/edit"} />
                </LinkItem>
              </LinkItem>
              <LinkItem
                name={"Company"}
                roleCondition={"superadmin,system,admin"}
                icon={company}
                to={"company"}
              />
              <LinkItem
                name={"SMSC"}
                icon={smscIcon}
                roleCondition={"superadmin,system"}
                toggle={"smsc"}
              >
                <LinkItem name={"List"} to={"smsc/list"} />
                <LinkItem name={"Flag"} to={"smsc/flag"} />
                <LinkItem name={"Status"} to={"smsc/status"} />
                <LinkItem name={"Alert"} to={"smsc/alert"} />
                <LinkItem name={"Limits"} to={"smsc/limits"} />
                <LinkItem name={"Looping"} to={"smsc/looping"} />
                <LinkItem name={"HLR"} toggle={"hlr"}>
                  <LinkItem name={"List"} to={"smsc/hlr"} />
                  <LinkItem name={"Flag"} to={"smsc/hlr-flag"} />
                  <LinkItem name={"Status"} to={"smsc/hlr-status"} />
                </LinkItem>
                <LinkItem name={"Blocking"} to={"smsc/blocking"} />
                <LinkItem name={"Schedule"} to={"smsc/schedule"} />
                <LinkItem
                  name={"Special Settings"}
                  to={"smsc/special-settings"}
                />
                <LinkItem name={"Groups"} to={"smsc/group-data"} />
              </LinkItem>
              <LinkItem
                name={"Firewall"}
                to={"firewall"}
                icon={firewallIcon}
                roleCondition={"admin,system,superadmin"}
                navOption={"bsfm"}
              />
              <LinkItem
                name={"DLT"}
                icon={dltIcon}
                roleCondition={"superadmin,system"}
                toggle={"dlt"}
              >
                <LinkItem name={"Principle ID"} to={"dlt/principleEntityID"} />
                <LinkItem name={"Template"} to={"dlt/templateDlt"} />
              </LinkItem>
              <LinkItem
                name={"Sales Team"}
                to={"sales"}
                icon={salesIcon}
                navOption={"sales"}
                roleCondition={"admin,superadmin,system"}
              />
              <LinkItem
                name={"Network"}
                to={"network"}
                icon={networkIcon}
                navOption={"sales"}
                roleCondition={"superadmin,system"}
              />

              <LinkItem
                name={"Documentation"}
                to={"downloads"}
                icon={downloadIcon}
                navOption={"user"}
                toggle={"download"}
              />
            </div>
          ) : (
            <div className="links">
              <LinkItem
                name={"Dashboard"}
                to={"dashboard"}
                icon={dashboardIcon}
              />
              <LinkItem
                name={"Batch"}
                to={"reports/batch-summary"}
                icon={sellerBatchIcon}
              />
              <LinkItem
                name={"Submission"}
                to={"reports/submission"}
                icon={sellerSubmissionIcon}
              />
              <LinkItem
                name={"Delivery"}
                to={"reports/delivery-advance"}
                icon={sellerDeliveryIcon}
              />
              <LinkItem
                name={"Coverage"}
                to={"reports/coverage"}
                icon={sellerCoverageIcon}
              />
              <LinkItem
                name={"Performance User"}
                to={"reports/performance-user"}
                icon={sellerPerformanceIcon}
              />
              <LinkItem
                name={"Sales Team"}
                to={"sales-list"}
                icon={salesIcon}
                navOption={"sales"}
                roleCondition={"manager"}
              />
              <LinkItem
                name={"User"}
                to={"reports/user-status"}
                icon={sellerStatusIcon}
              />
              <LinkItem
                name={"Transaction History"}
                to={"reports/transaction-history"}
                icon={transactionHistoryIcon}
              />
            </div>
          )}
          <div className="logout">
            <p onClick={logout}>
              <img src={logoutIcon} alt="" loading="lazy" />
              <span className="link-title w-100">Logout</span>
            </p>
          </div>
        </div>
      </div>
      <div className="mobToggle">
        <button
          onClick={closeSidebar}
          data-tooltip="Toggle Sidebar"
          data-tooltip-location="right"
        >
          <img src={mobToggleIcon} alt="" loading="lazy" />
        </button>
      </div>
    </>
  );
};

export default Sidebar;
