import moment from "moment-timezone";

export const dateFormatter = (date) => {
  return date
    .toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+)/, "$1-$2-$3 $4");
};
export const dateFormatterISO = (date) => {
  if (!date) return "";
  const [datePart, timePart] = date.split(" ");

  const [day, month, year] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");
  const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return isoDateString;
};
export const tableShortFormatDate = (dateString, time = true) => {
  if (!dateString || dateString == "") return "-";
  return moment(dateString).format(`DD MMM YYYY${time ? ", HH:mm A" : ""}`);
};
export const tableFormatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options);
};
export const dateFormatter2 = (date, endDate = true, reversed = false) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  if (reversed) return `${month}-${day}-${year}`;
  return `${year}-${month}-${day} ` + (endDate ? "23:59:59" : "00:00:00");
};
export const getLast10thDate = (reversed = false) => {
  let currentDate = new Date();
  let startDate = dateFormatter2(currentDate, true, reversed);
  let endDate = currentDate;
  endDate.setDate(endDate.getDate() - 10);
  endDate = dateFormatter2(endDate, false, reversed);
  return { startDate, endDate };
};
export const getCountsByKey = (data, keyName) => {
  const counts = data.reduce((counts, row) => {
    counts[row[keyName]] = (counts[row[keyName]] || 0) + 1;
    return counts;
  }, {});

  const labels = Object.keys(counts);
  const range = Object.values(counts);

  return { labels, range };
};
