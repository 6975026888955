import { dateFormatter2 } from "../Wrapper/CommonFun";

export const REPORTSERVERTIME = {
  // time is in ms
  "Delivery Content": 0.0104,
  "Batch Summary": 0.0553,
  "Delivery Advance": 0.457,
  "Batch Aborted": 0.0553,
  Aggregator: 0.3724,
  "Delivery Summary": 9.423,
  "Performance Route": 0.8645,
  "Performance User": 0.123,
  Schedule: 0.08,
};
export const sizesArray = ["B", "kB", "MB", "GB", "TB", "PB"];
export const tableCustomStyles = (config) => {
  let { height, minHeight, maxHeight, overflow, fontSize, headFontSize } =
    config ?? {};
  let { tableStyle, wrapperStyle } = config ?? {};

  return {
    table: {
      style: {
        height: height ?? `calc(100vh - 380px)`,
        minHeight: minHeight ?? 400,
        ...(maxHeight ? { maxHeight } : {}),
        overflow: overflow ?? `hidden auto`,
        ...(tableStyle ?? {}),
      },
    },
    tableWrapper: {
      style: {
        height: height ?? `calc(100vh - 380px)`,
        minHeight: minHeight ?? 400,
        ...(maxHeight ? { maxHeight } : {}),
        overflow: overflow ?? `auto`,
        ...(wrapperStyle ?? {}),
      },
    },
    headCells: {
      style: {
        fontSize: `${headFontSize ?? 14}px`,
      },
    },
    cells: {
      style: {
        fontSize: `${fontSize ?? 12}px`,
      },
    },
  };
};
export const getDatesFromDay = (day) => {
  let eDate = new Date();
  let sDate = new Date();

  sDate.setDate(sDate.getDate() - +day);

  return {
    startDate: dateFormatter2(sDate).split(" ")[0],
    endDate: dateFormatter2(eDate).split(" ")[0],
  };
};
