import React from "react";
import SuccessIcon from "../../../assets/img/successIcon.svg";
import NotifiedIcon from "../../../assets/img/notifiedIcon.svg";
import ExclamationMarkIcon from "../../../assets/img/exclamationMark.svg";
import DownloadIcon from "../../../assets/img/notificationDownload.svg";
import DeliveredIcon from "../../../assets/img/done-all.svg";
import DangerIcon from "../../../assets/img/danger.svg";
import SeenIcon from "../../../assets/img/seen.svg";

const NotificationsIconRenderer = ({ name, className = "" }) => {
  if (name === "success")
    return (
      <img className={className} src={SuccessIcon} alt="" loading="lazy" />
    );
  if (name === "notified")
    return (
      <img className={className} src={NotifiedIcon} alt="" loading="lazy" />
    );
  if (name === "warning")
    return (
      <img
        className={className}
        src={ExclamationMarkIcon}
        alt=""
        loading="lazy"
      />
    );
  if (name === "download")
    return (
      <img className={className} src={DownloadIcon} alt="" loading="lazy" />
    );
  if (name === "failed")
    return <img className={className} src={DangerIcon} alt="" loading="lazy" />;
  if (name === "delivered")
    return (
      <img className={className} src={DeliveredIcon} alt="" loading="lazy" />
    );
  if (name === "seen")
    return <img className={className} src={SeenIcon} alt="" loading="lazy" />;
  else return <React.Fragment />;
};

export default NotificationsIconRenderer;
