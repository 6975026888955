import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  size: "desktop",
};

export const commonUtilitySlice = createSlice({
  name: "commonUtility",
  initialState,
  reducers: {
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
      state.isTablet = false;
      state.isDesktop = false;
      state.size = "mobile";
    },
    setIsTablet: (state, { payload }) => {
      state.isMobile = false;
      state.isTablet = payload;
      state.isDesktop = false;
      state.size = "tablet";
    },
    setIsDesktop: (state, { payload }) => {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = payload;
      state.size = "desktop";
    },
    clearState: (state) => {
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = false;
    },
  },
});

export const { setIsDesktop, setIsMobile, setIsTablet, clearState } =
  commonUtilitySlice.actions;
export const commonUtilitySelector = (state) => state.commonUtility;

export default commonUtilitySlice.reducer;
