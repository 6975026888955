import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  dashboardData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  UserRecentActivity: {},
  isFetchingRecentActivity: false,
  isSuccessRecentActivity: false,
  isErrorRecentActivity: false,
  errorMessageRecentActivity: "",
  userDashboardGraphs: {},
  isFetchingGraphs: false,
  isSuccessGraphs: false,
  isErrorGraphs: false,
  errorMessageGraphs: "",
  UserAccountSummary: {},
  isFetchingAccountSummary: false,
  isSuccessAccountSummary: false,
  isErrorAccountSummary: false,
  errorMessageAccountSummary: "",
};

export const dashboardDataGet = createAsyncThunk(
  "reports/dashboard",
  async (data1, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/reports/dashboard`;
      var date = data1.date;
      const body = {
        method: "dashboard",
        days: date,
      };
      if (data1.user) body.username = data1.user;

      const { data, status } = await axios.post(url, body, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const recentActivity = createAsyncThunk(
  "login/recent-activity",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/login/recent-activity`;

      const { data, status } = await axios.post(url, _, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const accountSummary = createAsyncThunk(
  "login/account-summary",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/login/account-summary`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const dashboardFetchGraphs = createAsyncThunk(
  "dashboard-access/fetch",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`dashboard-access/fetch`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const dashboardSaveGraphs = createAsyncThunk(
  "dashboard-access/save",
  async (graph, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `dashboard-access/save`;

      const { data, status } = await axios.post(url, graph, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearStateDashboard: (state) => {
      state.dashboardData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateRecentActivity: (state) => {
      state.UserRecentActivity = [];
      state.isFetchingRecentActivity = false;
      state.isSuccessRecentActivity = false;
      state.isErrorRecentActivity = false;
      state.errorMessageRecentActivity = "";
    },
    clearStateAccountSummary: (state) => {
      state.UserAccountSummary = [];
      state.isFetchingAccountSummary = false;
      state.isSuccessAccountSummary = false;
      state.isErrorAccountSummary = false;
      state.errorMessageAccountSummary = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboardDataGet.pending, (state, action) => {
        if (action.meta.arg.setLoading) {
          state.isFetching = true;
          state.isSuccess = false;
          state.isError = false;
          state.errorMessage = "";
        }
      })
      .addCase(dashboardDataGet.fulfilled, (state, action) => {
        if (action.meta.arg.setLoading) {
          state.isFetching = false;
          state.isSuccess = true;
        }
        state.dashboardData = action.payload.data;
      })
      .addCase(dashboardDataGet.rejected, (state, action) => {
        if (action.meta.arg.setLoading) {
          state.isFetching = false;
          state.isError = true;
          state.errorMessage = action.payload || "Something went wrong";
        }
      })
      .addCase(recentActivity.pending, (state) => {
        state.isFetchingRecentActivity = true;
        state.isSuccessRecentActivity = false;
        state.isErrorRecentActivity = false;
        state.errorMessageRecentActivity = "";
      })
      .addCase(recentActivity.fulfilled, (state, action) => {
        state.isFetchingRecentActivity = false;
        state.isSuccessRecentActivity = true;
        state.UserRecentActivity = action.payload.data;
      })
      .addCase(recentActivity.rejected, (state, action) => {
        state.isFetchingRecentActivity = false;
        state.isErrorRecentActivity = true;
        state.errorMessageRecentActivity =
          action.payload || "Something went wrong";
      })
      .addCase(accountSummary.pending, (state) => {
        state.isFetchingAccountSummary = true;
        state.isSuccessAccountSummary = false;
        state.isErrorAccountSummary = false;
        state.errorMessageAccountSummary = "";
      })
      .addCase(accountSummary.fulfilled, (state, action) => {
        state.isFetchingAccountSummary = false;
        state.isSuccessAccountSummary = true;
        state.UserAccountSummary = action.payload.data;
      })
      .addCase(accountSummary.rejected, (state, action) => {
        state.isFetchingAccountSummary = false;
        state.isErrorAccountSummary = true;
        state.errorMessageAccountSummary =
          action.payload || "Something went wrong";
      })
      .addCase(dashboardFetchGraphs.fulfilled, (state, action) => {
        state.userDashboardGraphs = action.payload;
        state.isFetchingGraphs = false;
        state.isSuccessGraphs = true;
      })
      .addCase(dashboardFetchGraphs.pending, (state) => {
        state.isFetchingGraphs = true;
      })
      .addCase(dashboardFetchGraphs.rejected, (state, action) => {
        state.isFetchingGraphs = false;
        state.isErrorGraphs = true;
        state.errorMessageGraphs = action.payload || "Something went wrong";
      })
      // save userDashBoardGraphs
      .addCase(dashboardSaveGraphs.fulfilled, (state, action) => {
        state.userDashboardGraphs = action.payload;
        state.isFetchingGraphs = false;
        state.isSuccessGraphs = true;
      })
      .addCase(dashboardSaveGraphs.pending, (state) => {
        state.isFetchingGraphs = true;
      })
      .addCase(dashboardSaveGraphs.rejected, (state, action) => {
        state.isFetchingGraphs = false;
        state.isErrorGraphs = true;
        state.errorMessageGraphs = action.payload || "Something went wrong";
      });
  },
});

export const {
  clearStateDashboard,
  clearStateRecentActivity,
  clearStateAccountSummary,
} = dashboardSlice.actions;

export function clearStateDashboardAction() {
  return async (dispatch) => {
    dispatch(clearStateDashboard());
  };
}
export function clearStateRecentActivityAction() {
  return async (dispatch) => {
    dispatch(clearStateRecentActivity());
  };
}
export function clearStateAccountSummaryAction() {
  return async (dispatch) => {
    dispatch(clearStateAccountSummary());
  };
}

export const dashboardSelector = (state) => state.dashboard;
export default dashboardSlice.reducer;
