import React from "react";
import NotificationsIconRenderer from "./NotificationsIconRenderer";
import CloseButton from "../../../components/Common/CloseButton";
import moment from "moment-timezone";

const NotificationCard = ({
  delete: deleteFunction,
  notification: { id, title, message, status, timestamp, read },
  handleNotificationClick,
}) => {
  const handleCardClick = () => {
    handleNotificationClick(id, !read || read === "no");
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    deleteFunction(id, !read);
  };

  return (
    <div
      className={
        "notificationCard " +
        (!read || read === "no" ? "unread" : "") +
        (" " + status?.toLowerCase())
      }
      onClick={handleCardClick}
    >
      <div className="icon">
        <NotificationsIconRenderer name={status?.toLowerCase()} />
      </div>
      <div className="detail w-100">
        <h2 className="d-flex justify-content-between mb-1 text-darkN">
          <span style={{ textTransform: "capitalize" }}>{title}</span>
          <CloseButton
            theme="light"
            color="red"
            height={12}
            onClick={handleDelete}
          />
        </h2>
        <p className="mb-2 text-gray">{message}</p>
        <p className="small mb-0 text-gray">
          {timestamp ? moment(timestamp).format("DD-MM-YYYY HH:mm:ss") : ""}
        </p>
      </div>
    </div>
  );
};

export default NotificationCard;
