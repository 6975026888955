import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  userData: [], // Initial user data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  createUser: {},
  isSuccessCreateUser: false,
  isErrorCreateUser: false,
  isErrorMessageCreateUser: "",

  deleteUser: {},
  isSuccessDeleteUser: false,
  isErrorDeleteUser: false,
  isErrorMessageDeleteUser: "",

  editUser: {},
  isSuccessEditUser: false,
  isErrorEditUser: false,
  isErrorMessageEditUser: "",

  isFetchingSMS: false,
  smsIdentify: {},
  isSuccessSmsIdentify: false,
  isErrorSmsIdentify: false,
  isErrorMessageSmsIdentify: "",
};

export const getUserData = createAsyncThunk(
  "templates/get-all-templates", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/templates/get-all-templates`;

      if (udata) {
        url = `/templates/get-all-templates${udata}`;
      }

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createUserData = createAsyncThunk(
  "templates/create-template",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/templates/create-template",
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserData = createAsyncThunk(
  "templates/delete-template",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/templates/delete-template/${udata.id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const editUserData = createAsyncThunk(
  "templates/update-template",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/templates/update-template/${udata.id}`,
        {
          title: udata.title,
          message: udata.message,
        },
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const SMSIdentify = createAsyncThunk(
  "sms/identify",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        "/sms/identify",
        { message: udata },
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    clearStateTemplate: (state, { payload }) => {
      state.userData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateTemplate: (state, { payload }) => {
      state.createUser = {};
      state.isFetching = false;
      state.isSuccessCreateUser = false;
      state.isErrorCreateUser = false;
      state.isErrorMessageCreateUser = "";
    },
    clearStateDeleteTemplate: (state, { payload }) => {
      state.deleteUser = {};
      state.isFetching = false;
      state.isSuccessDeleteUser = false;
      state.isErrorDeleteUser = false;
      state.isErrorMessageDeleteUser = "";
    },
    clearStateEditTemplate: (state, { payload }) => {
      state.editUser = {};
      state.isFetching = false;
      state.isSuccessEditUser = false;
      state.isErrorEditUser = false;
      state.isErrorMessageEditUser = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.userData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      //Create Template API
      .addCase(createUserData.fulfilled, (state, action) => {
        state.createUser = action.payload;
        state.isFetching = false;
        state.isSuccessCreateUser = true;
      })
      .addCase(createUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createUserData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateUser = true;
        state.isErrorMessageCreateUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Delete Template API
      .addCase(deleteUserData.fulfilled, (state, action) => {
        state.deleteUser = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteUser = true;

        if (state.userData.length === 1) state.userData = [];
      })
      .addCase(deleteUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteUserData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteUser = true;
        state.isErrorMessageDeleteUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Edit Template api
      .addCase(editUserData.fulfilled, (state, action) => {
        state.editUser = action.payload;
        state.isFetching = false;
        state.isSuccessEditUser = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(editUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editUserData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditUser = true;
        state.isErrorMessageEditUser = action.payload.data
          ? action.payload.message
          : "Internal error";
      })
      // smsIdentify
      .addCase(SMSIdentify.fulfilled, (state, action) => {
        state.smsIdentify = action.payload;
        state.isFetchingSMS = false;
        state.isSuccessSmsIdentify = true;
        state.isErrorMessageSmsIdentify = action.payload.data;
      })
      .addCase(SMSIdentify.pending, (state) => {
        state.isFetchingSMS = true;
      })
      .addCase(SMSIdentify.rejected, (state, action) => {
        state.isFetchingSMS = false;
        state.isErrorSmsIdentify = true;
        state.isErrorMessageSmsIdentify = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const {
  clearStateTemplate,
  clearStateCreateTemplate,
  clearStateDeleteTemplate,
  clearStateEditTemplate,
} = templateSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateTemplate());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateTemplate());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteTemplate());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditTemplate());
  };
}

export const templateSelector = (state) => state.template;

export default templateSlice.reducer;
