import React, { useState } from "react";
import { useSelector } from "react-redux";
import calenderIcon from "../../assets/img/calenderIcon.svg";
import { reportSelector } from "../Report/ReportSlice";
import CloseButton from "./CloseButton";
import LoaderComponent from "./LoaderComponent";
import Modal from "./Modal";

const SaveFilter = ({ handleCancelClick, handleSaveClick }) => {
  const [saveText, setSaveText] = useState("");

  const { isFetchingSaveFilter } = useSelector(reportSelector);

  return (
    <Modal show={true} close={handleCancelClick} justify="center">
      <div className="modal-header">
        <h6 className="modal-title d-flex align-items-center fs-22">
          <img src={calenderIcon} loading="lazy" />
          Save Filter
        </h6>
        <CloseButton theme={"dark"} onClick={handleCancelClick} />
      </div>
      <div className="modal-body py-2 px-3 bg-white">
        {isFetchingSaveFilter ? (
          <LoaderComponent />
        ) : (
          <>
            <h3 className="fs-20 fw-600">Do you want to save this filter?</h3>
            <p className="small text-gray">
              Save filters for quick access and improved productivity.
            </p>
            <div className="input my-2">
              <label className="form-label text-black fw-500">
                Enter Name for this filter
              </label>
              <input
                type="text"
                className="form-control w-full"
                placeholder="Give your preferred and relevant name to this filter"
                value={saveText}
                onChange={(e) => setSaveText(e.target.value)}
              />
            </div>
          </>
        )}
      </div>
      <div className="modal-footer d-block bg-white">
        <div className="row g-3">
          <div className="col-6 mt-0">
            <button
              type="button"
              className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
          <div className="col-6 mt-0">
            <button
              onClick={() => handleSaveClick(saveText)}
              className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SaveFilter;
