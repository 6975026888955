import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkForWallet } from "../../../lib/utility";
import CloseButton from "../../Common/CloseButton";
import LoaderComponent from "../../Common/LoaderComponent";
import Modal from "../../Common/Modal";
import { companySelector, modifyCompanyBalance } from "../CompanySlice";
import { middlewareUpdate } from "../../../redux/store";

const BalanceCompanyUser = ({
  modal,
  closeModal,
  companyData,
  id,
  getCompanyDataOnce,
}) => {
  const [walletFlag, setWalletFlag] = useState("yes");
  const [balanceOption, setBalanceOption] = useState("credit");
  const [balanceData, setBalanceData] = useState({
    amount: 0,
    remarks: "",
  });

  const dispatch = useDispatch();
  const { isFetchingUserBalance } = useSelector(companySelector);

  const handleBalanceOption = (e) => {
    setBalanceOption(e.target.value);
  };

  const handleBalanaceSubmit = () => {
    let amount = balanceData.amount;
    let balanceBody = {
      companyId: companyData?.id || id,
      type: balanceOption,
      amount: +amount,
      walletFlag: walletFlag,
    };
    dispatch(modifyCompanyBalance(balanceBody)).then((action) =>
      middlewareUpdate(action, "Company Balance", () => {}, undefined, {
        modal: closeModal,
        refresh: getCompanyDataOnce,
      })
    );
  };
  return (
    <Modal show={modal} close={closeModal} justify="center">
      <div className="modal-header">
        <h6 className="modal-title d-flex gap-2 align-items-center fs-22">
          Balance
        </h6>
        <CloseButton theme="dark" onClick={closeModal} />
      </div>
      <div className="modal-body bg-white">
        {isFetchingUserBalance ? (
          <LoaderComponent />
        ) : (
          <>
            <div
              className="header"
              style={{
                background: "#0000000a",
                borderRadius: "20px 20px 0 0",
                border: "1px solid #3341631A",
                padding: "20px 24px",
              }}
            >
              <select
                className="form-select bg-transparent fw-600"
                onChange={handleBalanceOption}
                value={balanceOption}
              >
                <option value="credit">
                  Add {checkForWallet(walletFlag) ? "Wallet" : "Credit"} Amount
                </option>
                <option value="debit">
                  Deduct {checkForWallet(walletFlag) ? "Wallet" : "Credit"}{" "}
                  Amount
                </option>
              </select>
            </div>
            <div
              key={balanceOption}
              className="p-4 border border-light_grey"
              style={{
                borderRadius: "0 0 20px 20px",
              }}
            >
              <div className="row g-3 mb-3">
                <div className="col-3">
                  <label className="form-label mt-2 fs-16 fw-600 text-dark">
                    Type:
                  </label>
                </div>
                <div className="col-9">
                  <select
                    className="form-select"
                    value={walletFlag}
                    onChange={(e) => setWalletFlag(e.target.value)}
                  >
                    <option value="yes">Wallet</option>
                    <option value="no">Credit</option>
                  </select>
                </div>
                <div className="col-3">
                  <label className="form-label mt-2 fs-16 fw-600 text-dark">
                    Amount:
                  </label>
                </div>
                <div className="col-9">
                  <input
                    type="number"
                    min={0}
                    className="rounded form-control"
                    placeholder="Enter Amount"
                    value={balanceData.amount}
                    onChange={(e) =>
                      setBalanceData((prev) => ({
                        ...prev,
                        amount: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="modal-footer d-block bg-white">
        <div className="row g-3">
          <div className="col-6 mt-0">
            <button
              type="button"
              className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
              onClick={() => {
                setBalanceOption("add");
                setBalanceData({ amount: 0, remarks: "" });
              }}
            >
              Reset
            </button>
          </div>
          <div className="col-6 mt-0">
            <button
              type="submit"
              className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
              onClick={handleBalanaceSubmit}
              //   disabled={isFetchingUserBalance}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BalanceCompanyUser;
