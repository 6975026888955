import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

const RedirectToDashboard = () => {
  const navigate = useNavigate();

  useFetch(() => {
    navigate("/dashboard");
  });
  return <></>;
};
export default RedirectToDashboard;
