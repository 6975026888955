import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  userData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  // userDataContact: [],
  // isFetchingContact: false,
  // isSuccessContact: false,
  // isErrorContact: false,
  // errorMessageContact: "",

  createUser: {},
  isSuccessCreateUser: false,
  isErrorCreateUser: false,
  isErrorMessageCreateUser: "",

  deleteUser: {},
  isSuccessDeleteUser: false,
  isErrorDeleteUser: false,
  isErrorMessageDeleteUser: "",

  editUser: {},
  isSuccessEditUser: false,
  isErrorEditUser: false,
  isErrorMessageEditUser: "",

  exportGroup: {},
  isSuccessExportGroup: false,
  isFetchingExportGroup: false,
  isErrorExportGroup: false,
  isErrorMessageExportGroup: "",

  // exportContact: {},
  // isSuccessExportContact: false,
  // isFetchingExportContact: false,
  // isErrorExportContact: false,
  // isErrorMessageExportContact: "",

  deleteMultiple: {},
  isSuccessDeleteMultiple: false,
  isFetchingDeleteMultiple: false,
  isErrorDeleteMultiple: false,
  isErrorMessageDeleteMultiple: "",

  isFetchingSearchGroupData: false,
};

export const listAddressBookGroups = createAsyncThunk(
  "addressBook/listGroups",
  async (udata, thunkAPI) => {
    try {
      const { currentPage, pageSize } = udata;
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/groups/list?page=${
        currentPage - 1
      }&size=${pageSize}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const filterGroupsData = createAsyncThunk(
  "addressBook/filterData",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/contact/search/${udata}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const saveAddressBookGroupEntry = createAsyncThunk(
  "/addressbook/groups-add",
  async ({ name }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/groups`;

      const requestBody = {
        groupId: 0,
        groupName: name,
      };

      const { data, status } = await axios.post(url, requestBody, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateAddressBookGroupEntry = createAsyncThunk(
  "/addressbook/groups-update",
  async ({ id, name, groupData }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/groups`;

      const requestBody = {
        groupId: id,
        groupName: name,
      };

      const { data, status } = await axios.put(url, requestBody, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const DeleteAddressBook = createAsyncThunk(
  "/addressbook/groups-delete",
  async ({ ids }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/groups?ids=${ids.join(",")}`;
      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const exportGroupData = createAsyncThunk(
  "addressbook/contact/export",
  async (requestData, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();
      const stringArray = requestData.join(",");
      if (requestData) urlParams.append("GroupID", stringArray);

      const url = `/addressbook/contact/export?GroupID=${stringArray}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
        responseType: "blob",
      });
      if (response.status.toString().startsWith("2")) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchGroupData = createAsyncThunk(
  "addressbook/contact/view",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/addressbook/contact/view`;
      const { data, status } = await axios.post(url, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchGroupCriteria = createAsyncThunk(
  "addressbook/contact/criteria",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/addressbook/contact/criteria`;
      const { data, status } = await axios.post(url, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const AddressBookSlice = createSlice({
  name: "addressBook",
  initialState,
  reducers: {
    clearStateAddressBook: (state, { payload }) => {
      state.userData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateAddressBook: (state, { payload }) => {
      state.createUser = {};
      state.isFetching = false;
      state.isSuccessCreateUser = false;
      state.isErrorCreateUser = false;
      state.isErrorMessageCreateUser = "";
    },
    clearStateEditAddressBook: (state, { payload }) => {
      state.editUser = {};
      state.isFetching = false;
      state.isSuccessEditUser = false;
      state.isErrorEditUser = false;
      state.isErrorMessageEditUser = "";
    },
    clearStateDeleteAddressBook: (state, { payload }) => {
      state.deleteUser = {};
      state.isFetching = false;
      state.isSuccessDeleteUser = false;
      state.isErrorDeleteUser = false;
      state.isErrorMessageDeleteUser = "";
    },
    clearStateExportGroup: (state, { payload }) => {
      state.exportGroup = {};
      state.isFetchingExportGroup = false;
      state.isSuccessExportGroup = false;
      state.isErrorExportGroup = false;
      state.isErrorMessageExportGroup = "";
    },
    // clearStateExportContact: (state, { payload }) => {
    //   state.exportContact = {};
    //   state.isFetchingExportContact = false;
    //   state.isSuccessExportContact = false;
    //   state.isErrorExportContact = false;
    //   state.isErrorMessageExportContact = "";
    // },
    clearStateDeleteMultiple: (state, { payload }) => {
      state.deleteMultiple = {};
      state.isFetchingDeleteMultiple = false;
      state.isSuccessDeleteMultiple = false;
      state.isErrorDeleteMultiple = false;
      state.isErrorMessageDeleteMultiple = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAddressBookGroups.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(listAddressBookGroups.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.userData = action.payload;
      })
      .addCase(listAddressBookGroups.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.userData = [];
        state.errorMessage = action.payload?.message || "Something went wrong";
      })
      .addCase(filterGroupsData.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(filterGroupsData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(filterGroupsData.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload?.message || "Something went wrong";
      })
      // .addCase(listAddressBookContacts.pending, (state) => {
      //   state.isFetchingContact = true;
      //   state.isSuccessContact = false;
      //   state.isErrorContact = false;
      //   state.errorMessageContact = "";
      // })
      // .addCase(listAddressBookContacts.fulfilled, (state, action) => {
      //   state.isFetchingContact = false;
      //   state.isSuccessContact = true;
      //   state.userDataContact = action.payload;
      // })
      // .addCase(listAddressBookContacts.rejected, (state, action) => {
      //   state.isFetchingContact = false;
      //   state.isErrorContact = true;
      //   state.errorMessageContact = action.payload || "Something went wrong";
      // })

      .addCase(saveAddressBookGroupEntry.fulfilled, (state, action) => {
        state.createUser = action.payload;
        state.isFetching = false;
        state.isSuccessCreateUser = true;
      })
      .addCase(saveAddressBookGroupEntry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(saveAddressBookGroupEntry.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateUser = true;
        state.isErrorMessageCreateUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(updateAddressBookGroupEntry.fulfilled, (state, action) => {
        state.editUser = action.payload;
        state.isFetching = false;
        state.isSuccessEditUser = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(updateAddressBookGroupEntry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateAddressBookGroupEntry.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditUser = true;
        state.isErrorMessageEditUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(DeleteAddressBook.fulfilled, (state, action) => {
        state.deleteUser = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteUser = true;
      })
      .addCase(DeleteAddressBook.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(DeleteAddressBook.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteUser = true;
        state.isErrorMessageDeleteUser = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(exportGroupData.fulfilled, (state, action) => {
        state.exportGroup = action.payload;
        state.isFetchingExportGroup = false;
        state.isSuccessExportGroup = true;
      })
      .addCase(exportGroupData.pending, (state) => {
        state.isFetchingExportGroup = true;
      })
      .addCase(exportGroupData.rejected, (state, action) => {
        state.isFetchingExportGroup = false;
        state.isErrorExportGroup = true;
        state.isErrorMessageExportGroup = action.payload
          ? action.payload.message
          : "Update failed due to an internal error";
      })
      .addCase(searchGroupData.fulfilled, (state, action) => {
        state.isFetchingSearchGroupData = false;
      })
      .addCase(searchGroupData.pending, (state) => {
        state.isFetchingSearchGroupData = true;
      })
      .addCase(searchGroupData.rejected, (state, action) => {
        state.isFetchingSearchGroupData = false;
      })
      .addCase(searchGroupCriteria.fulfilled, (state, action) => {
        state.isFetchingSearchGroupData = false;
      })
      .addCase(searchGroupCriteria.pending, (state) => {
        state.isFetchingSearchGroupData = true;
      })
      .addCase(searchGroupCriteria.rejected, (state, action) => {
        state.isFetchingSearchGroupData = false;
      });
  },
});

export const {
  clearStateAddressBook,
  clearStateCreateAddressBook,
  clearStateEditAddressBook,
  clearStateDeleteAddressBook,
  clearStateDeleteMultiple,
} = AddressBookSlice.actions;

export function clearStateAddressBookAction() {
  return async (dispatch) => {
    dispatch(clearStateAddressBook());
  };
}

export function clearStateCreateAddressBookAction() {
  return async (dispatch) => {
    dispatch(clearStateCreateAddressBook());
  };
}

export function clearStateEditAddressBookAction() {
  return async (dispatch) => {
    dispatch(clearStateEditAddressBook());
  };
}

export function clearStateDeleteAddressBookAction() {
  return async (dispatch) => {
    dispatch(clearStateDeleteAddressBook());
  };
}

export function clearStateDeleteMultipleAction() {
  return async (dispatch) => {
    dispatch(clearStateDeleteMultiple());
  };
}

export const addressBookSelector = (state) => state.addressBook;
export default AddressBookSlice.reducer;
