import React from "react";
import CustomSelect from "./CustomSelect";
import DownloadIcon from "../../assets/img/downloadIcon.svg";
import csvIcon from "../../assets/img/csvicon.svg";
import pdfIcon from "../../assets/img/pdficon.svg";
import xlsIcon from "../../assets/img/xlsicon.svg";
import jsPDF from "jspdf";

const CommonHeader = ({
  name,
  fileType,
  handleFileTypeChange,
  downloadContent,
  discardedFileType,
  customChildren,
  children,
}) => {
  let downloadTypes = [
    { value: "csv", label: "CSV", image: csvIcon },
    { value: "pdf", label: "PDF", image: pdfIcon },
    { value: "xls", label: "XLSX", image: xlsIcon },
  ].filter((k) => discardedFileType?.[k.value] === undefined);
  let keepDownload =
    fileType && handleFileTypeChange && downloadContent && downloadTypes;
  let childLength =
    typeof children === "undefined"
      ? 1
      : typeof children === "object"
      ? 2
      : children.length + 1;

  return (
    <div className="commonHeader | mb-3">
      <p>{name}</p>
      <div
        className="d-flex gap-2 align-items-center"
        style={{
          "--dropdownWidth": Math.ceil(100 / childLength) - 2 + "%",
        }}
      >
        {children && children}
        {keepDownload && (
          <div className="dropdown">
            <div className="d-flex dwn-btn-border align-items-center bg-white">
              <div
                style={{
                  position: "relative",
                  zIndex: 5,
                  borderRight: "1px solid #b5b5b5",
                }}
              >
                <CustomSelect
                  selectData={{
                    fileType,
                    handleFileTypeChange,
                    downloadTypes,
                  }}
                />
              </div>
              <button
                type="button"
                className="btn dwn-btn"
                onClick={downloadContent}
              >
                <span>Download</span>
                <img src={DownloadIcon} alt="" loading="lazy" className="ms-2" />
              </button>
            </div>
          </div>
        )}
        {customChildren && customChildren}
      </div>
    </div>
  );
};

export default CommonHeader;
