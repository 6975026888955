import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  dltData: [],
  isFetchingDltList: false,
  isFetchingAddDlt: false,
  isFetchingEditDlt: false,
  isFetchingDeleteDlt: false,
  tempDltData: [],
  isFetchingTempDltList: false,
  isFetchingAddTempDlt: false,
  isFetchingEditTempDlt: false,
  isFetchingDeleteTempDlt: false,
};

export const getDltList = createAsyncThunk(
  "dlt/getDltList",
  async (params, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/dlt/entries`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addDltData = createAsyncThunk(
  "dlt/addDlt",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/dlt/entries", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editDltData = createAsyncThunk(
  "dlt/editDlt",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put("/dlt/entries", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteDltData = createAsyncThunk(
  "dlt/deleteDlt",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete("/dlt/entries/" + udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getTempDltList = createAsyncThunk(
  "dlt/getTempDltList",
  async (params, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = params ? `/dlt/templates/` + params : `/dlt/templates`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ ...data, status });
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addTempDltData = createAsyncThunk(
  "dlt/addTempDlt",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/dlt/templates", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editTempDltData = createAsyncThunk(
  "dlt/editTempDltList",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/dlt/templates`;

      const { data, status } = await axios.put(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteTempDltData = createAsyncThunk(
  "dlt/deleteTempDltList",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/dlt/templates/` + udata;

      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const dltSlice = createSlice({
  name: "dlt",
  initialState: initialState,
  reducers: {
    clearStateDlt: (state) => {
      state.isFetchingDltList = false;
      state.isFetchingAddDlt = false;
      state.isFetchingEditDlt = false;
      state.isFetchingDeleteDlt = false;
      state.isFetchingTempDltList = false;
      state.isFetchingAddTempDlt = false;
      state.isFetchingEditTempDlt = false;
      state.isFetchingDeleteTempDlt = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //getDltList
      .addCase(getDltList.pending, (state) => {
        state.isFetchingDltList = true;
      })
      .addCase(getDltList.fulfilled, (state, action) => {
        state.isFetchingDltList = false;
        state.dltData = action.payload?.data;
      })
      .addCase(getDltList.rejected, (state) => {
        state.isFetchingDltList = false;
      })
      //addDltData
      .addCase(addDltData.pending, (state) => {
        state.isFetchingAddDlt = true;
      })
      .addCase(addDltData.fulfilled, (state) => {
        state.isFetchingAddDlt = false;
      })
      .addCase(addDltData.rejected, (state) => {
        state.isFetchingAddDlt = false;
      })
      //editDltData
      .addCase(editDltData.pending, (state) => {
        state.isFetchingEditDlt = true;
      })
      .addCase(editDltData.fulfilled, (state) => {
        state.isFetchingEditDlt = false;
      })
      .addCase(editDltData.rejected, (state) => {
        state.isFetchingEditDlt = false;
      })
      //DeleteDltData
      .addCase(deleteDltData.pending, (state) => {
        state.isFetchingDeleteDlt = true;
      })
      .addCase(deleteDltData.fulfilled, (state) => {
        if (state.dltData.length === 1) state.dltData = [];
        state.isFetchingDeleteDlt = false;
      })
      .addCase(deleteDltData.rejected, (state) => {
        state.isFetchingDeleteDlt = false;
      })
      .addCase(getTempDltList.pending, (state) => {
        state.isFetchingTempDltList = true;
      })
      .addCase(getTempDltList.fulfilled, (state, action) => {
        state.isFetchingTempDltList = false;
        state.tempDltData = action.payload.data;
      })
      .addCase(getTempDltList.rejected, (state) => {
        state.isFetchingTempDltList = false;
      })
      // addTempDltData
      .addCase(addTempDltData.pending, (state) => {
        state.isFetchingAddTempDlt = true;
      })
      .addCase(addTempDltData.fulfilled, (state) => {
        state.isFetchingAddTempDlt = false;
      })
      .addCase(addTempDltData.rejected, (state) => {
        state.isFetchingAddTempDlt = false;
      })
      //editTempDltData
      .addCase(editTempDltData.pending, (state) => {
        state.isFetchingEditTempDlt = true;
      })
      .addCase(editTempDltData.fulfilled, (state) => {
        state.isFetchingEditTempDlt = false;
      })
      .addCase(editTempDltData.rejected, (state) => {
        state.isFetchingEditTempDlt = false;
      })
      // deleteTempData
      .addCase(deleteTempDltData.pending, (state) => {
        state.isFetchingDeleteTempDlt = true;
      })
      .addCase(deleteTempDltData.fulfilled, (state) => {
        if (state.tempDltData.length === 1) state.tempDltData = [];
        state.isFetchingDeleteTempDlt = false;
      })
      .addCase(deleteTempDltData.rejected, (state) => {
        state.isFetchingDeleteTempDlt = false;
      });
  },
});

export const { clearStateDlt } = dltSlice.actions;

export const dltSelector = (state) => state.dlt;

export default dltSlice.reducer;
