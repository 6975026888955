import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import LoaderComponent from "../Common/LoaderComponent";

const CompanyTxnHistoryTable = ({ isFetching, companyTxnData, hasID }) => {
  const columns = hasID
    ? [
        {
          name: "Transaction ID",
          selector: (row) => row.transactionId ?? "-",
          sortable: true,
        },
        {
          name: "Time",
          selector: (row) => row.updatedDate ?? "-",
          sortable: true,
        },
        {
          name: "Cr/Dr",
          //   selector: (row) => tableShortFormatDate(row.createdOn),
          selector: (row) => row.transactionType ?? "-",
          sortable: true,
        },
        {
          name: "Previous Balance",
          selector: (row) => row.previousAmount ?? "-",
          sortable: true,
        },
        {
          name: "Effective Balance",
          selector: (row) => row.affectedAmount ?? "-",
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.currentAmount ?? "-",
          sortable: true,
        },
        {
          name: "Remarks",
          selector: (row) => row.remark ?? "-",
          sortable: true,
        },
        {
          name: "Payment Status",
          selector: (row) => row.paymentStatus ?? "-",
          sortable: true,
        },
      ]
    : [
        {
          name: "Transaction ID",
          selector: (row) => row.transactionId ?? "-",
          sortable: true,
        },
        {
          name: "Company ID",
          selector: (row) => row.companyId ?? "-",
          sortable: true,
        },
        {
          name: "Company",
          selector: (row) => row.companyName ?? "-",
          sortable: true,
        },
        {
          name: "Time",
          selector: (row) => row.updatedDate ?? "-",
          sortable: true,
        },
        {
          name: "Cr/Dr",

          selector: (row) => row.transactionType ?? "-",
          sortable: true,
        },
        {
          name: "Previous Balance",
          selector: (row) => row.previousAmount ?? "-",
          sortable: true,
        },
        {
          name: "Effective Balance",
          selector: (row) => row.affectedAmount ?? "-",
          sortable: true,
        },
        {
          name: "Amount",
          selector: (row) => row.currentAmount ?? "-",
          sortable: true,
        },
        {
          name: "Remarks",
          selector: (row) => row.remark ?? "-",
          sortable: true,
        },
        {
          name: "Payment Status",
          selector: (row) => row.paymentStatus ?? "-",
          sortable: true,
        },
      ];
  const data = useCallback(companyTxnData, [companyTxnData]);

  return (
    <>
      <div className="m-3 table-responsive border br-12 table-action">
        {isFetching ? (
          <LoaderComponent />
        ) : (
          <DataTable
            // customStyles={tableCustomStyles()}
            columns={columns}
            data={data}
            highlightOnHover
            fixedHeader
            pagination
            noDataComponent={<div className="p-4">No data available</div>}
          />
        )}
      </div>
    </>
  );
};

export default CompanyTxnHistoryTable;
