import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import company from "../../../assets/icons/company.svg";
import { useFetch } from "../../../hooks/useFetch";
import { middlewareUpdate } from "../../../redux/store";
import LoaderComponent from "../../Common/LoaderComponent";
import Modal from "../../Common/Modal";
import { companySelector, editCompanyData } from "../CompanySlice";

const EditCompany = ({
  modal,
  toggleModal,
  companyData,
  getCompanyDataOnce,
}) => {
  const initialState = {
    companyName: companyData.companyName,
    companyEmail: companyData.companyEmail,
    companyType: companyData.companyType,
    country: companyData.country,
    telephone: companyData.telephone,
    mobile: companyData.mobile,
    address: companyData.address,
    remark: companyData.remark,
    registrationNumber: companyData.registrationNumber,
    vat: companyData.vat,
    nb: companyData.nb,
  };

  const dispatch = useDispatch();
  const { isEditingCompanyData } = useSelector(companySelector);

  const [editCompany, setEditCompany] = useState(initialState);

  const handleInputChange = (name, value) => {
    setEditCompany((prev) => ({ ...prev, [name]: value }));
  };
  const resetForm = () => {
    setEditCompany(companyData || initialState);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (companyData) {
      dispatch(
        editCompanyData({ id: companyData.id, udata: editCompany })
      ).then((action) =>
        middlewareUpdate(action, "update company", () => {}, undefined, {
          modal: toggleModal,
          refresh: getCompanyDataOnce,
        })
      );
    }
  };

  return (
    <Modal show={modal} close={toggleModal} justify="center" width={750}>
      <div className="modal-header">
        <h6 className="modal-title d-flex align-items-center fs-22">
          <img src={company} alt="Wallet Icon" loading="lazy" />
          Edit Company
        </h6>
        <svg
          className="cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={toggleModal}
        >
          <path
            d="M20 4L4 20M4 4L20 20"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <form onSubmit={handleSubmit} className="bg-white">
        <div className="modal-body py-2 px-3">
          {isEditingCompanyData ? (
            <LoaderComponent />
          ) : (
            <div className="row g-3">
              <div className="col-md-2">
                <label className="form-label">Company Name</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter Company Name"
                  name="companyName"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.companyName}
                  maxLength={50}
                  required
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Company Email</label>
              </div>
              <div className="col-md-4">
                <input
                  type="email"
                  className="form-control input-style"
                  placeholder="Enter Company Email"
                  name="companyEmail"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.companyEmail}
                  required
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Company Type</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter Company Email"
                  name="companyType"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.companyType}
                  required
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Country</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter Country"
                  name="country"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.country}
                  required
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Telephone</label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control input-style"
                  placeholder="Enter Telephone"
                  name="telephone"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.telephone}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Mobile</label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control input-style"
                  placeholder="Enter Mobile"
                  name="mobile"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.mobile}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Address</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter Address"
                  name="address"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.address}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Remarks</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter Remarks"
                  name="remark"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.remark}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Registration Number</label>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control input-style"
                  placeholder="Registration Number"
                  name="registrationNumber"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.registrationNumber}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">NB</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter NB"
                  name="nb"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.nb}
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Vat</label>
              </div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control input-style"
                  placeholder="Enter vat"
                  name="vat"
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  value={editCompany.vat}
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer d-block bg-white">
          <div className="row g-3">
            <div className="col-6 mt-0">
              <button
                type="button"
                className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                onClick={resetForm}
              >
                Reset
              </button>
            </div>
            <div className="col-6 mt-0">
              <button
                type="submit"
                className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditCompany;
