import { React, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import bulkMail from "../../assets/img/bulkMail.svg";
import cutomiseIcon from "../../assets/img/cutomiseIcon.svg";
import darkIcon from "../../assets/img/dark.svg";
import downloadsIcon from "../../assets/img/download.svg";
import lightIcon from "../../assets/img/light.svg";
import notification from "../../assets/img/notification.svg";
import quickMessage from "../../assets/img/quickMessage.svg";
import SubmissionReportIcon from "../../assets/img/submissionReport.svg";
import walletIcon from "../../assets/img/wallet.svg";
import { authSelector } from "../../components/Auth/AuthSlice";
import {
  toggleBulkSMSOverlay,
  toggleQuickSMSOverlay,
} from "../../components/Message/MessageSlice";
import { getProfileData } from "../../components/Profile/ProfileSlice";
import SubmissionReport from "../../components/Report/InnerReport/Submission";
import { userListGet } from "../../components/User/UserSlice";
import { useFetch } from "../../hooks/useFetch";
import useModal from "../../hooks/useModal";
import {
  checkForWallet,
  getItem,
  hasRoles,
  isArray,
  isManager,
  isSeller,
  storeItem,
} from "../../lib/utility";
import { middleware } from "../../redux/store";
import AddBalanceRequestModal from "./AddBalanceRequestModal";
import CustomizePanelFilter from "./CustomizePanel/CustomizePanelFilter";
import DownloadProgressModal from "./DownloadProgress/DownloadProgressModal";
import LowBalanceAlertModal from "./LowBalanceAlertModal";
import "./Navbar.scss";
import NotificationModal from "./Notifications/NotificationModal";
import {
  deleteNotification,
  getNotificationData,
  markAsReadNotification,
  notificationSelector,
} from "./Notifications/NotificationSlice";
import useNotificationThrottle from "./Notifications/useNotificationThrottle";

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [profileData, setProfileData] = useState(getItem("profileData", true));
  const [showNotification, setShowNotification] = useState(false);
  const [showFileProgress, setShowFileProgress] = useState(false);
  const [showSubmissionReport, setShowSubmissionReport] = useState(false);
  const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
  const [showCustomizePanel, setShowCustomizePanel] = useState(false);
  const [notifyId, setNotifyId] = useState();
  const [addBalanceLater, setAddBalanceLater] = useState(false);
  const [notificationCount, setNotificationCount] = useState(
    globalThis.notificationCount || 0
  );
  const [userList, setUserList] = useState([]);
  const [addBalanceId, setAddBalanceId] = useState();
  const [balanceUser, setBalanceUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(authSelector);
  const { notificationData, isFetching: isFetchingNotification } =
    useSelector(notificationSelector);
  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem("darkMode", newMode);
    applyTheme(newMode);
  };
  const applyTheme = (isDarkMode) => {
    const body = document.body;
    if (isDarkMode) {
      body.classList.add("dark-mode");
    } else {
      body.classList.remove("dark-mode");
    }
  };
  function handleQuickMessage(e) {
    e.preventDefault;
    navigate("/message");
    dispatch(toggleQuickSMSOverlay(true));
  }
  function handleBulkMessage(e) {
    e.preventDefault;
    navigate("/message");
    dispatch(toggleBulkSMSOverlay(true));
  }
  function handleSubmissionReportOpen(e) {
    e && e.preventDefault();
    setShowSubmissionReport(true);
  }
  function handleCustomizeFilter(e) {
    e && e.preventDefault();
    setShowCustomizePanel((prev) => !prev);
  }
  const handleNotificationModalOpen = () => {
    setShowNotification(true);
  };

  const handleFileProgressModalOpen = () => {
    setShowFileProgress(true);
  };
  const getNotificationDataOnce = (force) => {
    dispatch(getNotificationData(force)).then((action) =>
      middleware(action, "Notification", (action) => {
        if (isArray(action.payload.data)) {
          let totalUnread = action.payload.data.filter(
            (ntf) => !ntf.read
          ).length;
          const latestTitle = action.payload.data[0]?.title;
          if (
            latestTitle === "Profile Updated" &&
            +globalThis.notificationCount !== totalUnread
          ) {
            getProfileDataOnce(true);
          }
          if (latestTitle == "Low Balance Alert") {
            setNotifyId(action.payload.data[0]?.id);
            setLowBalanceAlert(true);
          }
          if (latestTitle == "Add Balance Request Alert") {
            setAddBalanceLater(true);
            setAddBalanceId(action.payload.data[0]);
          }
          setNotificationCount(totalUnread);
          globalThis.notificationCount = totalUnread;
        }
      })
    );
  };
  const handleNotificationClick = (id, index, read = false) => {
    if (!id && !index)
      return console.error(
        "Id and Index are rquired to mark notification read"
      );
    const notificationDetail = notificationData[index];
    dispatch(markAsReadNotification(id)).then((action) =>
      middleware(action, "Marking Notification Read", (action) => {})
    );
    if (notificationCount > 0 && read) {
      setNotificationCount((prev) => prev - 1);
      if (globalThis) {
        globalThis.notificationCount = globalThis.notificationCount
          ? globalThis.notificationCount - 1
          : 0;
      }
    }
  };

  const deleteNotificationOnce = (id, decrease = false) => {
    if (!id) return console.error("Id is required to delete Notification");
    dispatch(deleteNotification(id)).then(
      (action) => {
        if (decrease && globalThis) {
          setNotificationCount((prev) => prev - 1);
          globalThis.notificationCount -= 1;
        }
      }
      // middlewareDelete(action, false, (action) => {
      //   if (decrease && globalThis) {
      //     setNotificationCount((prev) => prev - 1);
      //     globalThis.notificationCount -= 1;
      //   }
      // })
    );
  };
  const getProfileDataOnce = (force = false) => {
    if (
      force ||
      !profileData ||
      Object.keys(profileData).length === 0 ||
      globalThis.isFirstTime
    ) {
      dispatch(getProfileData()).then((action) =>
        middleware(action, "Profile Data", (action) => {
          storeItem("profileData", action.payload);
          setProfileData(action.payload);
          globalThis.profileData = action.payload;
          globalThis.isFirstTime = false;
        })
      );
    }
  };
  const { clickRef, contRef, showModal, handleOpen } = useModal();
  useNotificationThrottle(5, 30, getNotificationDataOnce, setNotificationCount);
  useFetch(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(isDarkMode);
    applyTheme(isDarkMode);
    getProfileDataOnce();
  }, []);

  const userDataGetOnce = () => {
    dispatch(userListGet("editUser")).then((action) =>
      middleware(action, "User List", (action) => {
        setUserList(action.payload.usernames);
      })
    );
  };

  useEffect(() => {
    userDataGetOnce();
  }, []);

  return (
    <>
      <header>
        <nav>
          <div
            className="navItem"
            data-tooltip="Dark Mode"
            data-tooltip-location="bottom"
            onClick={toggleDarkMode}
          >
            <button>
              <img
                src={darkMode ? `${lightIcon}` : `${darkIcon}`}
                alt=""
                loading="lazy"
              />
            </button>
          </div>
          {hasRoles("admin", user) && (
            <div
              className="navItem hide"
              data-tooltip="Customize Panel"
              data-tooltip-location="bottom"
            >
              <button onClick={handleCustomizeFilter}>
                <img src={cutomiseIcon} alt="" loading="lazy" />
              </button>
            </div>
          )}
          <div
            className="navItem position-relative"
            data-tooltip="Notifications"
            data-tooltip-location="bottom"
            onClick={handleNotificationModalOpen}
          >
            <button>
              <img src={notification} alt="" loading="lazy" />
            </button>
            {notificationCount !== 0 && (
              <span
                style={{
                  right: -8,
                  top: -8,
                  backgroundColor: "tomato",
                }}
                className="position-absolute badge  rounded-circle"
              >
                {notificationCount}
              </span>
            )}
          </div>
          {!isSeller(user) && !isManager(user) && (
            <>
              <div
                className="navItem hide"
                onClick={handleQuickMessage}
                data-tooltip="Quick Message"
                data-tooltip-location="bottom"
              >
                <button>
                  <img src={quickMessage} alt="" loading="lazy" />
                </button>
              </div>
              <div
                className="navItem hide"
                onClick={handleBulkMessage}
                data-tooltip="Bulk Message"
                data-tooltip-location="bottom"
              >
                <button>
                  <img src={bulkMail} alt="" loading="lazy" />
                </button>
              </div>
              <div
                className="navItem hide"
                onClick={handleSubmissionReportOpen}
                data-tooltip="Submission Report"
                data-tooltip-location="bottom"
              >
                <button>
                  <img src={SubmissionReportIcon} alt="" loading="lazy" />
                </button>
              </div>
            </>
          )}
          <div
            className="navItem"
            data-tooltip="File download progress"
            data-tooltip-location="bottom"
            onClick={handleFileProgressModalOpen}
          >
            <button>
              <img src={downloadsIcon} alt="" loading="lazy" />
            </button>
          </div>
          {!isSeller(user) && !isManager(user) && (
            <>
              <div
                className="navItem wallet"
                data-tooltip={
                  (profileData && checkForWallet(profileData?.walletFlag)
                    ? profileData?.wallets?.toString()
                    : profileData?.credits?.toString()) +
                  " " +
                  (profileData && checkForWallet(profileData?.walletFlag)
                    ? profileData?.currency
                    : "CRE")
                }
                data-tooltip-location="bottom"
                onClick={() => navigate("/wallet")}
              >
                <button>
                  <img src={walletIcon} alt="" loading="lazy" />
                  <span className="text-dark fs-16 px-1">
                    {profileData && checkForWallet(profileData?.walletFlag)
                      ? profileData?.wallets?.toString()
                      : profileData?.credits?.toString()}
                  </span>{" "}
                  <span className="text-gray fs-12">
                    {" "}
                    {profileData && checkForWallet(profileData?.walletFlag)
                      ? profileData?.currency
                      : "CRE"}
                  </span>
                </button>
              </div>
              {hasRoles("superadmin", user) && (
                <div
                  className="navItem wallet"
                  data-tooltip={
                    (profileData && !checkForWallet(profileData?.walletFlag)
                      ? profileData?.wallets?.toString()
                      : profileData?.credits?.toString()) +
                    " " +
                    (profileData && !checkForWallet(profileData?.walletFlag)
                      ? profileData?.currency
                      : "CRE")
                  }
                  data-tooltip-location="bottom"
                  onClick={() => navigate("/wallet")}
                >
                  <button>
                    <img src={walletIcon} alt="" loading="lazy" />
                    <span className="text-dark fs-16 px-1">
                      {profileData && !checkForWallet(profileData?.walletFlag)
                        ? profileData?.wallets?.toString()
                        : profileData?.credits?.toString()}
                    </span>{" "}
                    <span className="text-gray fs-12">
                      {profileData && !checkForWallet(profileData?.walletFlag)
                        ? profileData?.currency
                        : "CRE"}
                    </span>
                  </button>
                </div>
              )}
            </>
          )}
          <div className="navItem more" ref={clickRef}>
            <button onClick={handleOpen}>
              <BsThreeDotsVertical color="#3f4d70" size={20} />
            </button>
          </div>
        </nav>

        {showModal && (
          <div className="moreOptions" ref={contRef}>
            {hasRoles("admin", user) && (
              <div className="navItem hide" onClick={handleCustomizeFilter}>
                <button>
                  <img src={cutomiseIcon} alt="" loading="lazy" />
                  <span>Customize Panel</span>
                </button>
              </div>
            )}
            {!isSeller(user) && !isManager(user) && (
              <>
                <div
                  className="navItem hide"
                  data-tooltip="Quick Message"
                  data-tooltip-location="left"
                  onClick={handleQuickMessage}
                >
                  <button>
                    <img src={quickMessage} alt="" loading="lazy" />
                  </button>
                </div>
                <div
                  className="navItem hide"
                  data-tooltip="Bulk Message"
                  data-tooltip-location="left"
                  onClick={handleBulkMessage}
                >
                  <button>
                    <img src={bulkMail} alt="" loading="lazy" />
                  </button>
                </div>
                <div
                  className="navItem hide"
                  data-tooltip="Submission Report"
                  data-tooltip-location="left"
                  onClick={handleSubmissionReportOpen}
                >
                  <button>
                    <img src={SubmissionReportIcon} alt="" loading="lazy" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </header>
      {showNotification && (
        <NotificationModal
          isLoading={isFetchingNotification}
          close={() => setShowNotification(false)}
          show={showNotification}
          notificationData={notificationData}
          deleteNotificationOnce={deleteNotificationOnce}
          handleNotificationClick={handleNotificationClick}
        />
      )}
      {showFileProgress && (
        <DownloadProgressModal
          close={() => setShowFileProgress(false)}
          show={showFileProgress}
        />
      )}

      {hasRoles("admin", user) && showCustomizePanel && (
        <CustomizePanelFilter
          modal={showCustomizePanel}
          toggleModal={handleCustomizeFilter}
        />
      )}

      {hasRoles("admin,user", user) && lowBalanceAlert && (
        <LowBalanceAlertModal
          profileData={profileData}
          modal={lowBalanceAlert}
          toggleModal={() => setLowBalanceAlert(false)}
          deleteNotificationOnce={deleteNotificationOnce}
          notifyId={notifyId}
          userList={userList}
          handleNotificationClick={handleNotificationClick}
        />
      )}
      {hasRoles("superadmin,admin", user) && (
        <>
          {addBalanceLater && (
            <AddBalanceRequestModal
              modal={addBalanceLater}
              toggleModal={() => setAddBalanceLater(false)}
              profileData={profileData}
              deleteNotificationOnce={deleteNotificationOnce}
              addBalanceId={addBalanceId}
              setBalanceUser={setBalanceUser}
              balanceUser={balanceUser}
              lowBalanceNotification={notificationData.filter(
                (no) => no.title === "Add Balance Request Alert"
              )}
            />
          )}
        </>
      )}

      {!hasRoles("seller,manager", user) && (
        <>
          {showSubmissionReport && (
            <SubmissionReport
              modal={showSubmissionReport}
              toggleModal={() => setShowSubmissionReport(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default Navbar;
