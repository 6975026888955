import React, { useEffect, useState } from "react";
import { checkForWallet, hasRoles, showToast } from "../../../lib/utility";
import CloseButton from "../../Common/CloseButton";
import Modal from "../../Common/Modal";
import { updateUserBalance, userSelector } from "../UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../Auth/AuthSlice";
import { middlewareUpdate } from "../../../redux/store";

const BalanceUser = ({
  modal,
  closeModal,
  userData,
  id,
  addBalance = false,
}) => {
  const [balanceOption, setBalanceOption] = useState("add");
  const [balanceData, setBalanceData] = useState({
    amount: 0,
    remarks: "",
    type: "Local",
  });

  const dispatch = useDispatch();
  const { isFetchingUserBalance } = useSelector(userSelector);
  const { user } = useSelector(authSelector);

  const handleBalanceOption = (e) => {
    setBalanceOption(e.target.value);
  };

  const handleBalanaceSubmit = () => {
    let amount = balanceData.amount;
    let balanceBody = {
      userId: userData?.id || id,
      type: balanceData.type,
      ...(checkForWallet(userData?.walletFlag || "No")
        ? { toBeAddedWallet: amount }
        : { toBeAddedCredits: amount }),
      remark: balanceData.remarks,
      walletFlag: userData?.walletFlag || "No",
      operation: balanceOption === "add" ? "plus" : "minus",
    };
    dispatch(
      updateUserBalance({
        ...balanceBody,
      })
    ).then((action) =>
      middlewareUpdate(action, "User Balance", (action) => {}, undefined, {
        modal: closeModal,
      })
    );
  };
  return (
    <Modal show={modal} close={closeModal} justify="center">
      <div className="modal-header">
        <h6 className="modal-title d-flex gap-2 align-items-center fs-22">
          Balance
        </h6>
        <CloseButton theme="dark" onClick={closeModal} />
      </div>
      <div className="modal-body bg-white">
        <div
          className="header"
          style={{
            background: "#0000000a",
            borderRadius: "20px 20px 0 0",
            border: "1px solid #3341631A",
            padding: "20px 24px",
          }}
        >
          <select
            className="form-select bg-transparent fw-600"
            onChange={handleBalanceOption}
            value={balanceOption}
            disabled={addBalance}
          >
            <option value="add">
              Add {checkForWallet(userData?.walletFlag) ? "Wallet" : "Credit"}{" "}
              Amount
            </option>
            <option value="deduct">
              Deduct{" "}
              {checkForWallet(userData?.walletFlag) ? "Wallet" : "Credit"}{" "}
              Amount
            </option>
          </select>
        </div>
        <div
          key={balanceOption}
          className="p-4 border border-light_grey"
          style={{
            borderRadius: "0 0 20px 20px",
          }}
        >
          <div className="row mb-3">
            <div className="col-3">
              <label className="form-label mt-2 fs-16 fw-600 text-dark">
                Amount:
              </label>
            </div>
            <div className="col-9">
              <input
                type="number"
                min={0}
                className="rounded form-control"
                placeholder="Enter Amount"
                value={balanceData.amount}
                onChange={(e) =>
                  setBalanceData((prev) => ({
                    ...prev,
                    amount: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div
            className={
              "row " + (hasRoles("superadmin,system", user) ? "mb-3" : "")
            }
          >
            <div className="col-3">
              <label className="form-label mt-2 fs-16 fw-600 text-dark">
                Remarks:
              </label>
            </div>
            <div className="col-9">
              <input
                type="text"
                className="rounded form-control"
                placeholder="Enter Remarks"
                value={balanceData.remarks}
                onChange={(e) =>
                  setBalanceData((prev) => ({
                    ...prev,
                    remarks: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {hasRoles("superadmin,system", user) && (
            <div className="row">
              <div className="col-3">
                <label className="form-label mt-2 fs-16 fw-600 text-dark">
                  Type:
                </label>
              </div>
              <div className="col-9">
                <select
                  className="rounded form-select"
                  value={balanceData.type}
                  onChange={(e) =>
                    setBalanceData((prev) => ({
                      ...prev,
                      type: e.target.value,
                    }))
                  }
                >
                  <option value="Local">Local</option>
                  <option value="International">International</option>
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer d-block bg-white">
        <div className="row g-3">
          <div className="col-6 mt-0">
            <button
              type="button"
              className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
              onClick={() => {
                setBalanceOption("add");
                setBalanceData({ amount: 0, remarks: "" });
              }}
            >
              Reset
            </button>
          </div>
          <div className="col-6 mt-0">
            <button
              type="submit"
              className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
              onClick={handleBalanaceSubmit}
              disabled={isFetchingUserBalance}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BalanceUser;
