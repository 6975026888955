import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  groupData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  createGroup: {},
  isSuccessCreateUser: false,
  isErrorCreateUser: false,
  isErrorMessageCreateUser: "",

  deleteGroup: {},
  isSuccessDeleteUser: false,
  isErrorDeleteUser: false,
  isErrorMessageDeleteUser: "",

  editGroup: {},
  isSuccessEditUser: false,
  isErrorEditUser: false,
  isErrorMessageEditUser: "",
};

export const getAddressBookGroupData = createAsyncThunk(
  "/addressbook/contact-get",
  async ({ groupId, start, end, search }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();
      if (start) urlParams.append("start", start);
      if (end) urlParams.append("end", end);
      if (search) urlParams.append("search", search);
      const url = `/addressbook/contact/${groupId}?${urlParams.toString()}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else if (status.toString().startsWith("4")) {
        const { error, message, status, timestamp } = data;

        // Customize the rejected payload for 404 error
        return thunkAPI.rejectWithValue({
          status: status,
          error,
          message,
          timestamp,
        });
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateAddressBookGroupEntryMember = createAsyncThunk(
  "addressbook/contact-update",
  async (
    {
      id,
      initials,
      email,
      number,
      firstName,
      middleName,
      lastName,
      age,
      profession,
      area,
      gender,
      groupId,
      company,
    },
    thunkAPI
  ) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = "/addressbook/contact";
      const groupDataEntryRequest = {
        id,
        initials,
        email,
        number,
        firstName,
        middleName,
        lastName,
        age,
        company,
        profession,
        area,
        gender,
        groupId,
      };

      const { data, status } = await axios.put(url, groupDataEntryRequest, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(response.data);
    }
  }
)
export const DeleteAddressBookGroupMember = createAsyncThunk(
  "/addressbook/contact-delete",
  async ({ ids }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/addressbook/contact?ids=${ids.join(",")}`;

      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const exportGroupData = createAsyncThunk(
  "addressbook/contact/export",
  async (requestData, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      let query = requestData.groupIds
        ? "?GroupId=" + requestData.groupIds.join(",")
        : "";
      const url = `/addressbook/contact/export${query}`;

      const response = await axios.post(url, requestData, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
        responseType: "blob",
      });
      if (response.status === 200) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const SaveSingleGroupDataEntry = createAsyncThunk(
  "addressbook/contact-save",
  async (
    {
      id,
      initials,
      email,
      number,
      firstName,
      middleName,
      lastName,
      age,
      profession,
      area,
      gender,
      groupId,
      company,
      type,
      contactNumberFile,
    },
    thunkAPI
  ) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = "/addressbook/contact";
      const formData = new FormData();
      const groupDataEntryRequest = {
        // id,
        initials,
        email,
        number,
        firstName,
        middleName,
        lastName,
        age,
        profession,
        area,
        gender,
        groupId,
        company,
        type,
      };

      formData.append(
        "groupDataEntryRequest",
        JSON.stringify(groupDataEntryRequest)
      );
      if (contactNumberFile) {
        formData.append("contactNumberFile", contactNumberFile);
      }

      const { data, status } = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Slice definition
const GroupDataSlice = createSlice({
  name: "Group",
  initialState,
  reducers: {
    clearStateGroupDataAddressBook: (state) => {
      state.groupData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateSaveSingleGroupDataEntry: (state, { payload }) => {
      state.createGroup = {};
      state.isFetchingCreateUser = false;
      state.isSuccessCreateUser = false;
      state.isErrorCreateUser = false;
      state.isErrorMessageCreateUser = "";
    },
    clearStateDeleteAddressBookGroupMember: (state, { payload }) => {
      state.deleteGroup = {};
      state.isFetching = false;
      state.isSuccessDeleteGroup = false;
      state.isErrorDeleteGroup = false;
      state.isErrorMessageDeleteGroup = "";
    },

    clearStateExportGroupData: (state, { payload }) => {
      state.deleteUser = {};
      state.isFetching = false;
      state.isSuccessDeleteUser = false;
      state.isErrorDeleteUser = false;
      state.isErrorMessageDeleteUser = "";
    },
    clearStateupdateAddressBookGroupEntry: (state, { payload }) => {
      state.editGroup = {};
      state.isFetching = false;
      state.isSuccessEditUser = false;
      state.isErrorEditUser = false;
      state.isErrorMessageEditUser = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddressBookGroupData.pending, (state) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getAddressBookGroupData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.groupData = action.payload.data;
      })
      .addCase(getAddressBookGroupData.rejected, (state, action) => {
        state.groupData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage =
          action.payload?.message ||
          action.payload?.error ||
          "Something went wrong";
      })
      .addCase(updateAddressBookGroupEntryMember.fulfilled, (state, action) => {
        state.editGroup = action.payload;
        state.isFetching = false;
        state.isSuccessEditUser = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(updateAddressBookGroupEntryMember.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateAddressBookGroupEntryMember.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditUser = true;
        state.isErrorMessageEditUser = action.payload
          ? action.payload?.message || action.payload?.error
          : "Internal error";
      })
      .addCase(DeleteAddressBookGroupMember.fulfilled, (state, action) => {
        state.deleteGroup = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteUser = true;
      })
      .addCase(DeleteAddressBookGroupMember.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(DeleteAddressBookGroupMember.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteUser = true;
        state.isErrorMessageDeleteUser = action.payload
          ? action.payload?.message || action.payload?.error
          : "Internal error";
      })
      .addCase(SaveSingleGroupDataEntry.fulfilled, (state, action) => {
        state.createGroup = action.payload;
        state.isFetching = false;
        state.isSuccessCreateUser = true;
      })
      .addCase(SaveSingleGroupDataEntry.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(SaveSingleGroupDataEntry.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateUser = true;
        state.isErrorMessageCreateUser =
          action.payload?.message || action.payload?.error || "Internal Error";
      })
      .addCase(exportGroupData.fulfilled, (state, action) => {
        state.exportGroupData = action.payload;
        state.isFetching = false;
        state.isSuccessExportGroupData = true;
      })
      .addCase(exportGroupData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(exportGroupData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorExportGroupData = true;
        state.isErrorMessageExportGroupData = action.payload
          ? action.payload.message || action.payload?.error
          : "Internal error";
      });
  },
});
export const {
  clearStateGroupDataAddressBook,
  clearStateSaveSingleGroupDataEntry,
  clearStateDeleteAddressBookGroupMember,
  clearStateupdateAddressBookGroupEntry,
} = GroupDataSlice.actions;

export function clearStateGroupDataAddressBookAction() {
  return async (dispatch) => {
    dispatch(clearStateGroupDataAddressBook());
  };
}
export function clearStateSaveSingleGroupDataEntryAction() {
  return async (dispatch) => {
    dispatch(clearStateSaveSingleGroupDataEntry());
  };
}
export function clearStateDeleteAddressBookGroupMemberAction() {
  return async (dispatch) => {
    dispatch(clearStateDeleteAddressBookGroupMember());
  };
}
export function clearStateupdateAddressBookGroupEntryAction() {
  return async (dispatch) => {
    dispatch(clearStateupdateAddressBookGroupEntry());
  };
}

export function clearStateExportGroupDataAction() {
  return async (dispatch) => {
    dispatch(clearStateExportGroupData());
  };
}
export const GroupDataSelector = (state) => state.Group;
export default GroupDataSlice.reducer;
