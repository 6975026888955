import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../redux/axios";

const initialState = {
  scheduleEditData: [], // Initial schedule data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  deleteSchedule: {},
  isSuccessDeleteSchedule: false,
  isErrorDeleteSchedule: false,
  isErrorMessageDeleteSchedule: "",

  editSchedule: {},
  isFetchingEdit: false,
  isSuccessEditSchedule: false,
  isErrorEditSchedule: false,
  isErrorMessageEditSchedule: "",

  isFetchingUpdate: false,
  updateSchedule: {},
  isSuccessUpdateSchedule: false,
  isErrorUpdateSchedule: false,
  isErrorMessageUpdateSchedule: "",
};

export const getScheduleData = createAsyncThunk(
  "schedule/get-all-schedule", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/sms/listSchedule` + (udata || "");

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteScheduleData = createAsyncThunk(
  "schedule/delete-schedule",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const response = await axios.delete(
        `/sms/abort/schedule?scheduleId=${udata.id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      let data = response;
      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getEditScheduleData = createAsyncThunk(
  "schedule/edit-schedule",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `sms/edit/schedule?scheduleId=${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateScheduleData = createAsyncThunk(
  "schedule/update-schedule",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `sms/modify`,
        // ?sendBulkScheduleRequest=` + JSON.stringify(udata.details),
        udata.formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendScheduleData = createAsyncThunk(
  "schedule/update-schedule",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `sms/send-now`,
        udata.formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    clearStateSchedule: (state, { payload }) => {
      state.scheduleEditData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateDeleteSchedule: (state, { payload }) => {
      state.deleteSchedule = {};
      state.isFetching = false;
      state.isSuccessDeleteSchedule = false;
      state.isErrorDeleteSchedule = false;
      state.isErrorMessageDeleteSchedule = "";
    },
    clearStateEditSchedule: (state, { payload }) => {
      state.editSchedule = {};
      state.isFetching = false;
      state.isSuccessEditSchedule = false;
      state.isErrorEditSchedule = false;
      state.isErrorMessageEditSchedule = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScheduleData.fulfilled, (state, action) => {
        state.scheduleEditData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getScheduleData.rejected, (state, action) => {
        state.scheduleEditData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      //Get Edit Schedule Data
      .addCase(getEditScheduleData.fulfilled, (state, action) => {
        state.editSchedule = action.payload;
        state.isFetchingEdit = false;
        state.isSuccess = true;
      })
      .addCase(getEditScheduleData.pending, (state) => {
        state.isFetchingEdit = true;
      })
      .addCase(getEditScheduleData.rejected, (state, action) => {
        state.editSchedule = {};
        state.isFetchingEdit = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      // Delete Schedule API
      .addCase(deleteScheduleData.fulfilled, (state, action) => {
        state.deleteSchedule = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteSchedule = true;

        if (state.scheduleEditData.length === 1) state.scheduleEditData = [];
      })
      .addCase(deleteScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteScheduleData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteSchedule = true;
        state.isErrorMessageDeleteSchedule = action.payload
          ? action.payload.message
          : "Internal error";
      })
      // Update Schedule api
      .addCase(updateScheduleData.fulfilled, (state, action) => {
        state.updateSchedule = action.payload;
        state.isFetchingUpdate = false;
        state.isSuccessUpdateSchedule = true;
        state.isErrorMessageUpdateSchedule = action.payload.data;
      })
      .addCase(updateScheduleData.pending, (state) => {
        state.isFetchingUpdate = true;
      })
      .addCase(updateScheduleData.rejected, (state, action) => {
        state.isFetchingUpdate = false;
        state.isErrorUpdateSchedule = true;
        state.isErrorMessageUpdateSchedule = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const {
  clearStateSchedule,
  clearStateDeleteSchedule,
  clearStateEditSchedule,
} = scheduleSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateSchedule());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteSchedule());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditSchedule());
  };
}

export const scheduleEditSelector = (state) => state.scheduleEdit;

export default scheduleSlice.reducer;
