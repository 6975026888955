import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../redux/axios";

const initialState = {
  // Normal
  autoScheduleData: null,
  isAutoScheduleFetching: false,
  isAutoScheduleSuccess: false,
  isAutoScheduleError: false,
  errorAutoScheduleMessage: "",

  // Media Upload
  autoScheduleMediaUploadData: null,
  isAutoScheduleMediaUploadFetching: false,
  isAutoScheduleMediaUploadSuccess: false,
  isAutoScheduleMediaUploadError: false,
  errorAutoScheduleMediaUploadMessage: "",
};

export const autoScheduleMediaUpload = createAsyncThunk(
  "sms/auto-schedule/media",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post("/sms/upload/media", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const autoScheduleMessage = createAsyncThunk(
  "sms/auto-schedule",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let formData = new FormData();

      formData.append("bulkAutoScheduleRequest", JSON.stringify(udata.details));
      formData.append("destinationNumberFile", udata.formData);

      const { data, status } = await axios.post(
        "/sms/auto-schedule",
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const autoScheduleSlice = createSlice({
  name: "sendAutoSchedule",
  initialState,
  reducers: {
    clearStateAutoScheduleMediaUpload: (state, { payload }) => {
      state.autoScheduleMediaUploadData = null;
      state.isAutoScheduleMediaUploadFetching = false;
      state.isAutoScheduleMediaUploadSuccess = false;
      state.isAutoScheduleMediaUploadError = false;
      state.errorAutoScheduleMediaUploadMessage = "";
    },
    clearStateAutoScheduleData: (state, { payload }) => {
      state.autoScheduleData = null;
      state.isAutoScheduleFetching = false;
      state.isAutoScheduleSuccess = false;
      state.isAutoScheduleError = false;
      state.errorAutoScheduleMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(autoScheduleMediaUpload.fulfilled, (state, action) => {
        state.autoScheduleMediaUploadData = action.payload;
        state.isAutoScheduleMediaUploadFetching = false;
        state.isAutoScheduleMediaUploadSuccess = true;
      })
      .addCase(autoScheduleMediaUpload.pending, (state) => {
        state.isAutoScheduleMediaUploadFetching = true;
      })
      .addCase(autoScheduleMediaUpload.rejected, (state, action) => {
        state.isAutoScheduleMediaUploadFetching = false;
        state.isAutoScheduleMediaUploadError = true;
        state.errorAutoScheduleMediaUploadMessage = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const { clearStateAutoScheduleMediaUpload, clearStateAutoScheduleData } =
  autoScheduleSlice.actions;

export function clearAutoScheduleMediaUploadState() {
  return async (dispatch) => {
    dispatch(clearStateAutoScheduleMediaUpload());
  };
}
export function clearAutoScheduleState() {
  return async (dispatch) => {
    dispatch(clearStateAutoScheduleData());
  };
}

export const autoScheduleSelector = (state) => state.autoSchedule;

export default autoScheduleSlice.reducer;
