import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  alertList: [],
  isFetchingAlertList: false,
  isSuccessAlertList: false,
  isErrorAlertList: false,
  errorMessageAlertList: "",

  isFetchingAddAlert: false,
  isFetchingEditData: false,
  isFetchingUdateAlert: false,
};

export const getAlertList = createAsyncThunk(
  "alert/get-alert-list",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/get`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);
export const getSmscCountryOptions = createAsyncThunk(
  "alert/setup",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/setup`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);
export const addAlert = createAsyncThunk(
  "/alerts/add",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/add`;

      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);
export const getEditAlertData = createAsyncThunk(
  "alert/edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/edit/` + udata;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);
export const updateAlert = createAsyncThunk(
  "/alerts/update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/update/` + udata.id.id;

      const { data, status } = await axios.put(url, udata.payload, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);
export const deleteAlert = createAsyncThunk(
  "/alerts/delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const url = `/alerts/delete/` + udata;

      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response ? err.response.data : err);
    }
  }
);

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    clearStateAlertList: (state) => {
      state.isFetchingAlertList = false;
      state.isSuccessAlertList = false;
      state.isErrorAlertList = false;
      state.errorMessageAlertList = "";
    },
    clearStateAddAlert: (state) => {
      state.addAlert = {};
      state.isSuccessAddAlert = false;
      state.isErrorAddAlert = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlertList.pending, (state) => {
        state.isFetchingAlertList = true;
        state.isErrorAlertList = false;
        state.errorMessageAlertList = "";
      })
      .addCase(getAlertList.fulfilled, (state, action) => {
        state.alertList = action.payload.data;
        state.isFetchingAlertList = false;
        state.isSuccessAlertList = true;
        state.isErrorAlertList = false;
      })
      .addCase(getAlertList.rejected, (state, action) => {
        state.alertList = [];
        state.isFetchingAlertList = false;
        state.isSuccessAlertList = false;
        state.isErrorAlertList = true;
        state.errorMessageAlertList =
          action.payload?.message || "Internal error";
      })
      .addCase(addAlert.pending, (state) => {
        state.isFetchingAddAlert = true;
      })
      .addCase(addAlert.fulfilled, (state) => {
        state.isFetchingAddAlert = false;
      })
      .addCase(addAlert.rejected, (state) => {
        state.isFetchingAddAlert = false;
      })
      .addCase(getSmscCountryOptions.pending, (state) => {
        state.isFetchingAddAlert = true;
      })
      .addCase(getSmscCountryOptions.fulfilled, (state) => {
        state.isFetchingAddAlert = false;
      })
      .addCase(getSmscCountryOptions.rejected, (state) => {
        state.isFetchingAddAlert = false;
      })
      .addCase(getEditAlertData.pending, (state) => {
        state.isFetchingEditData = true;
      })
      .addCase(getEditAlertData.fulfilled, (state) => {
        state.isFetchingEditData = false;
      })
      .addCase(getEditAlertData.rejected, (state) => {
        state.isFetchingEditData = false;
      });
  },
});

export const { clearStateAlertList, clearStateAddAlert } = alertSlice.actions;

export const clearStateAlertListAction = () => async (dispatch) => {
  dispatch(clearStateAlertList());
};

export const clearStateAddAlertAction = () => async (dispatch) => {
  dispatch(clearStateAddAlert());
};

export const alertSelector = (state) => state.alert;

export default alertSlice.reducer;
