import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  isFetchingEmailLink: false,
  isFetchingUpdatePassword: false,
  linkVerified: false,
  otpSent: false,
  otpVerified: false,
  passwordUpdated: false,
  isError: false,
  errorMessage: true,
};
const fakeApi = (url, body, headers, delay = 1000, message = "") => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let random = Math.random();
      random > 0.5
        ? resolve({ data: { success: true }, status: 200 })
        : reject({
            response: {
              data: {
                error: true,
                message: message || "Could not send email link",
              },
            },
            status: 500,
          });
    }, delay);
  });
};
axios.fakeApi = fakeApi;

export const sendEmailLink = createAsyncThunk(
  "password/email-link",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        "/login/changePassword",
        {
          headers: {
            username: udata.userName
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const verifyLink = createAsyncThunk(
  "password/verify-link",
  async (udata, thunkAPI) => {
    try {
      let url = udata.tokenString;
      const { data, status } = await axios.get(
        "/login/validate-link?encryptData=" + encodeURIComponent(url)
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const verifyOTP = createAsyncThunk(
  "password/verify-otp",
  async (udata, thunkAPI) => {
    try {
      const { data, status } = await axios.get(
        "/login/validateOtp?otp=" +
          udata.otp +
          "&encryptData=" +
          encodeURIComponent(udata.encryptData)
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const resendOTP = createAsyncThunk(
  "password/resend-otp",
  async (udata, thunkAPI) => {
    try {
      let url = udata.tokenString;
      const { data, status } = await axios.get(
        "/login/validate-link?encryptData=" + encodeURIComponent(url)
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updatePassword = createAsyncThunk(
  "password/update-password",
  async (udata, thunkAPI) => {
    try {
      const { data, status } = await axios.put(
        "/login/updatePassword?encryptData=" +
          encodeURIComponent(udata.encryptData),
        { oldPassword: udata.oldPassword, newPassword: udata.newPassword }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const forgetPassword = createAsyncThunk(
  "password/forget-password",
  async (udata, thunkAPI) => {
    try {
      const { data, status } = await axios.put(
        "/login/forgotPassword?encryptData=" +
          encodeURIComponent(udata.encryptData) +
          "&newPassword=" +
          udata.newPassword,
        {}
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updatePasswordSlice = createSlice({
  name: "updatePassword",
  initialState,
  reducers: {
    clearState: (state) => {
      state.linkSent = false;
      state.otpSent = false;
      state.otpVerified = false;
      state.passwordUpdated = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendEmailLink.pending, (state, action) => {
        state.isFetchingEmailLink = true;
      })
      .addCase(sendEmailLink.fulfilled, (state, action) => {
        state.isFetchingEmailLink = false;
      })
      .addCase(sendEmailLink.rejected, (state, action) => {
        state.isFetchingEmailLink = false;
      })
      .addCase(verifyLink.pending, (state, action) => {
        state.linkSent = false;
        state.otpSent = false;
        state.otpVerified = false;
        state.passwordUpdated = false;
      })
      .addCase(verifyLink.fulfilled, (state, action) => {
        state.otpSent = true;
        state.linkVerified = true;
      })
      .addCase(verifyLink.rejected, (state, action) => {
        state.linkVerified = false;
        state.otpSent = false;
        state.isError = true;
        state.errorMessage = action.payload?.message;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.otpVerified = true;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.otpVerified = false;
      })
      .addCase(updatePassword.pending, (state, action) => {
        state.isFetchingUpdatePassword = true;
        state.passwordUpdated = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isFetchingUpdatePassword = false;
        state.passwordUpdated = false;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isFetchingUpdatePassword = false;
      })
      .addCase(resendOTP.pending, (state, action) => {
        state.otpSent = false;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.otpSent = true;
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.linkVerified = false;
        state.otpSent = false;
        state.isError = true;
        state.errorMessage = action.payload?.message;
      });
  },
});

export const { clearState } = updatePasswordSlice.actions;

export function clearStateFn() {
  return async (dispatch) => {
    dispatch(clearState());
  };
}

// export function clearLogoutAuthState() {
//   return async (dispatch) => {
//     dispatch(logoutAuth());
//   };
// }

//index file ko is authslice file se connect krna h to hame ye 90 or 93 export karana paedhega
export const updatePasswordSelector = (state) => state.updatePassword;

export default updatePasswordSlice.reducer;
