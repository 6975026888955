import React, { memo, useEffect, useState } from "react";
import LoaderSvg from "../../assets/img/loadmessagedark.svg";
import LoaderSvgLight from "../../assets/img/loadmessagelight.svg";

const LoaderComponent = memo(({ className, size = 150, padding = "p-5" }) => {
  const [isDarkMode, setIsDarkMode] = useState(
    document.querySelector("body").className.includes("dark")
  );

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          (mutation.type === "attributes") &
          (mutation.attributeName === "class")
        ) {
          setIsDarkMode(
            document.querySelector("body").className.includes("dark")
          );
        }
      }
    });

    const config = { attributes: true, childList: true, subtree: false };

    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={
        "d-flex justify-content-center align-items-center" +
        className +
        " " +
        (padding ? padding : "")
      }
    >
      <img
        src={isDarkMode ? LoaderSvgLight : LoaderSvg}
        alt="Loading..."
        role="status"
        className="prevent-dark-icon"
        style={{ width: size, height: "auto" }}
        loading="lazy"
      />
    </div>
  );
});

export default LoaderComponent;
