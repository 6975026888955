import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DownloadsIcon from "../../../assets/img/file-progress-download.svg";
import Modal from "../../../components/Common/Modal";
import DownloadLogCard from "./DownloadLogCard";
import {
  deleteDownloadLog,
  downloadProgressSelector,
} from "./DownloadLogSlice";
import "./downloadLog.scss";

const DownloadProgressModal = ({ close, show }) => {
  const { downloads } = useSelector(downloadProgressSelector);
  const dispatch = useDispatch();

  const clearDownload = (id) => {
    dispatch(deleteDownloadLog({ id }));
  };

  return (
    <Modal show={show} close={close} justify="right" centerOnMobile>
      <div className="bg-white h-100">
        <div className="modal-header" style={{ borderRadius: 0 }}>
          <h6 className="modal-type d-flex align-items-center fs-22 gap-2">
            <img
              src={DownloadsIcon}
              style={{ width: "50px", height: "50px" }}
              loading="lazy"
            />
            File Download
          </h6>
          <svg
            className="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={close}
          >
            <path
              d="M20 4L4 20M4 4L20 20"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className="modal-body p-3"
          style={{
            overflow: "auto",
            height: "calc(100% - 80px)",
          }}
        >
          {downloads.length > 0 ? (
            downloads.map((download, index) => (
              <DownloadLogCard
                key={download.id}
                download={download}
                index={index}
                deleteLog={() => clearDownload(download.id)}
              />
            ))
          ) : (
            <div className="w-100 h-100 d-flex align-items-center">
              <p className="py-4 w-100 fs-4 text-center justify-content-center">
                No File Download in Progress
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DownloadProgressModal;
