import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  isFetching: false,
  walletData: [],
  isSuccessWallet: false,
  isErrorWallet: false,
  errorMessageWallet: "",
};

export const walletUserData = createAsyncThunk(
  "wallet",
  async (udata = "", thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let body = udata.body ?? {};
      let searchParam = udata.query ? "?searchParameter=" + udata.query : "";
      let tokenStr = auth.user.accessToken;
      const response = await axios.post(
        "/reports/transactions" + searchParam,
        body,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearStateWallet: (state, { payload }) => {
      state.walletData = [];
      state.isFetching = false;
      state.isSuccessWallet = false;
      state.isErrorWallet = false;
      state.errorMessageWallet = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(walletUserData.fulfilled, (state, action) => {
        state.walletData = action.payload;
        state.isFetching = false;
        state.isSuccessWallet = true;
      })
      .addCase(walletUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(walletUserData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorWallet = true;
        state.errorMessageWallet = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});
export const { clearStateWallet } = walletSlice.actions;

export function clearStateWalletFn() {
  return async (dispatch) => {
    dispatch(clearStateWallet());
  };
}

export const walletSelector = (state) => state.wallet;

export default walletSlice.reducer;
