import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  salesData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  isFetchingUserList: false,

  createSale: {},
  isSuccessCreateSale: false,
  isErrorCreateSale: false,
  isErrorMessageCreateSale: "",

  deleteSale: {},
  isSuccessDeleteSale: false,
  isErrorDeleteSale: false,
  isErrorMessageDeleteSale: "",

  editSale: {},
  isSuccessEditSale: false,
  isErrorEditSale: false,
  isErrorMessageEditSale: "",
};

// Thunks for async operations
export const getSalesData = createAsyncThunk(
  "sales/get-all-sales",
  async (params, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = params ? `/sales/salesUsers` + params : `/sales/salesUsers`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSaleData = createAsyncThunk(
  "sales/create-sale",
  async (saleData, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/sales/save", saleData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSaleData = createAsyncThunk(
  "sales/delete-sale",
  async (saleId, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(`/sales/delete/${saleId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSaleData = createAsyncThunk(
  "sales/update-sale",
  async (saleData, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(`/sales/update`, saleData, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const salesSetupData = createAsyncThunk(
  "sales/setup",
  async (saleData, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(`/sales/setup-sales-entry`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// Slice
const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    clearStateSales: (state) => {
      state.salesData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateSale: (state) => {
      state.createSale = {};
      state.isSuccessCreateSale = false;
      state.isErrorCreateSale = false;
      state.isErrorMessageCreateSale = "";
    },
    clearStateDeleteSale: (state) => {
      state.deleteSale = {};
      state.isSuccessDeleteSale = false;
      state.isErrorDeleteSale = false;
      state.isErrorMessageDeleteSale = "";
    },
    clearStateEditSale: (state) => {
      state.editSale = {};
      state.isSuccessEditSale = false;
      state.isErrorEditSale = false;
      state.isErrorMessageEditSale = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Sales Data
      .addCase(getSalesData.fulfilled, (state, action) => {
        state.salesData = action.payload?.data;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getSalesData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSalesData.rejected, (state, action) => {
        state.salesData = [];
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Create Sale
      .addCase(createSaleData.fulfilled, (state, action) => {
        state.createSale = action.payload;
        state.isFetching = false;
        state.isSuccessCreateSale = true;
        state.isErrorCreateSale = false;
      })
      .addCase(createSaleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSaleData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateSale = true;
        state.isSuccessCreateSale = false;
        state.isErrorMessageCreateSale = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Delete Sale
      .addCase(deleteSaleData.fulfilled, (state, action) => {
        state.deleteSale = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteSale = true;
        state.isErrorDeleteSale = false;
        if (state.salesData.length === 1) state.salesData = [];
      })
      .addCase(deleteSaleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSaleData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteSale = true;
        state.isSuccessDeleteSale = false;
        state.isErrorMessageDeleteSale = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Edit Sale
      .addCase(editSaleData.fulfilled, (state, action) => {
        state.editSale = action.payload;
        state.isFetching = false;
        state.isSuccessEditSale = true;
        state.isErrorEditSale = false;
      })
      .addCase(editSaleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSaleData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditSale = true;
        state.isSuccessEditSale = false;
        state.isErrorMessageEditSale = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(salesSetupData.pending, (state) => {
        state.isFetchingUserList = true;
      })
      .addCase(salesSetupData.rejected, (state) => {
        state.isFetchingUserList = false;
      })
      .addCase(salesSetupData.fulfilled, (state) => {
        state.isFetchingUserList = false;
      });
  },
});

export const {
  clearStateSales,
  clearStateCreateSale,
  clearStateDeleteSale,
  clearStateEditSale,
} = salesSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateSales());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateSale());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteSale());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditSale());
  };
}

export const salesSelector = (state) => state.sales;

export default salesSlice.reducer;
