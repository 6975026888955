import React from "react";
import Select from "react-select";

const CustomSelect = ({ selectData }) => {
  const { fileType, handleFileTypeChange, downloadTypes } = selectData;

  const customStylesForSelect = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderColor: "red",
      borderRadius: "9px 0 0 9px",
      ":hover": {
        border: "none",
        outline: "none",
      },
    }),
    option: (provided) => {
      return {
        ...provided,
        backgroundColor: "white",
      };
    },
  };

  return (
    <Select
      value={fileType}
      onChange={handleFileTypeChange}
      options={downloadTypes}
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
        IndicatorsContainer: () => {
          return (
            <svg
              height="20"
              width="20"
              fill="black"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
              style={{ transform: "translateX(-8px)" }}
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          );
        },
        Option: (state) => {
          const index = downloadTypes.findIndex(
            (option) => option.value === state.data.value
          );
          function handleDropDownClick(data) {
            state.setValue(data);
          }

          return (
            <div
              onClick={() => handleDropDownClick(downloadTypes[index])}
              className={
                "d-flex align-items-center " +
                (index !== downloadTypes.length - 1 ? "mb-1 pb-1" : "")
              }
              style={{
                justifyContent: "space-between",
                ...(index !== downloadTypes.length - 1
                  ? {
                      borderBottom: "1px solid #b5b5b5",
                    }
                  : {}),
              }}
            >
              <img
                style={{ height: 30 }}
                src={downloadTypes[index].image}
                alt="doc-image"
                loading="lazy"
              />
              <input
                type="checkbox"
                name=""
                id=""
                style={{
                  marginRight: 8,
                  borderRadius: "50%",
                }}
                defaultChecked={
                  downloadTypes[index].value === state.getValue()[0].value
                }
              />
            </div>
          );
        },
      }}
      formatOptionLabel={(fileType) => (
        <div
          className="d-flex align-items-center"
          style={{
            width: 25,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={fileType.image} alt="doc-image" loading="lazy" />
        </div>
      )}
      styles={customStylesForSelect}
    />
  );
};

export default CustomSelect;
