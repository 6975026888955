import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";
import { encrypted } from "../Common/encrypted";
import { storeItem } from "../../lib/utility";

const initialState = {
  user: {},
  accessDevice: false,
  otpScreen: false,
  confirmOtp: false,
  accountExpired: false,
  isFetching: false,
  isLoginSuccess: false,
  isOTPSuccess: false,
  isAccessDeviceSuccess: false,
  isError: false,
  errorMessage: "",
  // sidebar navigating options
  navOptions: {},
  isFetchingNavOptions: false,
  isnavOptionsFetchSuccess: false,
  isnavOptionsFetchError: false,
  navOptionsFetchErrorMessage: "",

  isFetchingLandingInfo: false,

  isFetchingLandingInfoCreate: false,
  isSuccessCreateLandingInfo: false,

  isFetchingLoginPanelSave: false,
  isErrorUpdateLandingInfo: false,
  updateLandingInfoErrorMsg: "",
  isFetchingLandingInfoAll: false,
};

const getIPAddress = async () => {
  try {
    let { data } = await axios.get("https://api.ipify.org/");
    if (data) return data;
  } catch (err) {
    showToast(
      "Error Occured while getting your IP. Please Refresh page.",
      "error"
    );
  }
};
export const loginUser = createAsyncThunk(
  "auth/login",
  async (udata, thunkAPI) => {
    try {
      const ipAddress = await getIPAddress();
      const encryptedData = encrypted({ ...udata, ipAddress });
      const response = await axios.post(
        "/login/jwt",
        {},
        {
          params: {
            loginRequest: encryptedData,
          },
        }
      );
      let { data, status, headers } = response;

      if (response.status === 200) {
        return { data, status: headers.get("X-Custom-Info") ?? status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let accessToken = auth.user.accessToken;
      const response = await axios.post(
        "/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let { data, status, headers } = response;

      if (response.status === 200) {
        return { data, status: headers.get("X-Custom-Info") };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async (udata, thunkAPI) => {
    try {
      const ipAddress = await getIPAddress();
      let encryptedData = encrypted({ ...udata, ipAddress });

      const response = await axios.post(
        "/login/verifyOtp",
        {},
        {
          params: {
            loginRequest: encryptedData,
          },
        }
      );
      let { data, status, headers } = response;

      if (status === 200) {
        return { data, status: headers.get("X-Custom-Info") };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const accessDeviceThunk = createAsyncThunk(
  "auth/authenticateMultiUser",
  async (udata, thunkAPI) => {
    try {
      const ipAddress = await getIPAddress();
      let encryptedData = encrypted({ ...udata, ipAddress });
      const response = await axios.post(
        "/login/authenticateMultiUser",
        encryptedData,
        {
          params: {
            loginRequest: encryptedData,
          },
        }
      );
      let { data, status, headers } = response;

      if (status === 200) {
        return { data, status: headers.get("X-Custom-Info") };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const accessDeviceSkip = createAsyncThunk(
  "auth/accessDeviceSkip",
  async (udata, thunkAPI) => {
    try {
      const ipAddress = await getIPAddress();
      let encryptedData = encrypted({ ...udata, ipAddress });

      const response = await axios.post(
        "/login/authenticateSkip",
        encryptedData,
        {
          params: {
            loginRequest: encryptedData,
          },
        }
      );
      let { data, status, headers } = response;

      if (status === 200) {
        return { data, status: headers.get("X-Custom-Info") };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// thunk for get web access api (nav options)

export const getNavOptionsData = createAsyncThunk(
  "login/web-access",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let { data, status } = await axios.get(`/login/web-access`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getLandingInfo = createAsyncThunk(
  "login/user/data",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let { data, status } = await axios.get(`users/panel/public/` + udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateLandingInfo = createAsyncThunk(
  "update/landingInfo",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let systemId = auth.user.username;

      const { content, logo, icons, refId } = udata;

      const formData = new FormData();
      formData.append("logo", logo);
      Array.from(icons).forEach((icon, index) => {
        formData.append("icons", icon);
      });
      formData.append("content", content);

      const { data, status } = await axios.put(
        `/users/panel/${refId}?systemId=` + systemId,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createLandingInfo = createAsyncThunk(
  "create/landingInfo",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let systemId = auth.user.username;

      const { content, logo, icons, refId } = udata;

      const formData = new FormData();
      formData.append("logo", logo);
      Array.from(icons).forEach((icon, index) => {
        formData.append("icons", icon);
      });
      formData.append("content", content);

      let { data, status } = await axios.post(
        `/users/panel?systemId=` + systemId,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearStateAuth: (state, { payload }) => {
      state.isFetching = false;
      state.accessDevice = false;
      state.otpScreen = false;
      state.confirmOtp = false;
      state.accountExpired = false;
      state.isError = false;
      state.errorMessage = "";
      state.isLoginSuccess = false;
    },
    logoutAuth: (state, { payload }) => {
      state.user = {};
      state.isFetching = false;
      state.isLoginSuccess = false;
      state.isOTPSuccess = false;
      state.isAccessDeviceSuccess = false;
      state.isError = false;
      state.accessDevice = false;
      state.otpScreen = false;
      state.confirmOtp = false;
      state.accountExpired = false;
      state.errorMessage = "";

      localStorage.removeItem("savedSearch");
      localStorage.removeItem("dateRange");
      localStorage.removeItem("pinnedGraph");

      if (globalThis.stallTime) {
        clearInterval(globalThis.stallTime);
        globalThis.stallTime = null;
        globalThis.notificationTime = null;
      }
    },
    clearPanelUpdate: (state) => {
      state.isFetchingLoginPanelSave = false;
      state.isErrorUpdateLandingInfo = false;
      state.updateLandingInfoErrorMsg = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(accessDeviceSkip.fulfilled, (state, action) => {
        let { data, status } = action.payload;

        state.accessDevice = false;
        if (status === "001") {
          state.accessDevice = true;
        } else if (status === "002") {
          state.otpScreen = true;
        } else if (status === "003") {
          state.otpScreen = true;
        } else if (status === "403") {
          state.accountExpired = true;
        } else {
          state.otpScreen = false;
          state.accessDevice = false;
          state.accountExpired = false;
          state.user = data;
          state.isLoginSuccess = true;
          globalThis.isAuthenticated = true;
        }
        state.isFetching = false;
        state.isAccessDeviceSuccess = true;
      })
      .addCase(accessDeviceSkip.pending, (state) => {
        state.isFetching = true;
        state.isAccessDeviceSuccess = false;
        state.errorMessage = "";
        state.isError = false;
      })
      .addCase(accessDeviceSkip.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(accessDeviceThunk.fulfilled, (state, action) => {
        let { data, status } = action.payload;

        state.accessDevice = false;
        if (status === "001") {
          state.accessDevice = true;
        } else if (status === "002") {
          state.otpScreen = true;
        } else if (status === "003") {
          state.otpScreen = true;
        } else if (status === "403") {
          state.accountExpired = true;
        } else {
          state.otpScreen = false;
          state.accessDevice = false;
          state.accountExpired = false;
          state.user = data;
          state.isLoginSuccess = true;
          globalThis.isAuthenticated = true;
        }
        state.isFetching = false;
        state.isAccessDeviceSuccess = true;
      })
      .addCase(accessDeviceThunk.pending, (state) => {
        state.isFetching = true;
        state.errorMessage = "";
        state.isError = false;
      })
      .addCase(accessDeviceThunk.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? "Device Access does not matched"
          : "Internal error";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        let { data, status } = action.payload;

        state.otpScreen = false;
        if (status === "001") {
          state.accessDevice = true;
        } else if (status === "002") {
          state.otpScreen = true;
        } else if (status === "003") {
          state.otpScreen = true;
        } else if (status === "403") {
          state.accountExpired = true;
        } else {
          state.otpScreen = false;
          state.accessDevice = false;
          state.accountExpired = false;
          state.user = data;
          state.isLoginSuccess = true;
          globalThis.isAuthenticated = true;
        }
        state.isFetching = false;
        state.isOTPSuccess = true;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.isFetching = true;
        state.isOTPSuccess = false;
        state.errorMessage = "";
        state.isError = false;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        let { data, status } = action.payload;

        if (status === "001") {
          state.accessDevice = true;
        } else if (status === "002") {
          state.otpScreen = true;
        } else if (status === "003") {
          state.otpScreen = true;
        } else if (status === "403") {
          state.accountExpired = true;
        } else {
          state.otpScreen = false;
          state.accessDevice = false;
          state.accountExpired = false;
          state.user = data;
          state.isLoginSuccess = true;
          globalThis.isAuthenticated = true;
          storeItem("userId", data.id);
        }

        state.isFetching = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
        state.errorMessage = "";
        state.isError = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      // webAccess api add cases
      .addCase(getNavOptionsData.fulfilled, (state, action) => {
        state.navOptions = action.payload;
        state.isFetchingNavOptions = false;
        state.isnavOptionsFetchSuccess = true;
      })
      .addCase(getNavOptionsData.pending, (state) => {
        state.isFetchingNavOptions = true;
        state.isnavOptionsFetchSuccess = false;
        state.isnavOptionsFetchError = false;
        state.navOptionsFetchErrorMessage = "";
      })
      .addCase(getNavOptionsData.rejected, (state, action) => {
        state.navOptions = {};
        state.isFetchingNavOptions = false;
        state.isnavOptionsFetchError = true;
        state.navOptionsFetchErrorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getLandingInfo.pending, (state, action) => {
        state.isFetchingLandingInfo = true;
      })
      .addCase(getLandingInfo.fulfilled, (state, action) => {
        state.isFetchingLandingInfo = false;
      })
      .addCase(getLandingInfo.rejected, (state, action) => {
        state.isFetchingLandingInfo = false;
      })
      .addCase(updateLandingInfo.pending, (state, action) => {
        state.isFetchingLoginPanelSave = true;
      })
      .addCase(updateLandingInfo.fulfilled, (state, action) => {
        state.isFetchingLoginPanelSave = false;
        state.isErrorUpdateLandingInfo = false;
        state.updateLandingInfoErrorMsg = "";
      })
      .addCase(updateLandingInfo.rejected, (state, action) => {
        state.isFetchingLoginPanelSave = false;
        state.isErrorUpdateLandingInfo = true;
        state.updateLandingInfoErrorMsg = action.payload
          ? action.payload.message
          : "Error while updating panel";
      })
      .addCase(createLandingInfo.pending, (state, action) => {
        state.isFetchingLoginPanelSave = true;
      })
      .addCase(createLandingInfo.fulfilled, (state, action) => {
        state.isFetchingLoginPanelSave = false;
        state.isSuccessCreateLandingInfo = true;
      })
      .addCase(createLandingInfo.rejected, (state, action) => {
        state.isFetchingLoginPanelSave = false;
        state.isSuccessCreateLandingInfo = false;
      });
  },
});

//destruture start
export const { clearStateAuth, logoutAuth, clearPanelUpdate } =
  authSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateAuth());
  };
}

export function clearLogoutAuthState() {
  return async (dispatch) => {
    dispatch(logoutAuth());
  };
}

//index file ko is authslice file se connect krna h to hame ye 90 or 93 export karana paedhega
export const authSelector = (state) => state.auth;

export default authSlice.reducer;
