import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  userData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  twoWayReportData: [],
  isFetchingTwoWayReport: false,
  isSuccessTwoWayReport: false,
  isErrorTwoWayReport: false,
  errorMessageTwoWayReport: "",

  addUser: {},
  isFetchingAdd: false,
  isSuccessAddUser: false,
  isErrorAddUser: false,
  isErrorMessageAddUser: "",

  deleteUser: {},
  isFetchingDelete: false,
  isSuccessDeleteUser: false,
  isErrorDeleteUser: false,
  isErrorMessageDeleteUser: "",

  getSingleTowWay: {},
  isFetchingSingle: true,
  isSuccessSingleTowWay: false,
  isErrorSingleTowWay: false,
  isErrorMessageSingleTowWay: "",

  editUser: {},
  isFetchingEdit: false,
  isSuccessEditUser: false,
  isErrorEditUser: false,
  isErrorMessageEditUser: "",

  deleteMultipleUser: "",
  isSuccessDeleteMultipleUser: false,
  isErrorDeleteMultipleUser: false,
  isErrorMessageDeleteMultipleUser: "",
};

export const getUserData = createAsyncThunk(
  "twoway/list-keyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/twoway/list-keyword${udata}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addUserData = createAsyncThunk(
  "twoway/addkeyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const response = await axios.post("/twoway/addkeyword", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteUserData = createAsyncThunk(
  "twoway/delete-keyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const response = await axios.delete(
        `/twoway/delete-keyword/${udata.id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      let data = response;
      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteMultipleTwoWayData = createAsyncThunk(
  "twoway/delete-all-keyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      let multiIds = udata.join();
      const { data, status } = await axios.delete(
        `/twoway/delete-all-keyword?ids=${multiIds}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { ...data, udata };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSingleTwoWayData = createAsyncThunk(
  "twoway/viewkeyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/twoway/viewkeyword/${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editUserData = createAsyncThunk(
  "twoway/update-keyword",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.put(
        "/twoway/update-keyword",
        {
          id: udata.id,
          userId: udata.userId,
          prefix: udata.prefix,
          suffix: udata.suffix,
          type: udata.type,
          shortCode: udata.shortCode,
          expiresOn: udata.expiresOn,
          reply: udata.reply,
          replyMessage: udata.replyMessage,
          replyOnFailed: udata.replyOnFailed,
          replyOnExpire: udata.replyOnExpire,
          alertNumber: udata.alertNumber,
          alertEmail: udata.alertEmail,
          alertUrl: udata.alertUrl,
          replySender: udata.replySender,
          sources: udata.sources,
        },
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      let data = response;
      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getTwoWayReport = createAsyncThunk(
  "twoway/view/report",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/twoway/view/report${udata}`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      let data = response;
      if (response.status === 200) {
        return data.data;
      } else if (response.status === 201) {
        return data.data;
      } else if (response.status === 404) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const twoWaySlice = createSlice({
  name: "twoway",
  initialState,
  reducers: {
    clearStateTwoWay: (state, { payload }) => {
      state.userData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateAddTwoWay: (state, { payload }) => {
      state.addUser = {};
      state.isFetching = false;
      state.isSuccessAddUser = false;
      state.isErrorAddUser = false;
      state.isErrorMessageAddUser = "";
    },
    clearStateDeleteTwoWay: (state, { payload }) => {
      state.deleteUser = {};
      state.isFetching = false;
      state.isSuccessDeleteUser = false;
      state.isErrorDeleteUser = false;
      state.isErrorMessageDeleteUser = "";
    },
    clearStateSingleTwoWay: (state, { payload }) => {
      state.getSingleTowWay = {};
      state.isFetchingSingle = false;
      state.isSuccessSingleTowWay = false;
      state.isErrorSingleTowWay = false;
      state.isErrorMessageSingleTowWay = "";
    },
    clearStateEditTwoWay: (state, { payload }) => {
      state.editUser = {};
      state.isFetching = false;
      state.isSuccessEditUser = false;
      state.isErrorEditUser = false;
      state.isErrorMessageEditUser = "";
    },
    clearStateReportTwoWay: (state, { payload }) => {
      state.twoWayReportData = [];
      state.isFetchingTwoWayReport = false;
      state.isSuccessTwoWayReport = false;
      state.isErrorTwoWayReport = false;
      state.errorMessageTwoWayReport = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(getUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserData.rejected, (state, action) => {
        // state.userData = [
        //   [],
        //   {
        //     firstPage: true,
        //     lastPage: true,
        //     pageNumber: 0,
        //     pageSize: 10,
        //     totalElements: 1,
        //     totalPages: 1,
        //   },
        // ];
        state.userData = [];
        state.isSuccess = false;
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      //Create TwoWay API
      .addCase(addUserData.fulfilled, (state, action) => {
        state.addUser = action.payload;
        state.isFetchingAdd = false;
        state.isSuccessAddUser = true;
      })
      .addCase(addUserData.pending, (state) => {
        state.isFetchingAdd = true;
      })
      .addCase(addUserData.rejected, (state, action) => {
        state.addUser = {};
        state.isFetchingAdd = false;
        state.isErrorAddUser = true;
        state.isErrorMessageAddUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Delete TwoWay API
      .addCase(deleteUserData.fulfilled, (state, action) => {
        state.deleteUser = action.payload;
        state.isFetchingDelete = false;
        state.isSuccessDeleteUser = true;

        if (state.userData.length === 1) state.userData = [];
      })
      .addCase(deleteUserData.pending, (state) => {
        state.isFetchingDelete = true;
      })
      .addCase(deleteUserData.rejected, (state, action) => {
        state.isFetchingDelete = false;
        state.isErrorDeleteUser = true;
        state.isErrorMessageDeleteUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Get single TwoWay api
      .addCase(getSingleTwoWayData.fulfilled, (state, action) => {
        state.isFetchingSingle = false;
        state.getSingleTowWay = action.payload.data;
        state.isSuccessSingleTowWay = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(getSingleTwoWayData.pending, (state) => {
        state.isFetchingSingle = true;
      })
      .addCase(getSingleTwoWayData.rejected, (state, action) => {
        state.isFetchingSingle = false;
        state.isErrorSingleTowWay = true;
        state.isErrorMessageSingleTowWay = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Edit TwoWay api
      .addCase(editUserData.fulfilled, (state, action) => {
        state.editUser = action.payload;
        state.isFetchingEdit = false;
        state.isSuccessEditUser = true;
        state.isErrorMessageEditUser = action.payload.data;
      })
      .addCase(editUserData.pending, (state) => {
        state.isFetchingEdit = true;
      })
      .addCase(editUserData.rejected, (state, action) => {
        state.isFetchingEdit = false;
        state.isErrorEditUser = true;
        state.isErrorMessageEditUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      //multiple TwoWay delete api
      .addCase(deleteMultipleTwoWayData.fulfilled, (state, action) => {
        state.deleteMultipleUser = action.payload;
        state.isSuccessDeleteUser = true;
        state.isFetching = false;

        if (action.payload.udata.length === state.userData.length)
          state.userData = [];
      })
      .addCase(deleteMultipleTwoWayData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteMultipleTwoWayData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteMultipleUser = true;
        state.isErrorMessageDeleteMultipleUser = action.payload
          ? action.payload.message
          : "Internal error";
      })

      //Two Way Report Api
      .addCase(getTwoWayReport.fulfilled, (state, action) => {
        state.twoWayReportData = action.payload;
        state.isFetchingTwoWayReport = false;
        state.isSuccessTwoWayReport = true;
        state.isErrorTwoWayReport = false;
      })
      .addCase(getTwoWayReport.pending, (state) => {
        state.isFetchingTwoWayReport = true;
      })
      .addCase(getTwoWayReport.rejected, (state, action) => {
        state.twoWayReportData = [
          [],
          {
            pageNumber: 0,
            pageSize: 10,
            totalPages: 1,
            totalElements: 1,
            lastPage: true,
            firstPage: true,
          },
        ];
        state.isSuccessTwoWayReport = false;
        state.isFetchingTwoWayReport = false;
        state.isErrorTwoWayReport = true;
        state.errorMessageTwoWayReport = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const {
  clearStateTwoWay,
  clearStateAddTwoWay,
  clearStateDeleteTwoWay,
  clearStateSingleTwoWay,
  clearStateEditTwoWay,
  clearStateReportTwoWay,
} = twoWaySlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateTwoWay());
  };
}

export function clearStateAdd() {
  return async (dispatch) => {
    dispatch(clearStateAddTwoWay());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteTwoWay());
  };
}

export function clearStateSingleTwoWayData() {
  return async (dispatch) => {
    dispatch(clearStateSingleTwoWay());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditTwoWay());
  };
}

export function clearStateReport() {
  return async (dispatch) => {
    dispatch(clearStateReportTwoWay());
  };
}

export const twoWaySelector = (state) => state.twoway;

export default twoWaySlice.reducer;
