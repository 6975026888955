import React, { useEffect, useRef, useState } from "react";
import { isArray } from "../../lib/utility";

const Select = ({
  options,
  onChange,
  value,
  labelValue,
  placeholder = "-Select-",
  log = false,
  style = {},
}) => {
  const [selectedValue, setSelectedValue] = useState(labelValue ?? value ?? "");
  const [selectedId, setSelectedId] = useState(value ?? "");
  const [focused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const valueSetRef = useRef(false);
  const labelSetRef = useRef(false);
  const selectRef = useRef();
  const { top, left, bottom, width, height, y, x } =
    selectRef?.current?.getBoundingClientRect() ?? {};
  const above = window.innerHeight - y - height - 3 < 200;
  options = options ? Array.from(new Set(options)) : [];

  const handleSelectClick = (e, focus) => {
    if (e.target.id === "customSelect" || focus) {
      setFocused((prev) => !prev);
    }
  };
  const handleOutSideClick = (e) => {
    if (
      e.target.id !== "customSelect" &&
      selectRef.current &&
      !selectRef.current.contains(e.target)
    ) {
      setFocused(false);
      window.removeEventListener("click", handleOutSideClick);
    }
  };
  const handleOptionClick = (opt) => {
    setSelectedId(opt.id);
    setSelectedValue(opt.label);
    setFocused((prev) => !prev);
    onChange(opt.id);
  };
  useEffect(() => {
    if (focused) {
      window.addEventListener("click", handleOutSideClick);
    }
  }, [focused]);

  useEffect(() => {
    if (value && !valueSetRef.current) {
      setSelectedId(value);
      setSelectedValue(labelValue ?? value);
      valueSetRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (labelValue && !labelSetRef.current) {
      setSelectedValue(labelValue);
      labelSetRef.current = true;
    }
    return () => {};
  }, []);

  const filteredOptions = isArray(options)
    ? options.filter((opt) =>
        opt.label?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];
  return (
    <div
      className={
        "customSelect fw-400 d-flex align-items-center " +
        (focused ? "focus" : "")
      }
      onClick={handleSelectClick}
      ref={selectRef}
      id="customSelect"
      style={style}
    >
      <style>
        {`
          .customSelect {
            position: relative;
            border: 1px solid #ced4da;
            padding: 6px 36px 6px 12px;
            border-radius: 8px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
            background-color: white;
          }
          .customSelect.focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .2);
          }
          .customSelect > p {
            font-weight: 400;
            font-size: 15px;  
          }
          .customSelect > p.text-gray {
            color: gray;
          }
          .customSelect > p {
            max-width: 100%;
            overflow: auto;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
          }
          .customSelect .customSelectDropdown {
            position: fixed;
            padding: 6px;
            display: block;
            z-index: 99999;
            background: white;
            border: 1px solid #ced4da;
            border-radius: 4px;
            overflow: auto;
            max-height: 200px;
            overflow-x: hidden;
          }
          .customSelect .customSelectDropdown .option {
            cursor: pointer;
            border-radius: 4px;
            padding: 0 6px;
          }
          .customSelect .customSelectDropdown .option:hover {
            background: #eee;
          }
          .customSelect .customSelectDropdown .option.active {
            background: #1967d2;
            color: white;
          }
          .customSelect .customSelectDropdown .option input {
            height: 16px;
            width: 16px;
            border-radius: 8px;
          }
          .customSelect .customSelectDropdown .option p {
            pointer-events: none;
            font-size: 15px;
            font-weight: 400;
          }
        `}
      </style>
      <p
        tabIndex={-1}
        className={
          "m-0 " + (selectedId.length === 0 ? "text-gray" : "text-black")
        }
        onClick={(e) => handleSelectClick(e, true)}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {!selectedValue ? placeholder : selectedValue}
      </p>
      {focused && (
        <div
          className={"customSelectDropdown " + (above ? "above" : "below")}
          tabIndex={-1}
          style={{
            left: `${x}px`,
            width: `${width}px`,
            ...(above
              ? { bottom: `${window.innerHeight - y + 3}px` }
              : { top: `${y + height + 3}px` }),
          }}
        >
          <div className="search-container mb-1">
            <input
              className="form-control"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          {filteredOptions.map((opt, index) => (
            <div
              className={
                "d-flex gap-2 align-items-center py-1 option" +
                (opt.label === selectedValue ? " active" : "")
              }
              onClick={() => handleOptionClick(opt)}
              key={`${opt.id}-${index}`}
            >
              <p className="form-label flex-grow w-100 m-0">{opt.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
