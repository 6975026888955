import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import LoaderComponent from "../../Common/LoaderComponent";
import { useSelector } from "react-redux";
import { reportSelector } from "../ReportSlice";
import { tableShortFormatDate } from "../../../Wrapper/CommonFun";
import { tableCustomStyles } from "../../../lib/utils";

const SubmissionTable = ({
  submissionTableData,
  isFetching,
  country,
  status,
}) => {
  const { isFetchingSubmissionUser } = useSelector(reportSelector);

  const columns = [
    {
      name: "Date",
      selector: (row) => tableShortFormatDate(row.Date, false),
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row.Count,
      sortable: true,
    },
  ];
  if (country && country !== "%") {
    columns.push({
      name: "Country",
      selector: (row) => row.Country,
      sortable: true,
    });
  }
  if (status && status !== "%") {
    columns.push({
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
    });
  }

  const data = useCallback(
    submissionTableData.map((row) => ({
      Date: row.startDate,
      Count: row.totalSmsSend,
      Country: row.country,
      Status: row.messageStatus,
    })),
    [submissionTableData]
  );

  return (
    <>
      <div className="table-responsive border br-12 table-action frozen m-3">
        {isFetchingSubmissionUser ? (
          <LoaderComponent />
        ) : (
          <DataTable
            className="h-100"
            columns={columns}
            data={data}
            highlightOnHover
            fixedHeader
            pagination
            customStyles={tableCustomStyles()}
            noDataComponent={
              <p className="p-4 text-center">No data available</p>
            }
            paginationRowsPerPageOptions={[
              50, 100, 150, 200, 250, 350, 400, 450, 500,
            ]}
            paginationPerPage={50}
          />
        )}
      </div>
    </>
  );
};

export default SubmissionTable;
