import React, { useCallback } from "react";
import DataTable from "react-data-table-component";

import { tableShortFormatDate } from "../../Wrapper/CommonFun";
import LoaderComponent from "../Common/LoaderComponent";

const CompanyUserTable = ({ isFetching, companyUserData }) => {
  const columns = [
    {
      name: "User Name",
      selector: (row) => row.systemId,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Master Id",
      selector: (row) => row.masterId,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => tableShortFormatDate(row.createdOn),
      sortable: true,
    },
  ];
  const data = useCallback(companyUserData, [companyUserData]);

  return (
    <>
      <div className="m-3 table-responsive border br-12 table-action">
        {isFetching ? (
          <LoaderComponent />
        ) : (
          <DataTable
            // customStyles={tableCustomStyles()}
            columns={columns}
            data={data}
            highlightOnHover
            fixedHeader
            pagination
            noDataComponent={<div className="p-4">No data available</div>}
          />
        )}
      </div>
    </>
  );
};

export default CompanyUserTable;
