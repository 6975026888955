import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasRoles } from "../../lib/utility";
import axios from "../../redux/axios";

const initialState = {
  getProfile: globalThis.profileData ?? {},
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  profileUpdate: {},

  isFetchingProfileUpdate: false,
  isSuccessProfileUpdate: false,
  isErrorProfileUpdate: false,
  isErrorMessageProfileUpdate: "",

  getalert: {},
  isFetchingAlert: false,
  isSuccessAlert: false,
  isErrorAlert: false,
  errorMessageAlert: "",
};

export const getProfileData = createAsyncThunk(
  "login/user/data",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let user = auth.user;
      let { data, status } = await axios.get(
        hasRoles("seller,manager", user)
          ? `/sales/view-sales-entry/` + auth.user.id
          : `/login/user/data`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return hasRoles("seller,manager", user)
          ? {
              ...data["seller"],
              profileName: data.profileName,
              profilePath: data.profilePath,
              status,
            }
          : { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userProfileUpdate = createAsyncThunk(
  "login/updateProfile",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { editableFields, selectedFile } = udata;
      const formData = new FormData();

      for (const key in editableFields) {
        if (
          Object.prototype.hasOwnProperty.call(editableFields, key) &&
          editableFields[key] !== undefined &&
          editableFields[key] !== null &&
          (editableFields[key] !== "" || editableFields[key] !== 0)
        ) {
          formData.append(key, editableFields[key]);
        }
      }

      if (selectedFile) {
        formData.append("image", selectedFile);
      }

      if (hasRoles("seller,manager", auth.user)) {
        formData.append("id", auth.user.id);
      }

      const { data, status } = await axios.put(
        hasRoles("seller,manager", auth.user)
          ? `/sales/update`
          : `/login/updateProfile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearStateProfile: (state, { payload }) => {
      state.getProfile = {};
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateProfileUpdateData: (state, { payload }) => {
      state.profileUpdate = {};
      state.isFetching = false;
      state.isSuccessProfileUpdate = false;
      state.isErrorProfileUpdate = false;
      state.isErrorMessageProfileUpdate = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.getProfile = action.payload;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = action.payload;
      })
      .addCase(getProfileData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      // Profile update api
      .addCase(userProfileUpdate.fulfilled, (state, action) => {
        state.profileUpdate = action.payload.data;
        state.isFetchingProfileUpdate = false;
        state.isSuccessProfileUpdate = true;
        state.isErrorMessageProfileUpdate = action.payload.data;
      })
      .addCase(userProfileUpdate.pending, (state) => {
        state.isFetchingProfileUpdate = true;
        state.isErrorMessageProfileUpdate = "";
        state.isErrorProfileUpdate = false;
      })
      .addCase(userProfileUpdate.rejected, (state, action) => {
        state.isFetchingProfileUpdate = false;
        state.isErrorProfileUpdate = true;
        state.isErrorMessageProfileUpdate = action.payload
          ? action.payload.message
          : "Internal error";
      });

    //Alert
    // .addCase(alert.fulfilled, (state, action) => {
    //   state.getalert = action.payload.data;
    //   state.isFetchingAlert = false;
    //   state.isSuccessAlert = true;
    //   state.errorMessageAlert = action.payload.data;
    // })
    // .addCase(alert.pending, (state) => {
    //   state.isFetchingAlert = true;
    // })
    // .addCase(alert.rejected, (state, action) => {
    //   state.isFetchingAlert = false;
    //   state.isErrorAlert = true;
    //   state.errorMessageAlert = action.payload
    //     ? action.payload.message
    //     : "Internal error";
    // });
  },
});

export const { clearStateProfile, clearStateProfileUpdateData } =
  profileSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateProfile());
  };
}
export function clearStateProfileUpdate() {
  return async (dispatch) => {
    dispatch(clearStateProfileUpdateData());
  };
}

export const profileSelector = (state) => state.profile;

export default profileSlice.reducer;
