import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { skipNullValue } from "../../lib/utility";
import axios from "../../redux/axios";
import { handleDownloadSizeProgress } from "../../Layouts/Navbar/DownloadProgress/DownloadLogSlice";

const initialState = {
  isFetching: false,

  batchUser: [],
  isFetchingBatch: false,
  isSuccessBatchUser: false,
  isErrorBatchUser: false,
  errorMessageBatchUser: "",

  abortedUser: [],
  isFetchingAborted: false,
  isSuccessAbortedUser: false,
  isErrorAbortedUser: false,
  errorMessageAbortedUser: "",

  deliverySummaryData: [],
  isFetchingDeliverySummary: false,
  isSuccessDeliverySummaryData: false,
  isErrorDeliverySummaryData: false,
  errorMessageDeliverySummaryData: "",

  contentUser: [],
  isFetchingContentUser: false,
  isSuccessContentUser: false,
  isErrorContentUser: false,
  errorMessageContentUser: "",

  advanceUser: [],
  isFetchingAdvance: false,
  isSuccessAdvanceUser: false,
  isErrorAdvanceUser: false,
  errorMessageAdvanceUser: "",

  scheduleUser: [],
  isFetchingScheduleUser: false,
  isSuccessScheduleUser: false,
  isErrorScheduleUser: false,
  errorMessageScheduleUser: "",

  latencyUser: [],
  isFetchingLatencyUser: false,
  isSuccessLatencyUser: false,
  isErrorLatencyUser: false,
  errorMessageLatencyUser: "",

  lookupUser: [],
  isFetchingLookupUser: false,
  isSuccessLookupUser: false,
  isErrorLookupUser: false,
  errorMessageLookupUser: "",

  submissionUser: [],
  isFetchingSubmissionUser: false,
  isSuccessSubmissionUser: false,
  isErrorSubmissionUser: false,
  errorMessageSubmissionUser: "",

  consumptionUser: [],
  isFetchingConsumptionUser: false,
  isSuccessConsumptionUser: false,
  isErrorConsumptionUser: false,
  errorMessageConsumptionUser: "",

  campaignUser: [],
  isFetchingCampaignUser: false,
  isSuccessCampaignUser: false,
  isErrorCampaignUser: false,
  errorMessageCampaignUser: "",

  smscData: [],
  isFetchingSmscData: false,
  isSuccessSmscData: false,
  isErrorSmscData: false,
  errorMessageSmscData: "",

  aggregatedUser: {
    totalTRN: 0,
    totalOTP: 0,
    totalMKT: 0,
    aggregators: [],
  },
  isFetchingAggregatedUser: false,
  isSuccessAggregatedUser: false,
  isErrorAggregatedUser: false,
  errorMessageAggregatedUser: "",

  isCountryListFetching: false,
  isOperatorListFetching: false,
  isCOperatorListFetching: false,

  profitData: [],
  isFetchingProfitData: false,
  isSuccessProfitData: false,
  isErrorProfitData: false,
  errorMessageProfitData: "",

  performanceUserData: [],
  isFetchingPerformanceUserData: false,
  isSuccessPerformanceUser: false,
  isErrorPerformanceUser: false,
  errorMessagePerformanceUser: "",

  performanceRouteData: [],
  isFetchingPerformanceRouteData: false,
  isSuccessPerformanceRoute: false,
  isErrorPerformanceRoute: false,
  errorMessagePerformanceRoute: "",

  coverageData: [],
  isFetchingCoverageData: false,
  isSuccessCoverage: false,
  isErrorCoverage: false,
  errorMessageCoverage: "",

  blockedData: [],
  isFetchingBlockedData: false,
  isSuccessBlocked: false,
  isErrorBlocked: false,
  errorMessageBlocked: "",

  isFetchingSaveFilter: false,
};

export const batchUserData = createAsyncThunk(
  "reports/summary-report-view",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        `/reports/summary-report?` + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );
      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status, download };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const abortedUserData = createAsyncThunk(
  "reports/abort-batch",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        `/reports/abort-batch?` + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );
      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("Network error:", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getAbortedNumbers = createAsyncThunk(
  "reports/aborted-numbers",
  async (id, thunkAPI, download = false) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/sms/abortedNumberList?abort_batch_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deliverySummaryUserData = createAsyncThunk(
  "reports/dlr-summary-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      const { data, status } = await axios.post(
        "/reports/dlr-summary-report?" + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );
      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const contentUserData = createAsyncThunk(
  "reports/content-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        "/reports/content-report?" + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );
      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const advanceUserData = createAsyncThunk(
  "reports/advance",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        "/reports/advance-report?" + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );
      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const scheduleUserData = createAsyncThunk(
  "reports/schedule-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      // let query = udata.str ? "?searchParameter=" + udata.str : "";
      const urlParams = new URLSearchParams();
      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      const { data, status } = await axios.post(
        "/reports/schedule-report?" + urlParams.toString(),
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const lookupUserData = createAsyncThunk(
  "reports/lookup-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const urlParams = new URLSearchParams();
      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        "/reports/lookup-report?" + urlParams.toString(),
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue({ ...data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const latencyUserData = createAsyncThunk(
  "reports/latency-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        "/reports/latency-report-view?" + urlParams.toString(),
        skipNullValue({ ...udata }),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const submissionUserData = createAsyncThunk(
  "reports/submission-report",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let query = udata.search ? "?searchParameter=" + udata.search : "";
      const { data, status } = await axios.post(
        "/reports/submission-execute" + query,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const consumptionUserData = createAsyncThunk(
  "reports/balance-report",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let query = udata.search ? "?searchParameter=" + udata.search : "";
      const { data, status } = await axios.post(
        "/reports/balance-report" + query,
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return {
          status,
          data: Object.keys(data).reduce((acc, key) => {
            return [...acc, ...data[key]];
          }, []),
        };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const aggregatedReportData = createAsyncThunk(
  "report/aggregated",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();

      let urlParam = new URLSearchParams();
      urlParam.append("startDate", udata.startDate);
      urlParam.append("endDate", udata.endDate);
      search && urlParam.append("searchParameter", search);
      urlParam.append("page", page || 0);
      urlParam.append("size", size || 20);

      if (udata.systemId) {
        urlParam.append("systemId", udata.systemId);
      }

      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        "/reports/aggregator-report?" + urlParam.toString(),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const campaignUserData = createAsyncThunk(
  "/reports/campaign-report",
  async ({ udata }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let queryString = udata?.search ? "searchParameter=" + udata.search : "";
      const { data, status } = await axios.post(
        `/reports/campaign-report-view?` + queryString,
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscData = createAsyncThunk(
  "/reports/delivery-smsc",
  async ({ udata, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let queryString = udata.search ? "searchParameter=" + udata.search : "";
      const { data, status } = await axios.post(
        `/reports/smsc-dlr-report?` + queryString,
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getProfitData = createAsyncThunk(
  "reports/profit",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);

      const { data, status } = await axios.post(
        `/reports/profit?` + urlParams.toString(),
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getPerformanceUserData = createAsyncThunk(
  "reports/performance-user",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();
      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      const { data, status } = await axios.post(
        `/reports/performance/user-delivery?${urlParams.toString()}`,
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getPerformanceRouteData = createAsyncThunk(
  "reports/performance-route",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();
      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      const { data, status } = await axios.post(
        `/reports/performance-route?${urlParams.toString()}`,
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getBlockedData = createAsyncThunk(
  "reports/blocked-report",
  async ({ udata, search, page, size, download = false }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      if (search) urlParams.append("searchParameter", search);
      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      const { data, status } = await axios.post(
        `/reports/blocked-report?` + urlParams.toString(),
        skipNullValue(udata),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
          ...(download
            ? {
                onDownloadProgress: (progressEvent) => {
                  // const totalBytes = progressEvent.total;
                  const downloadedBytes = progressEvent.loaded;
                  thunkAPI.dispatch(
                    handleDownloadSizeProgress({
                      id: download,
                      size: downloadedBytes * 1.05,
                    })
                  );
                },
              }
            : {}),
        }
      );

      let dataLength = JSON.stringify(data).length;
      if (status.toString().startsWith("2")) {
        if (download) {
          setTimeout(() => {
            thunkAPI.dispatch(
              handleDownloadSizeProgress({
                id: download,
                size: dataLength * 1.05,
              })
            );
          }, 450);
        }
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCoverageUserData = createAsyncThunk(
  "reports/coverage",
  async ({ udata }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/reports/coverage-report?userIds=` + udata.userIds,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCountryList = createAsyncThunk(
  "/reports/country",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/reports/all-countries?`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getOperatorList = createAsyncThunk(
  "/reports/operators",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/reports/all-operators`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getOperatorByCountryList = createAsyncThunk(
  "/reports/operators-by-country",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/reports/operators/` + udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getDashboardPerformanceList = createAsyncThunk(
  "/reports/dashboard-performance-list",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/reports/dashboard/list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getDashboardTrafficList = createAsyncThunk(
  "/reports/dashboard-traffic-list",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/reports/traffic/list`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCampaignList = createAsyncThunk(
  "/reports/campaignList",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/reports/`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const saveFilter = createAsyncThunk(
  "/reports/saveFilter",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/reports/recent-filters`,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSavedFilter = createAsyncThunk(
  "/reports/getSavedFilter",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/reports/recent-filters?purposeType=${udata}`,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    clearStateBatchReport: (state, { payload }) => {
      state.batchUser = [];
      state.isFetching = false;
      state.isSuccessBatchUser = false;
      state.isErrorBatchUser = false;
      state.errorMessageBatchUser = "";
    },
    clearStateAbortedReport: (state, { payload }) => {
      state.abortedUser = [];
      state.isFetching = false;
      state.isSuccessAbortedUser = false;
      state.isErrorAbortedUser = false;
      state.errorMessageAbortedUser = "";
    },
    clearStateDelievrySummaryReport: (state, { payload }) => {
      state.deliverySummaryData = [];
      state.isFetching = false;
      state.isSuccessDeliverySummaryData = false;
      state.isErrorDeliverySummaryData = false;
      state.errorMessageDeliverySummaryData = "";
    },
    clearStateContentReport: (state, { payload }) => {
      state.contentUser = [];
      state.isFetching = false;
      state.isSuccessContentUser = false;
      state.isErrorContentUser = false;
      state.errorMessageContentUser = "";
    },
    clearStateAdvanceReport: (state, { payload }) => {
      state.advanceUser = [];
      state.isFetching = false;
      state.isSuccessAdvanceUser = false;
      state.isErrorAdvanceUser = false;
      state.errorMessageAdvanceUser = "";
    },
    clearStateScheduleReport: (state, { payload }) => {
      state.scheduleUser = [];
      state.isFetching = false;
      state.isSuccessScheduleUser = false;
      state.isErrorScheduleUser = false;
      state.errorMessageScheduleUser = "";
    },
    clearStateLatencyReport: (state, { payload }) => {
      state.latencyUser = [];
      state.isFetching = false;
      state.isSuccessLatencyUser = false;
      state.isErrorLatencyUser = false;
      state.errorMessageLatencyUser = "";
    },
    clearStateLookupReport: (state, { payload }) => {
      state.lookupUser = [];
      state.isFetching = false;
      state.isSuccessLookupUser = false;
      state.isErrorLookupUser = false;
      state.errorMessageLookupUser = "";
    },
    clearStateSubmissionReport: (state, { payload }) => {
      state.submissionUser = [];
      state.isFetching = false;
      state.isSuccessSubmissionUser = false;
      state.isErrorSubmissionUser = false;
      state.errorMessageSubmissionUser = "";
    },
    clearStateConsumptionReport: (state, { payload }) => {
      state.consumptionUser = [];
      state.isFetching = false;
      state.isSuccessConsumptionUser = false;
      state.isErrorConsumptionUser = false;
      state.errorMessageConsumptionUser = "";
    },
    clearStateAggregatedReport: (state, { payload }) => {
      state.aggregatedUser = [];
      state.isFetching = false;
      state.isSuccessAggregatedUser = false;
      state.isErrorAggregatedUser = false;
      state.errorMessageAggregatedUser = "";
    },
    clearStateCampaignReport: (state, { payload }) => {
      state.campaignUser = [];
      state.isFetching = false;
      state.isSuccessCampaignUser = false;
      state.isErrorCampaignUser = false;
      state.errorMessageCampaignUser = "";
    },
    clearStateSmscReport: (state, { payload }) => {
      state.smscData = [];
      state.isFetching = false;
      state.isSuccessSmscData = false;
      state.isErrorSmscData = false;
      state.errorMessageSmscData = "";
    },
    clearStateProfitReport: (state, { payload }) => {
      state.profitData = [];
      state.isFetching = false;
      state.isSuccessProfitData = false;
      state.isErrorProfitData = false;
      state.errorMessageProfitData = "";
    },
    clearStatePerformanceUserReport: (state, { payload }) => {
      state.performanceUserData = [];
      state.isFetching = false;
      state.isSuccessPerformanceUser = false;
      state.isErrorPerformanceUser = false;
      state.errorMessagePerformanceUser = "";
    },
    clearStateCoverageReport: (state, { payload }) => {
      state.performanceUserData = [];
      state.isFetching = false;
      state.isSuccessPerformanceUser = false;
      state.isErrorPerformanceUser = false;
      state.errorMessagePerformanceUser = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Report-Batch-Summary api
      .addCase(batchUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.batchUser = action.payload.data[0];
          state.isFetchingBatch = false;
          state.isSuccessBatchUser = true;
          state.isErrorBatchUser = false;
        }
      })
      .addCase(batchUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingBatch = true;
        }
      })
      .addCase(batchUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.batchUser = [];
          state.isSuccessBatchUser = false;
          state.isFetchingBatch = false;
          state.isErrorBatchUser = true;
          state.errorMessageBatchUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Batch-Aborted api
      .addCase(abortedUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.abortedUser = action.payload.data[0];
          state.isFetchingAborted = false;
          state.isSuccessAbortedUser = true;
        }
      })
      .addCase(abortedUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingAborted = true;
          state.isErrorAbortedUser = false;
        }
      })
      .addCase(abortedUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.abortedUser = [];
          state.isFetchingAborted = false;
          state.isErrorAbortedUser = true;
          state.isSuccessAbortedUser = false;
          state.errorMessageAbortedUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Delivery-Summary api
      .addCase(deliverySummaryUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.deliverySummaryData = action.payload.data[0];
          state.isFetchingDeliverySummary = false;
          state.isSuccessDeliverySummaryData = true;
        }
      })
      .addCase(deliverySummaryUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingDeliverySummary = true;
        }
      })
      .addCase(deliverySummaryUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.deliverySummaryData = [];
          state.isFetchingDeliverySummary = false;
          state.isErrorDeliverySummaryData = true;
          state.errorMessageDeliverySummaryData = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Delivery-Content api
      .addCase(contentUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.contentUser = action.payload.data[0];
          state.isFetchingContentUser = false;
          state.isSuccessContentUser = true;
        }
      })
      .addCase(contentUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingContentUser = true;
        }
      })
      .addCase(contentUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.contentUser = [];
          state.isFetchingContentUser = false;
          state.isErrorContentUser = true;
          state.errorMessageContentUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Delivery-Advance api
      .addCase(advanceUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.advanceUser =
            (action.payload?.data && action.payload?.data[0]) || [];
          state.isFetchingAdvance = false;
          state.isSuccessAdvanceUser = true;
        }
      })
      .addCase(advanceUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingAdvance = true;
        }
      })
      .addCase(advanceUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.advanceUser = [];
          state.isFetchingAdvance = false;
          state.isErrorAdvanceUser = true;
          state.errorMessageAdvanceUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Schedule-view api
      .addCase(scheduleUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.scheduleUser = action.payload.data[0];
          state.isFetchingScheduleUser = false;
          state.isSuccessScheduleUser = true;
          state.isErrorScheduleUser = false;
        }
      })
      .addCase(scheduleUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingScheduleUser = true;
        }
      })
      .addCase(scheduleUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.scheduleUser = [];
          state.isSuccessScheduleUser = false;
          state.isFetchingScheduleUser = false;
          state.isErrorScheduleUser = true;
          state.errorMessageScheduleUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Latency-view api
      .addCase(latencyUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.latencyUser = action?.payload?.data?.[0] ?? [];
          state.isFetchingLatencyUser = false;
          state.isSuccessLatencyUser = true;
          state.isErrorLatencyUser = false;
        }
      })
      .addCase(latencyUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingLatencyUser = true;
        }
      })
      .addCase(latencyUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.latencyUser = [];
          state.isSuccessLatencyUser = false;
          state.isFetchingLatencyUser = false;
          state.isErrorLatencyUser = true;
          state.errorMessageLatencyUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Lookup api
      .addCase(lookupUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.lookupUser = action.payload.data[0];
          state.isFetchingLookupUser = false;
          state.isSuccessLookupUser = true;
          state.isErrorLookupUser = false;
        }
      })
      .addCase(lookupUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingLookupUser = true;
        }
      })
      .addCase(lookupUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.lookupUser = [];
          state.isSuccessLookupUser = false;
          state.isFetchingLookupUser = false;
          state.isErrorLookupUser = true;
          state.errorMessageLookupUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Submission-view api
      .addCase(submissionUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.submissionUser = action.payload.data;
          state.isFetchingSubmissionUser = false;
          state.isSuccessSubmissionUser = true;
          state.isErrorSubmissionUser = false;
        }
      })
      .addCase(submissionUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingSubmissionUser = true;
        }
      })
      .addCase(submissionUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.submissionUser = [];
          state.isSuccessSubmissionUser = false;
          state.isFetchingSubmissionUser = false;
          state.isErrorSubmissionUser = true;
          state.errorMessageSubmissionUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Customized-view api
      .addCase(consumptionUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.consumptionUser = action.payload.data;
          state.isFetchingConsumptionUser = false;
          state.isSuccessConsumptionUser = true;
        }
      })
      .addCase(consumptionUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingConsumptionUser = true;
        }
      })
      .addCase(consumptionUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.consumptionUser = [];
          state.isFetchingConsumptionUser = false;
          state.isErrorConsumptionUser = true;
          state.errorMessageConsumptionUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Aggregated-view api
      .addCase(aggregatedReportData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.aggregatedUser = action.payload.data[0];
          state.isFetchingAggregatedUser = false;
          state.isSuccessAggregatedUser = true;
        }
      })
      .addCase(aggregatedReportData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingAggregatedUser = true;
        }
      })
      .addCase(aggregatedReportData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.aggregatedUser = [];
          state.isFetchingAggregatedUser = false;
          state.isErrorAggregatedUser = true;
          state.errorMessageAggregatedUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Campaign
      .addCase(campaignUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.campaignUser = action.payload.data;
          state.isFetchingCampaignUser = false;
          state.isSuccessCampaignUser = true;
          state.isErrorCampaignUser = false;
        }
      })
      .addCase(campaignUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingCampaignUser = true;
        }
      })
      .addCase(campaignUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.campaignUser = [];
          state.isFetchingCampaignUser = false;
          state.isErrorCampaignUser = true;
          state.isSuccessCampaignUser = false;
          state.errorMessageCampaignUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Smsc
      .addCase(getSmscData.fulfilled, (state, action) => {
        if (!action.meta?.arg?.download) {
          state.smscData = action.payload?.data;
          state.isFetchingSmscData = false;
          state.isSuccessSmscData = true;
          state.isErrorSmscData = false;
        }
      })
      .addCase(getSmscData.pending, (state, action) => {
        if (!action.meta?.arg?.download) {
          state.isFetchingSmscData = true;
        }
      })
      .addCase(getSmscData.rejected, (state, action) => {
        if (!action.meta?.arg?.download) {
          state.smscData = [];
          state.isFetchingSmscData = false;
          state.isErrorSmscData = true;
          state.isSuccessSmscData = false;
          state.errorMessageSmscData = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Profit
      .addCase(getProfitData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.profitData = action.payload.data;
          state.isFetchingProfitData = false;
          state.isSuccessProfitData = true;
          state.isErrorProfitData = false;
        }
      })
      .addCase(getProfitData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingProfitData = true;
        }
      })
      .addCase(getProfitData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.profitData = [];
          state.isFetchingProfitData = false;
          state.isErrorProfitData = true;
          state.isSuccessProfitData = false;
          state.errorMessageProfitData = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })

      //Report-Performance-User
      .addCase(getPerformanceUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.performanceUserData = action.payload.data[0];
          state.isFetchingPerformanceUserData = false;
          state.isSuccessPerformanceUser = true;
          state.isErrorPerformanceUser = false;
        }
      })
      .addCase(getPerformanceUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingPerformanceUserData = true;
        }
      })
      .addCase(getPerformanceUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.performanceUserData = [];
          state.isFetchingPerformanceUserData = false;
          state.isErrorPerformanceUser = true;
          state.isSuccessPerformanceUser = false;
          state.errorMessagePerformanceUser = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })
      //Report-Performance-Route
      .addCase(getPerformanceRouteData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.performanceRouteData = action.payload.data[0];
          state.isFetchingPerformanceRouteData = false;
          state.isSuccessPerformanceRoute = true;
          state.isErrorPerformanceRoute = false;
        }
      })
      .addCase(getPerformanceRouteData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingPerformanceRouteData = true;
        }
      })
      .addCase(getPerformanceRouteData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.performanceRouteData = [];
          state.isFetchingPerformanceRouteData = false;
          state.isErrorPerformanceRoute = true;
          state.isSuccessPerformanceRoute = false;
          state.errorMessagePerformanceRoute = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })
      //Report-Blocked
      .addCase(getBlockedData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.blockedData = action.payload.data;
          state.isFetchingBlockedData = false;
          state.isSuccessBlocked = true;
          state.isErrorBlocked = false;
        }
      })
      .addCase(getBlockedData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingBlockedData = true;
        }
      })
      .addCase(getBlockedData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.blockedData = [];
          state.isFetchingBlockedData = false;
          state.isErrorBlocked = true;
          state.isSuccessBlocked = false;
          state.errorMessageBlocked = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })
      .addCase(getCoverageUserData.fulfilled, (state, action) => {
        if (!action.meta?.arg.download) {
          state.coverageData = action.payload.data;
          state.isFetchingCoverageData = false;
          state.isSuccessCoverage = true;
          state.isErrorCoverage = false;
        }
      })
      .addCase(getCoverageUserData.pending, (state, action) => {
        if (!action.meta?.arg.download) {
          state.isFetchingCoverageData = true;
        }
      })
      .addCase(getCoverageUserData.rejected, (state, action) => {
        if (!action.meta?.arg.download) {
          state.coverageData = [];
          state.isFetchingCoverageData = false;
          state.isErrorCoverage = true;
          state.isSuccessCoverage = false;
          state.errorMessageCoverage = action.payload
            ? action.payload.message
            : "Internal error";
        }
      })
      .addCase(saveFilter.pending, (state) => {
        state.isFetchingSaveFilter = true;
      })
      .addCase(saveFilter.rejected, (state) => {
        state.isFetchingSaveFilter = false;
      })
      .addCase(saveFilter.fulfilled, (state) => {
        state.isFetchingSaveFilter = false;
      });
  },
});

export const {
  clearStateBatchReport,
  clearStateAbortedReport,
  clearStateDelievrySummaryReport,
  clearStateContentReport,
  clearStateAdvanceReport,
  clearStateLatencyReport,
  clearStateLookupReport,
  clearStateSubmissionReport,
  clearStateScheduleReport,
  clearStateConsumptionReport,
  clearStateAggregatedReport,
  clearStateCampaignReport,
  clearStateSmscReport,
  clearStateProfitReport,
  clearStatePerformanceUserReport,
  clearStateCoverageReport,
} = reportSlice.actions;

export function clearStateBatch() {
  return async (dispatch) => {
    dispatch(clearStateBatchReport());
  };
}

export function clearStateAborted() {
  return async (dispatch) => {
    dispatch(clearStateAbortedReport());
  };
}

export function clearStateDeliverySummary() {
  return async (dispatch) => {
    dispatch(clearStateDelievrySummaryReport());
  };
}

export function clearStateContent() {
  return async (dispatch) => {
    dispatch(clearStateContentReport());
  };
}

export function clearStateAdvance() {
  return async (dispatch) => {
    dispatch(clearStateAdvanceReport());
  };
}

export function clearStateSchedule() {
  return async (dispatch) => {
    dispatch(clearStateScheduleReport());
  };
}

export function clearStateLatency() {
  return async (dispatch) => {
    dispatch(clearStateLatencyReport());
  };
}

export function clearStateLookup() {
  return async (dispatch) => {
    dispatch(clearStateLookupReport());
  };
}

export function clearStateSubmission() {
  return async (dispatch) => {
    dispatch(clearStateSubmissionReport());
  };
}

export function clearStateConsumption() {
  return async (dispatch) => {
    dispatch(clearStateConsumptionReport());
  };
}

export function clearStateAggregated() {
  return async (dispatch) => {
    dispatch(clearStateAggregatedReport());
  };
}

export function clearStateCampaign() {
  return async (dispatch) => {
    dispatch(clearStateCampaignReport());
  };
}

export function clearStateSmsc() {
  return async (dispatch) => {
    dispatch(clearStateSmscReport());
  };
}

export function clearStateProfit() {
  return async (dispatch) => {
    dispatch(clearStateProfitReport());
  };
}
export function clearStatePerformanceUser() {
  return async (dispatch) => {
    dispatch(clearStatePerformanceUserReport());
  };
}
export function clearStateCoverage() {
  return async (dispatch) => {
    dispatch(clearStateCoverageReport());
  };
}

export const reportSelector = (state) => state.report;

export default reportSlice.reducer;
