import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import csvIcon from "../assets/img/csvicon.svg";
import {
  addDownloadToLog,
  addSuccessDownloadToLog,
  completedDownload,
} from "../Layouts/Navbar/DownloadProgress/DownloadLogSlice";
import { downloadCSV, isArray, kindOf, showToast } from "../lib/utility";
import { middleware } from "../redux/store";
import { REPORTSERVERTIME } from "../lib/utils";

export const usePaginationNDownload = (
  getReportData,
  reportData,
  downloadOptions = {}
) => {
  let {
    getReportThunk,
    reportName,
    filterData,
    searchVal,
    mappable,
    selector,
    chartsRef,
  } = downloadOptions;
  let downloadFactor = REPORTSERVERTIME[reportName] ?? 0.04;

  const [pagination, setPagination] = useState({
    current: 0,
    size: 50,
    isLastPage: false,
    totalPages: 0,
  });
  const [fileType, setFileType] = useState({
    value: "csv",
    label: "CSV",
    image: csvIcon,
  });

  const dispatch = useDispatch();

  const downloadXLSX = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb, fileName);
  };
  const downloadPDF = async (data, fileName) => {
    let doc;
    // let dataLength = Array.isArray(data) && data[0].Object.keys.length ? data.length : 0;
    let dataLength =
      Array.isArray(data) && data.length > 0 && Object.keys(data[0]).length
        ? Object.keys(data[0]).length
        : 0;
    if (dataLength < 7) {
      doc = new jsPDF();
    } else {
      doc = new jsPDF("landscape");
    }

    if (chartsRef) {
      let canvas1 = await html2canvas(chartsRef.current[0]);
      let fimg1 = new Image();
      fimg1.src = canvas1.toDataURL("png");
      doc.addImage(fimg1, "png", 12, 21, 90, 65);

      // Convert the second chart to a canvas, then to an image
      let canvas2 = await html2canvas(chartsRef.current[1]);
      let fimg2 = new Image();
      fimg2.src = canvas2.toDataURL("png");
      doc.addImage(fimg2, "png", 108, 21, 90, 65);
    }

    doc.setFontSize(16);
    chartsRef
      ? doc.text(reportName.replaceAll(" ", "-"), 13, 97)
      : doc.text(reportName.replaceAll(" ", "-"), 13, 20);

    doc.setFontSize(12);

    let pdfTable = Object.keys(mappable([{}])[0]);
    const tableData = data.map((item) =>
      pdfTable.map((column) => item[column])
    );

    tableData.unshift(pdfTable);

    // Generate a table in the PDF with the provided data
    doc.autoTable({
      startY: chartsRef ? 103 : 27,
      head: tableData.splice(0, 1),
      body: tableData,
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(9);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      if (dataLength < 7) {
        doc.text(`Pages ${i} - ${pageCount}`, 98, 290); // Adjust position as needed
      } else {
        doc.text(`Pages ${i} - ${pageCount}`, 140, 200); // Adjust position as needed
      }
    }

    // Save the PDF with a specified name
    doc.save(fileName ? fileName : reportName.replaceAll(" ", "-") + ".pdf");
  };
  const handlePageChange = (page) => {
    if (
      pagination.current !== page - 1 &&
      reportData.length !== 0 &&
      page < pagination.totalPages
    ) {
      setPagination((prev) => ({ ...prev, current: page - 1 }));
      kindOf(getReportData, "function") &&
        getReportData(undefined, undefined, page - 1);
    }
  };
  const handlePerPageChange = (size) => {
    if (pagination.size !== size && size !== 10) {
      let page = pagination.current;
      if (pagination.current * size > pagination.totalItems)
        page = parseInt(pagination.totalItems / size);

      setPagination((prev) => ({ ...prev, size: size, current: page }));
      kindOf(getReportData, "function") &&
        getReportData(undefined, undefined, page, size);
    }
  };
  const downloadContent = () => {
    if (pagination.totalPages === 0) {
      showToast("No Data to download", "info");
      return;
    }
    let currId = Date.now();

    dispatch(
      addDownloadToLog({
        name: `${reportName}`,
        id: currId,
        totalTime: pagination.totalItems * downloadFactor + 400 + 200,
        type: fileType.value,
        url: window.location.href,
      })
    );

    dispatch(
      getReportThunk({
        udata: filterData,
        search: searchVal,
        page: 0,
        size: pagination.totalItems,
        download: currId,
      })
    ).then((action) =>
      middleware(
        action,
        reportName,
        (action) => {
          if (isArray(action.payload?.data)) {
            let data = mappable(
              selector ? selector(action.payload) : action.payload.data[0]
            );
            if (fileType.value === "xls" && data.length > 0) {
              downloadXLSX(data, `${reportName.replaceAll(" ", "-")}.xlsx`);
            } else if (fileType.value === "csv" && data.length > 0) {
              downloadCSV(data, `${reportName.replaceAll(" ", "-")}.csv`);
            } else if (fileType.value === "pdf" && data.length > 0) {
              downloadPDF(data, `${reportName.replaceAll(" ", "-")}.pdf`);
            }
          }
          if (fileType.value) {
            dispatch(
              completedDownload({
                name: `${reportName} ${fileType.value}`,
                id: currId,
              })
            );
          }
        },
        "Error while Downloading " + reportName
      )
    );
  };

  return {
    pagination,
    setPagination,
    fileType,
    setFileType,
    handlePageChange,
    handlePerPageChange,
    downloadContent,
  };
};
export const useDownloadData = (reportData, downloadOptions = {}) => {
  let { reportName, mappable, chartsRef } = downloadOptions;

  const [fileType, setFileType] = useState({
    value: "csv",
    label: "CSV",
    image: csvIcon,
  });

  const dispatch = useDispatch();

  const downloadXLSX = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet");
    XLSX.writeFile(wb, fileName);
  };
  const downloadPDF = async (data, fileName) => {
    let doc;
    // let dataLength = Array.isArray(data) && data.length ? data.length : 0;
    let dataLength =
      Array.isArray(data) && data.length > 0 && Object.keys(data[0]).length
        ? Object.keys(data[0]).length
        : 0;

    if (dataLength < 7) {
      doc = new jsPDF();
    } else {
      doc = new jsPDF("landscape");
    }

    if (chartsRef) {
      let canvas1 = await html2canvas(chartsRef.current[0]);
      let fimg1 = new Image();
      fimg1.src = canvas1.toDataURL("png");
      doc.addImage(fimg1, "png", 12, 21, 90, 65);

      // Convert the second chart to a canvas, then to an image
      let canvas2 = await html2canvas(chartsRef.current[1]);
      let fimg2 = new Image();
      fimg2.src = canvas2.toDataURL("png");
      doc.addImage(fimg2, "png", 108, 21, 90, 65);
    }

    doc.setFontSize(16);
    chartsRef
      ? doc.text(reportName.replaceAll(" ", "-"), 13, 97)
      : doc.text(reportName.replaceAll(" ", "-"), 13, 20);

    doc.setFontSize(12);

    let pdfTable = Object.keys(mappable([{}])[0]);
    const tableData = data.map((item) =>
      pdfTable.map((column) => item[column])
    );

    tableData.unshift(pdfTable);

    // Generate a table in the PDF with the provided data
    doc.autoTable({
      startY: chartsRef ? 103 : 27,
      head: tableData.splice(0, 1),
      body: tableData,
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(9);

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      if (dataLength < 7) {
        doc.text(`Pages ${i} - ${pageCount}`, 98, 290); // Adjust position as needed
      } else {
        doc.text(`Pages ${i} - ${pageCount}`, 140, 200); // Adjust position as needed
      }
    }

    doc.save(fileName ? fileName : reportName.replaceAll(" ", "-") + ".pdf");
  };
  const downloadContent = () => {
    const data = mappable(reportData);
    if (!isArray(data)) {
      showToast("Bad Table Data", "warning");
      return;
    }
    if (data.length === 0) {
      showToast("No Data to download", "info");
      return;
    }

    let dataLength = JSON.stringify(reportData).length;
    dispatch(
      addSuccessDownloadToLog({
        name: `${reportName}`,
        id: Date.now(),
        totalTime: data.length,
        type: fileType.value,
        url: window.location.href,
        size: dataLength * 1.05,
      })
    );

    if (fileType.value === "xls" && data.length > 0) {
      downloadXLSX(data, `${reportName.replaceAll(" ", "-")}.xlsx`);
    } else if (fileType.value === "csv" && data.length > 0) {
      downloadCSV(data, `${reportName.replaceAll(" ", "-")}.csv`);
    } else if (fileType.value === "pdf" && data.length > 0) {
      downloadPDF(data, `${reportName.replaceAll(" ", "-")}.pdf`);
    }
  };

  return {
    fileType,
    setFileType,
    downloadContent,
  };
};
