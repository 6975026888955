import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import "./assets/css/modal.scss";
import "./components/Report/Report.scss";
import router from "./components/Routers/Router";
import { checkUserStatus } from "./components/User/UserSlice";
import { useFetch } from "./hooks/useFetch";
import useResponsive from "./hooks/useResponsive";
import { getItem } from "./lib/utility";
import { persistor } from "./redux/store";

function App() {
  const dispatch = useDispatch();
  let publicRoutes = ["/update-password"];

  const [isVerified, setIsVerified] = useState(false);

  globalThis.profileData = getItem("profileData", true) || null;
  globalThis.isFirstTime =
    globalThis.isFirstTime === undefined ? true : globalThis.isFirstTime;

  const okayElements = document.querySelectorAll(".overlay");
  okayElements.forEach((element) => {
    const parentDiv = element.parentNode;
    if (parentDiv && parentDiv.tagName === "DIV") {
      parentDiv.classList.add("ddd");
    }
  });

  const checkJWTToken = () => {
    return new Promise((resolve) => {
      if (isVerified) {
        resolve(true);
        return;
      }
      if (!getItem("persist:root")) {
        resolve(false);
        return;
      }

      if (publicRoutes.includes(window.location.pathname)) {
        resolve(false);
        return;
      } else {
        dispatch(checkUserStatus()).then((action) => {
          if (action.error) {
            resolve(false);
            return;
          }
          resolve(true);
          return;
        });
      }
    });
  };

  useResponsive();
  useFetch(() => {
    checkJWTToken().then((verified) => {
      if (verified) {
        globalThis.isAuthenticated = true;
      } else {
        globalThis.isAuthenticated = false;
        localStorage.clear();
        persistor.purge();
      }
      setIsVerified(true);
    });
  }, []);

  return (
    isVerified && (
      <>
        <RouterProvider router={router} />
      </>
    )
  );
}

export default App;
