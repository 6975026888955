import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../../redux/axios";

const initialState = {
  bulkEditData: [], // Initial bulk data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  deleteBulk: {},
  isSuccessDeleteBulk: false,
  isErrorDeleteBulk: false,
  isErrorMessageDeleteBulk: "",

  editBulk: {},
  isFetchingEdit: false,
  isSuccessEditBulk: false,
  isErrorEditBulk: false,
  isErrorMessageEditBulk: "",

  isFetchingUpdate: false,
  updateBulk: {},
  isSuccessUpdateBulk: false,
  isErrorUpdateBulk: false,
  isErrorMessageUpdateBulk: "",
};

export const getBulkData = createAsyncThunk(
  "bulk/get-all-bulk", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/sms/list` + (udata || "");

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteBulkData = createAsyncThunk(
  "bulk/delete-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/sms/abort?batch_Id=${udata.id}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getEditBulkData = createAsyncThunk(
  "bulk/edit-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`sms/edit?batch_Id=${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateBulkData = createAsyncThunk(
  "bulk/update-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `sms/sendModified`,
        udata.formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendBulkData = createAsyncThunk(
  "bulk/update-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `sms/send-now`,
        udata.formData,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const pauseBulkData = createAsyncThunk(
  "bulk/update-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`sms/pause?batch_Id=` + udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const resumeBulkData = createAsyncThunk(
  "bulk/update-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`sms/resume?batch_Id=` + udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const bulkSlice = createSlice({
  name: "bulk",
  initialState,
  reducers: {
    clearStateBulk: (state, { payload }) => {
      state.bulkEditData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateDeleteBulk: (state, { payload }) => {
      state.deleteBulk = {};
      state.isFetching = false;
      state.isSuccessDeleteBulk = false;
      state.isErrorDeleteBulk = false;
      state.isErrorMessageDeleteBulk = "";
    },
    clearStateEditBulk: (state, { payload }) => {
      state.editBulk = {};
      state.isFetching = false;
      state.isSuccessEditBulk = false;
      state.isErrorEditBulk = false;
      state.isErrorMessageEditBulk = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBulkData.fulfilled, (state, action) => {
        state.bulkEditData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getBulkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getBulkData.rejected, (state, action) => {
        state.bulkEditData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      //Get Edit Bulk Data
      .addCase(getEditBulkData.fulfilled, (state, action) => {
        state.editBulk = action.payload;
        state.isFetchingEdit = false;
        state.isSuccess = true;
      })
      .addCase(getEditBulkData.pending, (state) => {
        state.isFetchingEdit = true;
      })
      .addCase(getEditBulkData.rejected, (state, action) => {
        state.editBulk = {};
        state.isFetchingEdit = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      // Delete Bulk API
      .addCase(deleteBulkData.fulfilled, (state, action) => {
        state.deleteBulk = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteBulk = true;

        if (state.bulkEditData.length === 1) state.bulkEditData = [];
      })
      .addCase(deleteBulkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteBulkData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteBulk = true;
        state.isErrorMessageDeleteBulk = action.payload
          ? action.payload.message
          : "Internal error";
      })
      // Update Bulk api
      .addCase(updateBulkData.fulfilled, (state, action) => {
        state.updateBulk = action.payload;
        state.isFetchingUpdate = false;
        state.isSuccessUpdateBulk = true;
        state.isErrorMessageUpdateBulk = action.payload.data;
      })
      .addCase(updateBulkData.pending, (state) => {
        state.isFetchingUpdate = true;
      })
      .addCase(updateBulkData.rejected, (state, action) => {
        state.isFetchingUpdate = false;
        state.isErrorUpdateBulk = true;
        state.isErrorMessageUpdateBulk = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const { clearStateBulk, clearStateDeleteBulk, clearStateEditBulk } =
  bulkSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateBulk());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteBulk());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditBulk());
  };
}

export const bulkEditSelector = (state) => state.bulkEdit;

export default bulkSlice.reducer;
