import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  downloadData: [], // Initial user data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  exportData: [], // Initial user data is an array containing an empty object
  isFetchingexportData: false,
  isSuccessexportData: false,
  isErrorexportData: false,
  errorMessageexportData: "",
  sendFile: [], // Initial user data is an array containing an empty object
  isFetchingsendFile: false,
  isSuccesssendFile: false,
  isErrorsendFile: false,
  errorMessagesendFile: "",
};

export const getUserData = createAsyncThunk(
  "download/pricing-list",
  async (date, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      var url = `/download/pricing-list`;

      if (date) {
        const { startDate, endDate, search } = date;
        const urlParams = new URLSearchParams();
        if (startDate) urlParams.append("startDate", startDate);
        if (endDate) urlParams.append("endDate", endDate);
        if (search) urlParams.append("Search", search);
        var url = `/download/pricing-list?${urlParams.toString()}`;
      }

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fileDownload = createAsyncThunk(
  "download/pricing?format=pdf",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const url = `/download/pricing?format=${udata}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
        responseType: "blob",
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sendAttachment = createAsyncThunk(
  "reports/send-attachment", // Action type
  async (userdata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/reports/send-attachment`;

      const response = await axios.post(url, userdata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      let data = response;
      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const DownloadsSlice = createSlice({
  name: "Downloads",
  initialState,
  reducers: {
    clearStateDownloads: (state, { payload }) => {
      state.downloadData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateSendAttachment: (state, { payload }) => {
      state.sendFile = [];
      state.isFetchingsendFile = false;
      state.isSuccesssendFile = false;
      state.isErrorsendFile = false;
      state.errorMessagesendFile = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.downloadData = action.payload;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getUserData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.downloadData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(fileDownload.fulfilled, (state, action) => {
        state.exportData = action.payload.error;
        state.isFetchingexportData = false;
        state.isSuccessexportData = true;
      })
      .addCase(fileDownload.pending, (state) => {
        state.isFetchingexportData = true;
      })
      .addCase(fileDownload.rejected, (state, action) => {
        state.isFetchingexportData = false;
        state.isErrorexportData = true;
        state.errorMessageexportData = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(sendAttachment.fulfilled, (state, action) => {
        state.sendFile = action.payload;
        state.isFetchingsendFile = false;
        state.isSuccesssendFile = true;
      })
      .addCase(sendAttachment.pending, (state) => {
        state.isFetchingsendFile = true;
      })
      .addCase(sendAttachment.rejected, (state, action) => {
        state.isFetchingsendFile = false;
        state.isErrorsendFile = true;
        state.errorMessagesendFile = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const { clearStateDownloads, clearStateSendAttachment } =
  DownloadsSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateDownloads());
  };
}
export function clearStateSendAttachmentAction() {
  return async (dispatch) => {
    dispatch(clearStateSendAttachment());
  };
}

export const DownloadsSelector = (state) => state.Downloads;

export default DownloadsSlice.reducer;
