import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  userData: [],
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  isFetchingMobileEmail: false,
  walletFlagType: false,

  isFetchingUserGet: false,
  isFetchingUserUpdate: false,
  isFetchingUserDelete: false,

  isFetchingMultiGet: false,
  isFetchingMultiUpdate: false,
  isFetchingMultiDelete: false,

  userLimitData: [],
  isFetchingLimitsGet: false,
  isFetchingLimitsUpdate: false,
  isFetchingLimitsDelete: false,

  isFetchingMultiUserAcessGet: false,
  isFetchingUserList: false,

  isFetchingUserBalance: false,
  isFetchingUserConfigurations: false,
  isFetchingUserSessions: false,

  smscNames: [],
  isSuccessSmscNames: false,
  isErrorSmscNames: false,
  errorMessageSmscNames: "",

  isFetchingWebAccessLog: false,
  isFetchingHlrSchedule: false,
  isFetchingAddHlrSchedule: false,
  isFetchingEditHlrSchedule: false,
  isFetchingDeleteHlrSchedule: false,
};

export const checkUserStatus = createAsyncThunk(
  "user/data-status",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/login/status", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userDataGet = createAsyncThunk(
  "user/data-get",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/users/?userId=" + udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const limitGet = createAsyncThunk(
  "user/limit-get",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/limits/list`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userDataSave = createAsyncThunk(
  "user/data-save",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/users/save`;

      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const multiAccessSave = createAsyncThunk(
  "user/multi-save",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/multiUser/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const limitSave = createAsyncThunk(
  "user/limit-save",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/limits/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userDataUpdate = createAsyncThunk(
  "user/data-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(
        "/users/update?userId=" + udata.userId,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const multiAcessUpdate = createAsyncThunk(
  "user/multi-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put("/multiUser/update", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const limitUpdate = createAsyncThunk(
  "user/limit-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put("/limits/update", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userDataDelete = createAsyncThunk(
  "user/data-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        "/users/delete?userId=" + udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const multiAcessDelete = createAsyncThunk(
  "user/multi-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        "/multiUser/delete?ids=" + udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const limitDelete = createAsyncThunk(
  "user/limit-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete("/limits/delete/" + udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userListGet = createAsyncThunk(
  "users/preInformation",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/users/pre-information?purpose=${udata}&walletFlag=707`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const multiUserListAccess = createAsyncThunk(
  "users/multiListAccess",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = `/multiUser/list/access?userId=${udata.userId}`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateUserBalance = createAsyncThunk(
  "users/update-balance",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/balance/updateBalance";

      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUsersConfigurations = createAsyncThunk(
  "users/get-configurations",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/users/configurations?userIds=" + udata;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getUsersSessions = createAsyncThunk(
  "users/get-sessions",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/users/sessions?userIds=" + udata;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateFlag = createAsyncThunk(
  "users/flag-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url =
        "/users/flagUpdate?userIds=" + udata.ids + "&flagValue=" + udata.flag;

      const { data, status } = await axios.post(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const userBatchIds = createAsyncThunk(
  "users/batch-id",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const url = "/users/batch-id";

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscNames = createAsyncThunk(
  "smsc-names",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/users/smsc-names`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editTransactionMode = createAsyncThunk(
  "balance/updateBalanceMode",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/balance/updateBalanceMode?walletFlag=${udata.walletFlag}&userId=${udata.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getWebAccessLog = createAsyncThunk(
  "users/access-logs",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(
        `/users/access-logs?systemid=${udata.systemid}`,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getHlrSchedule = createAsyncThunk(
  "users/hlr-schedules",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(`/users/hlr-schedules`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addHlrSchedule = createAsyncThunk(
  "users/hlr-schedules/add",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(`/users/hlr-schedules`, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editHlrSchedule = createAsyncThunk(
  "users/hlr-schedules/edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(
        "/users/hlr-schedules/" + udata.id,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteHlrSchedule = createAsyncThunk(
  "users/hlr-schedules/delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        "/users/hlr-schedules/" + udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMobileEmailById = createAsyncThunk(
  "sales/sales-Users",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(`/sales/sales-Users/${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearStateUser: (state) => {
      state.userData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateSmscNames: (state, { payload }) => {
      state.smscNames = [];
      state.isFetching = false;
      state.isSuccessSmscNames = false;
      state.isErrorSmscNames = false;
      state.errorMessageSmscNames = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userDataGet.pending, (state) => {
        state.isFetchingUserGet = true;
      })
      .addCase(userDataGet.fulfilled, (state) => {
        state.isFetchingUserGet = false;
      })
      .addCase(userDataGet.rejected, (state) => {
        state.isFetchingUserGet = false;
      })
      .addCase(limitGet.pending, (state) => {
        state.isFetchingLimitsGet = true;
      })
      .addCase(limitGet.fulfilled, (state, action) => {
        state.userLimitData = action.payload?.data;
        state.isFetchingLimitsGet = false;
      })
      .addCase(limitGet.rejected, (state) => {
        state.isFetchingLimitsGet = false;
      })
      .addCase(userDataSave.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(userDataSave.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(userDataSave.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(multiAccessSave.pending, (state) => {
        state.isFetchingMultiUpdate = true;
      })
      .addCase(multiAccessSave.fulfilled, (state) => {
        state.isFetchingMultiUpdate = false;
      })
      .addCase(multiAccessSave.rejected, (state) => {
        state.isFetchingMultiUpdate = false;
      })
      .addCase(limitSave.pending, (state) => {
        state.isFetchingLimitsUpdate = true;
      })
      .addCase(limitSave.fulfilled, (state) => {
        state.isFetchingLimitsUpdate = false;
      })
      .addCase(limitSave.rejected, (state) => {
        state.isFetchingLimitsUpdate = false;
      })
      .addCase(userDataUpdate.pending, (state) => {
        state.isFetchingUserUpdate = true;
      })
      .addCase(userDataUpdate.fulfilled, (state) => {
        state.isFetchingUserUpdate = false;
      })
      .addCase(userDataUpdate.rejected, (state) => {
        state.isFetchingUserUpdate = false;
      })
      .addCase(multiAcessUpdate.pending, (state) => {
        state.isFetchingMultiUpdate = true;
      })
      .addCase(multiAcessUpdate.fulfilled, (state) => {
        state.isFetchingMultiUpdate = false;
      })
      .addCase(multiAcessUpdate.rejected, (state) => {
        state.isFetchingMultiUpdate = false;
      })
      .addCase(limitUpdate.pending, (state) => {
        state.isFetchingLimitsUpdate = true;
      })
      .addCase(limitUpdate.fulfilled, (state) => {
        state.isFetchingLimitsUpdate = false;
      })
      .addCase(limitUpdate.rejected, (state) => {
        state.isFetchingLimitsUpdate = false;
      })
      .addCase(userDataDelete.pending, (state) => {
        state.isFetchingUserDelete = true;
      })
      .addCase(userDataDelete.fulfilled, (state) => {
        state.isFetchingUserDelete = false;
      })
      .addCase(userDataDelete.rejected, (state) => {
        state.isFetchingUserDelete = false;
      })
      .addCase(multiAcessDelete.pending, (state) => {
        state.isFetchingMultiDelete = true;
      })
      .addCase(multiAcessDelete.fulfilled, (state) => {
        state.isFetchingMultiDelete = false;
      })
      .addCase(multiAcessDelete.rejected, (state) => {
        state.isFetchingMultiDelete = false;
      })
      .addCase(limitDelete.pending, (state) => {
        state.isFetchingLimitsDelete = true;
      })
      .addCase(limitDelete.fulfilled, (state) => {
        if (state.userLimitData.length === 1) state.userLimitData = [];
        state.isFetchingLimitsDelete = false;
      })
      .addCase(limitDelete.rejected, (state) => {
        state.isFetchingLimitsDelete = false;
      })
      .addCase(userListGet.pending, (state) => {
        state.isFetchingUserList = true;
        state.isFetching = true;
      })
      .addCase(userListGet.fulfilled, (state, action) => {
        state.isFetchingUserList = false;
        state.isFetching = false;
        state.userData = action.payload
          ? action.payload.usernameExt
            ? action.payload.usernameExt
            : []
          : [];
      })
      .addCase(userListGet.rejected, (state) => {
        state.isFetchingUserList = false;
        state.isFetching = false;
      })
      .addCase(multiUserListAccess.pending, (state) => {
        state.isFetchingMultiUserAcessGet = true;
        state.isFetching = true;
      })
      .addCase(multiUserListAccess.fulfilled, (state) => {
        state.isFetchingMultiUserAcessGet = false;
        state.isFetching = false;
      })
      .addCase(multiUserListAccess.rejected, (state) => {
        state.isFetchingMultiUserAcessGet = false;
        state.isFetching = false;
      })
      .addCase(updateUserBalance.pending, (state) => {
        state.isFetchingUserBalance = true;
      })
      .addCase(updateUserBalance.fulfilled, (state) => {
        state.isFetchingUserBalance = false;
      })
      .addCase(updateUserBalance.rejected, (state) => {
        state.isFetchingUserBalance = false;
      })
      .addCase(getUsersSessions.pending, (state) => {
        state.isFetchingUserSessions = true;
      })
      .addCase(getUsersSessions.fulfilled, (state) => {
        state.isFetchingUserSessions = false;
      })
      .addCase(getUsersSessions.rejected, (state) => {
        state.isFetchingUserSessions = false;
      })
      .addCase(getUsersConfigurations.pending, (state) => {
        state.isFetchingUserConfigurations = true;
      })
      .addCase(getUsersConfigurations.fulfilled, (state) => {
        state.isFetchingUserConfigurations = false;
      })
      .addCase(getUsersConfigurations.rejected, (state) => {
        state.isFetchingUserConfigurations = false;
      })
      .addCase(getSmscNames.fulfilled, (state, action) => {
        state.smscNames = action.payload;
        state.isFetching = false;
        state.isSuccessSmscNames = true;
        state.isErrorSmscNames = false;
      })
      .addCase(getSmscNames.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscNames.rejected, (state, action) => {
        state.smscNames = [];
        state.isFetching = false;
        state.isErrorSmscNames = true;
        state.isSuccessSmscNames = false;
        state.errorMessageSmscNames = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getWebAccessLog.pending, (state) => {
        state.isFetchingWebAccessLog = true;
      })
      .addCase(getWebAccessLog.rejected, (state) => {
        state.isFetchingWebAccessLog = false;
      })
      .addCase(getWebAccessLog.fulfilled, (state) => {
        state.isFetchingWebAccessLog = false;
      })
      .addCase(getHlrSchedule.pending, (state) => {
        state.isFetchingHlrSchedule = true;
      })
      .addCase(getHlrSchedule.fulfilled, (state) => {
        state.isFetchingHlrSchedule = false;
      })
      .addCase(getHlrSchedule.rejected, (state) => {
        state.isFetchingHlrSchedule = false;
      })
      .addCase(addHlrSchedule.pending, (state) => {
        state.isFetchingAddHlrSchedule = true;
      })
      .addCase(addHlrSchedule.fulfilled, (state) => {
        state.isFetchingAddHlrSchedule = false;
      })
      .addCase(addHlrSchedule.rejected, (state) => {
        state.isFetchingAddHlrSchedule = false;
      })
      .addCase(editHlrSchedule.pending, (state) => {
        state.isFetchingEditHlrSchedule = true;
      })
      .addCase(editHlrSchedule.fulfilled, (state) => {
        state.isFetchingEditHlrSchedule = false;
      })
      .addCase(editHlrSchedule.rejected, (state) => {
        state.isFetchingEditHlrSchedule = false;
      })
      .addCase(deleteHlrSchedule.pending, (state) => {
        state.isFetchingDeleteHlrSchedule = true;
      })
      .addCase(deleteHlrSchedule.fulfilled, (state) => {
        state.isFetchingDeleteHlrSchedule = false;
      })
      .addCase(deleteHlrSchedule.rejected, (state) => {
        state.isFetchingDeleteHlrSchedule = false;
      })
      .addCase(getMobileEmailById.pending, (state) => {
        state.isFetchingMobileEmail = true;
      })
      .addCase(getMobileEmailById.fulfilled, (state) => {
        state.isFetchingMobileEmail = false;
      })
      .addCase(getMobileEmailById.rejected, (state) => {
        state.isFetchingMobileEmail = false;
      });
  },
});

export const { clearStateUser, clearStateSmscNames } = userSlice.actions;

export function clearStateUserAction() {
  return async (dispatch) => {
    dispatch(clearStateUser());
  };
}

export function clearStateNames() {
  return async (dispatch) => {
    dispatch(clearStateSmscNames());
  };
}

export const userSelector = (state) => state.user;
export default userSlice.reducer;
