import { useSelector } from "react-redux";
import { useInactive } from "../../hooks/useInactive";
import { authSelector } from "../Auth/AuthSlice";
import { Outlet } from "react-router-dom";
import { hasRole, hasRoles } from "../../lib/utility";
import RedirectToDashboard from "./RedirectToDashboard";
import RedirectToLogin from "./RedirectToLogin";
import SuspenseLoader from "./SuspenseLoader";

const ProtectedRoute = ({ role }) => {
  const { user } = useSelector(authSelector);

  useInactive(user, +(import.meta.env.VITE_REACT_APP_STALL_TIME || "0"));

  if (role === "public") {
    return (
      <SuspenseLoader>
        <Outlet />
      </SuspenseLoader>
    );
  }

  if (role === "guest") {
    if (!hasRole(user))
      return (
        <SuspenseLoader>
          <Outlet />
        </SuspenseLoader>
      );

    if (hasRole(user)) {
      if (globalThis.isAuthenticated) {
        return <RedirectToDashboard />;
      } else
        return (
          <SuspenseLoader>
            <Outlet />
          </SuspenseLoader>
        );
    }
  }

  if (
    !user.roles &&
    (window.location.href !== "/login" || window.location.href !== "/")
  ) {
    return <RedirectToLogin />;
  }

  if (hasRoles(role, user)) {
    if (globalThis.isAuthenticated) {
      return (
        <SuspenseLoader>
          <Outlet />
        </SuspenseLoader>
      );
    } else {
      return <RedirectToLogin />;
    }
  } else window.history.back();
};
export default ProtectedRoute;
