import { React, useState } from "react";
import Container from "../../Layouts/Container";
import CommonHeader from "../Common/CommonHeader";
import TableWithHeader from "../Common/TableWithHeader";
import CompanyUserTable from "./CompanyUserTable";
import { companySelector, companyUserList } from "./CompanySlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { middleware } from "../../redux/store";
import { isArray } from "../../lib/utility";

const CompanyUser = () => {
  const { isFetchingCompanyUserList } = useSelector(companySelector);
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const [searchVal, setSearchVal] = useState("");
  const [companyUserData, setCompanyUserData] = useState([]);

  const getCompanyUserList = () => {
    dispatch(companyUserList({ udata: companyId })).then((action) =>
      middleware(
        action,
        "Company User List",
        (action) => {
          if (isArray(action.payload.data))
            setCompanyUserData(action.payload.data);
        },
        undefined
      )
    );
  };

  useFetch(() => {
    getCompanyUserList();
  });
  
  const handleSearch = (e) => {
    if (searchVal && searchVal !== "" && e.type !== "blur") {
      getCompanyDataOnce(`?pageName=${searchVal}`);
    } else if (e.type === "blur" && searchVal == "") getCompanyDataOnce();
  };
  return (
    <Container>
      <CommonHeader name={"Company User"}></CommonHeader>
      <TableWithHeader
        name={"Company User List"}
        search={{
          searchVal,
          setSearchVal,
          saveSearchKey: "companyUserList",
          handleSearch,
        }}
      >
        <CompanyUserTable
          isFetching={isFetchingCompanyUserList}
          companyUserData={companyUserData}
        />
      </TableWithHeader>
    </Container>
  );
};

export default CompanyUser;
