import NotificationDarkIcon from "../../../assets/img/notificationDark.svg";
import Modal from "../../../components/Common/Modal";
import "./notification.scss";
import NotificationCard from "./NotificationCard";

const NotificationModal = ({
  show,
  close,
  isLoading,
  notificationData,
  deleteNotificationOnce,
  handleNotificationClick,
}) => {
  return (
    <Modal show={show} close={close} justify="right" centerOnMobile>
      <div className="bg-white h-100">
        <div className="modal-header" style={{ borderRadius: 0 }}>
          <h6 className="modal-type d-flex align-items-center fs-22 gap-2">
            <img src={NotificationDarkIcon} loading="lazy" />
            Notifications
          </h6>
          <svg
            className="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={close}
          >
            <path
              d="M20 4L4 20M4 4L20 20"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className="modal-body p-3"
          style={{
            overflow: "auto",
            height: "calc(100% - 80px)",
          }}
        >
          {Array.isArray(notificationData) && (
            <>
              {notificationData
                .filter(
                  (element) =>
                    element.title !== "Low Balance Alert" &&
                    element.title !== "Add Balance Request Alert"
                )
                .map((noti, index) => (
                  <NotificationCard
                    key={noti.id}
                    notification={noti}
                    delete={deleteNotificationOnce}
                    handleNotificationClick={(id, read) =>
                      handleNotificationClick(id, index, read)
                    }
                  />
                ))}
            </>
          )}

          {!isLoading && notificationData.length === 0 && (
            <p className="py-4 w-100 text-center fs-4 ">
              No Notification Found
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
