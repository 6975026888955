import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

const RedirectToLogin = () => {
  const navigate = useNavigate();

  useFetch(() => {
    navigate("/login");
  });
  return <></>;
};
export default RedirectToLogin;
