import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../redux/axios";

const initialState = {
  // Normal
  bulkSmsData: null,
  isBulkFetching: false,
  isBulkSuccess: false,
  isBulkError: false,
  errorBulkMessage: "",

  // Custom
  customSmsData: null,
  isCustomSmsFetching: false,
  isCustomSmsSuccess: false,
  isCustomSmsError: false,
  errorCustomSmsMessage: "",

  // MMS
  mmsSmsData: null,
  isMmsSmsFetching: false,
  isMmsSmsSuccess: false,
  isMmsSmsError: false,
  errorMmsSmsMessage: "",

  // Coverage testing
  CoverageTestingData: null,
  isCoverageTestingFetching: false,
  isCoverageTestingSuccess: false,
  isCoverageTestingError: false,
  errorCoverageTestingMessage: "",

  // Coverage testing refresh
  CoverageTestingRefreshData: null,
  isCoverageTestingRefreshFetching: false,
  isCoverageTestingRefreshSuccess: false,
  isCoverageTestingRefreshError: false,
  errorCoverageTestingRefreshMessage: "",

  // By Contact
  byContactData: null,
  isbyContactFetching: false,
  isbyContactSuccess: false,
  isbyContactError: false,
  errorbyContactMessage: "",

  // By Group
  byGroupData: null,
  isbyGroupFetching: false,
  isbyGroupSuccess: false,
  isbyGroupError: false,
  errorbyGroupMessage: "",

  // Media Upload
  bulkMediaUploadData: null,
  isBulkMediaUploadFetching: false,
  isBulkMediaUploadSuccess: false,
  isBulkMediaUploadError: false,
  errorBulkMediaUploadMessage: "",
};

export const sendBulkSms = createAsyncThunk(
  "sms/sendbulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendbulk", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendCustomSms = createAsyncThunk(
  "sms/sendbulk/custom",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendbulk/custom", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendMmsSms = createAsyncThunk(
  "sms/sendSmsMms",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendSmsMms", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const messageCoverageTesting = createAsyncThunk(
  "sms/sendMessageWithSmscName",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendMessageWithSmscName", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const sendByContactSms = createAsyncThunk(
  "sms/sendByContacts",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendByContacts", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendByGroupSms = createAsyncThunk(
  "sms/sendSmsGroupData",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/sendSmsGroupData", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const bulkMediaUpload = createAsyncThunk(
  "sms/upload/media",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const response = await axios.post("/sms/upload/media", udata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      let data = response;

      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const getSMSSmscNames = createAsyncThunk(
  "sms/smsc-names",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get("/sms/smsc-names", {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const refreshCoverageResult = createAsyncThunk(
  "sms/sendMessageWithSmscName/refresh",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        `/sms/sendMessageWithSmscName?&refresh=refresh`,
        udata, // body if needed
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const bulkSmsSlice = createSlice({
  name: "sendBulk",
  initialState,
  reducers: {
    clearStateBulkSms: (state, { payload }) => {
      state.bulkSmsData = null;
      state.isBulkFetching = false;
      state.isBulkSuccess = false;
      state.isBulkError = false;
      state.errorBulkMessage = "";
    },
    clearStateCustomSms: (state, { payload }) => {
      state.customSmsData = null;
      state.isCustomSmsFetching = false;
      state.isCustomSmsSuccess = false;
      state.isCustomSmsError = false;
      state.errorCustomSmsMessage = "";
    },
    clearStateMmsSms: (state, { payload }) => {
      state.mmsSmsData = null;
      state.isMmsSmsFetching = false;
      state.isMmsSmsSuccess = false;
      state.isMmsSmsError = false;
      state.errorMmsSmsMessage = "";
    },
    clearStateCoverageTesting: (state, { payload }) => {
      state.CoverageTestingData = null;
      state.isCoverageTestingFetching = false;
      state.isCoverageTestingSuccess = false;
      state.isCoverageTestingError = false;
      state.errorCoverageTestingMessage = "";
    },
    clearStateByContactSms: (state, { payload }) => {
      state.byContactData = null;
      state.isbyContactFetching = false;
      state.isbyContactSuccess = false;
      state.isbyContactError = false;
      state.errorbyContactMessage = "";
    },
    clearStateByGroupSms: (state, { payload }) => {
      state.byGroupData = null;
      state.isbyGroupFetching = false;
      state.isbyGroupSuccess = false;
      state.isbyGroupError = false;
      state.errorbyGroupMessage = "";
    },
    clearStateBulkMediaUpload: (state, { payload }) => {
      state.bulkMediaUploadData = null;
      state.isBulkMediaUploadFetching = false;
      state.isBulkMediaUploadSuccess = false;
      state.isBulkMediaUploadError = false;
      state.errorBulkMediaUploadMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendBulkSms.fulfilled, (state, action) => {
        state.bulkSmsData = action.payload.data;
        state.isBulkFetching = false;
        state.isBulkSuccess = true;
      })
      .addCase(sendBulkSms.pending, (state) => {
        state.isBulkFetching = true;
      })
      .addCase(sendBulkSms.rejected, (state, action) => {
        state.isBulkFetching = false;
        state.isBulkError = true;
        state.errorBulkMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(sendCustomSms.fulfilled, (state, action) => {
        state.customSmsData = action.payload.data;
        state.isCustomSmsFetching = false;
        state.isCustomSmsSuccess = true;
      })
      .addCase(sendCustomSms.pending, (state) => {
        state.isCustomSmsFetching = true;
      })
      .addCase(sendCustomSms.rejected, (state, action) => {
        state.isCustomSmsFetching = false;
        state.isBulkError = true;
        state.errorCustomSmsMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(sendMmsSms.fulfilled, (state, action) => {
        state.mmsSmsData = action.payload.data;
        state.isMmsSmsFetching = false;
        state.isMmsSmsSuccess = true;
      })
      .addCase(sendMmsSms.pending, (state) => {
        state.isMmsSmsFetching = true;
      })
      .addCase(sendMmsSms.rejected, (state, action) => {
        state.isMmsSmsFetching = false;
        state.isMmsSmsError = true;
        state.errorMmsSmsMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(messageCoverageTesting.fulfilled, (state, action) => {
        state.CoverageTestingData = action.payload.data;
        state.isCoverageTestingFetching = false;
        state.isCoverageTestingSuccess = true;
      })
      .addCase(messageCoverageTesting.pending, (state) => {
        state.isCoverageTestingFetching = true;
      })
      .addCase(messageCoverageTesting.rejected, (state, action) => {
        state.isCoverageTestingFetching = false;
        state.isCoverageTestingError = true;
        state.errorCoverageTestingMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(refreshCoverageResult.fulfilled, (state, action) => {
        state.CoverageTestingRefreshData = action.payload.data;
        state.isCoverageTestingRefreshFetching = false;
        state.isCoverageTestingRefreshSuccess = true;
      })
      .addCase(refreshCoverageResult.pending, (state) => {
        state.isCoverageTestingRefreshFetching = true;
      })
      .addCase(refreshCoverageResult.rejected, (state, action) => {
        state.isCoverageTestingRefreshFetching = false;
        state.isCoverageTestingRefreshError = true;
        state.errorCoverageTestingRefreshMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(sendByContactSms.fulfilled, (state, action) => {
        state.byContactData = action.payload.data;
        state.isbyContactFetching = false;
        state.isbyContactSuccess = true;
      })
      .addCase(sendByContactSms.pending, (state) => {
        state.isbyContactFetching = true;
      })
      .addCase(sendByContactSms.rejected, (state, action) => {
        state.isbyContactFetching = false;
        state.isbyContactError = true;
        state.errorbyContactMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(sendByGroupSms.fulfilled, (state, action) => {
        state.byGroupData = action.payload.data;
        state.isbyGroupFetching = false;
        state.isbyGroupSuccess = true;
      })
      .addCase(sendByGroupSms.pending, (state) => {
        state.isbyGroupFetching = true;
      })
      .addCase(sendByGroupSms.rejected, (state, action) => {
        state.isbyGroupFetching = false;
        state.isbyGroupError = true;
        state.errorbyGroupMessage = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const {
  clearStateBulkSms,
  clearStateCustomSms,
  clearStateMmsSms,
  clearStateCoverageTesting,
  clearStateByContactSms,
  clearStateByGroupSms,
  clearStateBulkMediaUpload,
} = bulkSmsSlice.actions;

export function clearNormalFormState() {
  return async (dispatch) => {
    dispatch(clearStateBulkSms());
  };
}

export function clearCustomFormState() {
  return async (dispatch) => {
    dispatch(clearStateCustomSms());
  };
}

export function clearMmsFormState() {
  return async (dispatch) => {
    dispatch(clearStateMmsSms());
  };
}
export function clearCoverageTestingFormState() {
  return async (dispatch) => {
    dispatch(clearStateCoverageTesting());
  };
}

export function clearByContactFormState() {
  return async (dispatch) => {
    dispatch(clearStateByContactSms());
  };
}

export function clearByGroupFormState() {
  return async (dispatch) => {
    dispatch(clearStateByGroupSms());
  };
}

export function clearBulkMediaUploadState() {
  return async (dispatch) => {
    dispatch(clearStateBulkMediaUpload());
  };
}

export const bulkSmsSelector = (state) => state.sendBulk;

export default bulkSmsSlice.reducer;
