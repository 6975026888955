import axios from "axios";
import JSONbig from "json-bigint";

const instance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  transformResponse: [
    (data) => {
      try {
        return JSONbig.parse(data);
      } catch (error) {
        return data;
      }
    },
  ],
});
export default instance;
