import { createSlice } from "@reduxjs/toolkit";
import { getItem, storeItem } from "../../../lib/utility";

const saveToLocalStorage = (downloads) => {
  localStorage.setItem("downloads", JSON.stringify(downloads));
};

const loadFromLocalStorage = () => {
  const downloads = localStorage.getItem("downloads");
  return downloads
    ? JSON.parse(downloads).map((dwn) => ({ ...dwn, downloading: false }))
    : [];
};

const initialState = {
  downloads: (getItem("downloads", true) || []).filter(
    (dwn) => !dwn.downloading
  ),
};

const downloadLogSlice = createSlice({
  name: "downloadProgress",
  initialState,
  reducers: {
    addDownloadToLog: (state, action) => {
      state.downloads.unshift({
        downloading: true,
        startTime: Date.now(),
        totalTime: action.payload.totalTime,
        endTime: Date.now() + action.payload.totalTime,
        name: action.payload.name,
        id: action.payload.id,
        message: "downloading..",
        type: action.payload.type,
        url: action.payload.url,
        size: 0,
      });
      storeItem("downloads", state.downloads);
    },
    completedDownload: (state, action) => {
      const download = state.downloads.find(
        (file) => file.id === action.payload.id
      );
      if (download) {
        download.downloading = false;
        download.message = "Download successfull";
      }
      saveToLocalStorage(state.downloads);
    },
    deleteDownloadLog: (state, action) => {
      state.downloads = state.downloads.filter(
        (file) => file.id !== action.payload.id
      );
      storeItem("downloads", state.downloads);
    },
    addSuccessDownloadToLog: (state, action) => {
      state.downloads.unshift({
        downloading: false,
        startTime: Date.now(),
        totalTime: action.payload.totalTime,
        endTime: Date.now() + action.payload.totalTime,
        name: action.payload.name,
        id: action.payload.id,
        message: "Download successfull",
        type: action.payload.type,
        url: action.payload.url,
        size: action.payload.size,
      });
      saveToLocalStorage(state.downloads);
    },
    handleDownloadSizeProgress: (state, action) => {
      const download = state.downloads.find(
        (file) => file.id === action.payload.id
      );
      if (download) {
        download.size = action.payload.size;
      }
      saveToLocalStorage(state.downloads);
    },
  },
});

export const downloadProgressSelector = (state) => state.downloadProgress;

export const {
  addDownloadToLog,
  completedDownload,
  deleteDownloadLog,
  addSuccessDownloadToLog,
  handleDownloadSizeProgress,
} = downloadLogSlice.actions;
export default downloadLogSlice.reducer;
