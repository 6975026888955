import { useEffect } from "react";

const useNotificationThrottle = (
  changeDelay,
  stallTime,
  getNotificationDataOnce
) => {
  const getNotificationThrottleData = (changeTime = 5, stallTime = 30) => {
    if (!globalThis) return console.error("Global This is not supported");
    
    if (globalThis.notificationTime) {
      let elapsedTime = Date.now() - globalThis.notificationTime;
      if (elapsedTime < changeTime * 1000) return;

      if (globalThis.stallTime) {
        clearInterval(globalThis.stallTime);
      }
    }

    getNotificationDataOnce();
    let stallInterval = setInterval(() => {
      getNotificationDataOnce();
    }, stallTime * 1000);

    globalThis.notificationTime = Date.now();
    globalThis.stallTime = stallInterval;

    return true;
  };

  useEffect(() => {
    getNotificationThrottleData(changeDelay, stallTime);
  }, []);
};
export default useNotificationThrottle;
