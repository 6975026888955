import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const url = new URLSearchParams(window.location.search);

const initialState = {
  quickSMSOverlay: url.get("quickSms") ?? false,
  bulkSMSOverlay: url.get("bulkSms") ?? false,

  userMessage: [],
  isSuccessUserMessage: false,
  isErrorUserMessage: false,
  isFetching: false,
  errorUserMessage: "",

  getAllTemp: [],
  getRecentTemp: [],
  isFetching: false,
  isError: false,
  errorMessage: "",

  senderIdData: [],
  isSuccessSenderId: false,
  isErrorSenderId: false,
  errorSenderIdMessage: "",

  exculdeNumberData: [],
  isSuccessExculdeNumber: false,
  isErrorExculdeNumber: false,
  errorExculdeNumberMessage: "",

  // bulk
  managementLogData: [],
  isManagementLogFetching: false,
  isSuccessmanagementLog: false,
  isErrorManagementLog: false,

  //sender
  manageSenderLogs: [],
  isFetchingManageSenderLog: false,
  isSuccessManageSenderLog: false,
  isErrormanageSenderLog: false,
};

export const getAllMessageForTable = createAsyncThunk(
  "reports/sms/report",
  async ({ udata, search, page, size }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const urlParams = new URLSearchParams();

      urlParams.append("page", page || 0);
      urlParams.append("size", size || 20);
      if (search) urlParams.append("searchParameter", search);

      const url = `/reports/sms/report?${urlParams.toString()}`;
      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getAllTemplates = createAsyncThunk(
  "templates/get-all-templates",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/templates/get-all-templates", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getRecentUseTemplates = createAsyncThunk(
  "templates/recent-use-template",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        "/templates/recent-use-template",
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const smsIdentify = createAsyncThunk(
  "sms/identify",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        "/sms/identify",
        { message: udata },
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const smsSenderId = createAsyncThunk(
  "sms/sender/id",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get("/sms/sender/id", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const smsExcludeNo = createAsyncThunk(
  "sms/exclud/number",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get("/sms/exclud/number", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const sendSmsConfirmation = createAsyncThunk(
  "sms/single/confirmation",
  async (udata, thunkAPI) => {
    try {
      let urlParams = new URLSearchParams();
      urlParams.append("smsparts", udata.smsParts);
      urlParams.append("allowDuplicates", udata.allowDuplicates);
      udata.numbers &&
        udata.numbers !== "" &&
        urlParams.append("numbers", udata.numbers);
      udata.exclude &&
        udata.exclude !== "" &&
        urlParams.append("exclude", udata.exclude);

      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/sms/preSubmitCalcul?${urlParams.toString()}`,
        udata.formData || new FormData(),
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const fileProcessingPercentage = createAsyncThunk(
  "sms/bulk/percentage",
  async (_, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/sms/progressPercent`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getManageApprovedLogs = createAsyncThunk(
  "sms/list-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/sms/list/bulk-mgmt?method=` + udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateManageApprovedLogs = createAsyncThunk(
  "sms/update-bulk",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/sms/update/bulk-mgmt`, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getManageSenderLogs = createAsyncThunk(
  "sms/list-sender",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/sms/sender-mgmt?purpose=` + udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateManageSenderLogs = createAsyncThunk(
  "sms/update-sender",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/sms/update-sender-mgmt`,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addManageSender = createAsyncThunk(
  "sms/add-sender",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/sms/save-sender-mgmt`,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addManageSenderLogs = createAsyncThunk(
  "sms/sender-logs",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(`/sms/log-sender-mgmt`, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getHomeLocationRegister = createAsyncThunk(
  "msg/hlr-lookup",
  async ({ udata, reqType }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        `/sms/lookup?reqType=` + reqType,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const trackMessageStatus = createAsyncThunk(
  "reports/track-result-report",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const username = auth.user.username;
      const { data, status } = await axios.post(
        `reports/track-result-report?username=${username}&lang=en`,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    toggleQuickSMSOverlay: (state, { payload }) => {
      state.quickSMSOverlay = payload ?? !state.quickSMSOverlay;
    },
    toggleBulkSMSOverlay: (state, { payload }) => {
      state.bulkSMSOverlay = payload ?? !state.bulkSMSOverlay;
    },
    clearStateMessageTable: (state, { payload }) => {
      state.isFetching = false;
      state.userMessage = [];
      state.isSuccessUserMessage = false;
      state.isErrorUserMessage = false;
      state.errorUserMessage = "";
    },
    clearStateMessage: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearCommonState: (state, { payload }) => {
      state.getAllTemp = [];
      state.getRecentTemp = [];
    },
    clearStateSenderId: (state, { payload }) => {
      state.senderIdData = [];
      state.isSuccessSenderId = false;
      state.isErrorSenderId = false;
      state.errorSenderIdMessage = "";
    },
    clearStateExculdeNumber: (state, { payload }) => {
      state.exculdeNumberData = [];
      state.isSuccessExculdeNumber = false;
      state.isErrorExculdeNumber = false;
      state.errorExculdeNumberMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllMessageForTable.fulfilled, (state, action) => {
        state.userMessage = action.payload?.data || [];
        state.isFetching = false;
        state.isSuccessUserMessage = true;
      })
      .addCase(getAllMessageForTable.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllMessageForTable.rejected, (state, action) => {
        state.userMessage = [];
        state.isFetching = false;
        state.isErrorUserMessage = true;
        state.errorUserMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.getAllTemp = action.payload?.data;
        state.isFetching = false;
      })
      .addCase(getAllTemplates.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllTemplates.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getRecentUseTemplates.fulfilled, (state, action) => {
        state.getRecentTemp = action.payload.data;
        state.isFetching = false;
      })
      .addCase(getRecentUseTemplates.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getRecentUseTemplates.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(smsSenderId.fulfilled, (state, action) => {
        state.senderIdData = action.payload?.data;
        state.isSuccessSenderId = true;
      })
      .addCase(smsSenderId.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(smsSenderId.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorSenderId = true;
        state.errorSenderIdMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      .addCase(smsExcludeNo.fulfilled, (state, action) => {
        state.exculdeNumberData = action.payload?.data;
        state.isSuccessExculdeNumber = true;
      })
      .addCase(smsExcludeNo.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(smsExcludeNo.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorExculdeNumber = true;
        state.errorExculdeNumberMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getManageApprovedLogs.fulfilled, (state, action) => {
        state.managementLogData = action.payload.data;
        state.isManagementLogFetching = false;
        state.isSuccessmanagementLog = true;
      })
      .addCase(getManageApprovedLogs.pending, (state) => {
        state.isManagementLogFetching = true;
      })
      .addCase(getManageApprovedLogs.rejected, (state, action) => {
        state.managementLogData = [];
        state.isManagementLogFetching = false;
        state.isSuccessmanagementLog = true;
        state.isErrorManagementLog = action.payload
          ? action.payload
          : "Internal error";
      })
      .addCase(updateManageApprovedLogs.fulfilled, (state, action) => {
        state.isManagementLogFetching = false;
      })
      .addCase(updateManageApprovedLogs.pending, (state) => {
        state.isManagementLogFetching = true;
      })
      .addCase(updateManageApprovedLogs.rejected, (state, action) => {
        state.isManagementLogFetching = false;
      })

      .addCase(getManageSenderLogs.pending, (state) => {
        state.isFetchingManageSenderLog = true;
        state.isSuccessManageSenderLog = false;
        state.isErrormanageSenderLog = false;
      })
      .addCase(getManageSenderLogs.fulfilled, (state, action) => {
        state.isFetchingManageSenderLog = false;
        state.manageSenderLogs = action.payload.data?.usernames || [];
        state.isSuccessManageSenderLog = true;
        state.isErrormanageSenderLog = false;
      })
      .addCase(getManageSenderLogs.rejected, (state, action) => {
        state.isFetchingManageSenderLog = false;
        state.manageSenderLogs = [];
        state.isSuccessManageSenderLog = false;
        state.isErrormanageSenderLog = true;
        // state.errorManageSenderLogMessage = action.payload ? action.payload.message : "Internal error";
      })

      .addCase(updateManageSenderLogs.pending, (state) => {
        state.isFetchingManageSenderLog = true;
        state.isSuccessManageSenderLog = false;
        state.isErrormanageSenderLog = false;
      })
      .addCase(updateManageSenderLogs.fulfilled, (state) => {
        state.isFetchingManageSenderLog = false;
      })
      .addCase(updateManageSenderLogs.rejected, (state) => {
        state.isFetchingManageSenderLog = false;
      })

      .addCase(addManageSender.pending, (state) => {
        state.isFetchingManageSenderLog = true;
      })
      .addCase(addManageSender.fulfilled, (state) => {
        state.isFetchingManageSenderLog = false;
      })
      .addCase(addManageSender.rejected, (state) => {
        state.isFetchingManageSenderLog = false;
      })
      .addCase(addManageSenderLogs.fulfilled, (state, action) => {
        state.manageSenderLogs = action.payload.data;
        state.isFetchingManageSenderLog = false;
        state.isSuccessManageSenderLog = true;
        state.isErrormanageSenderLog = false;
      })
      .addCase(addManageSenderLogs.pending, (state) => {
        state.isFetchingManageSenderLog = true;
        state.isSuccessManageSenderLog = false;
        state.isErrormanageSenderLog = false;
      })
      .addCase(addManageSenderLogs.rejected, (state, action) => {
        state.isFetchingManageSenderLog = false;
        state.manageSenderLogs = [];
        state.isSuccessManageSenderLog = false;
        state.isErrormanageSenderLog = true;
      });
  },
});

export const {
  clearStateMessageTable,
  clearStateMessage,
  clearCommonState,
  clearStateSenderId,
  clearStateExculdeNumber,
  toggleQuickSMSOverlay,
  toggleBulkSMSOverlay,
} = messageSlice.actions;

export function clearStateTable() {
  return async (dispatch) => {
    dispatch(clearStateMessageTable());
  };
}

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateMessage());
  };
}

export function clearCommonApiState() {
  return async (dispatch) => {
    dispatch(clearCommonState());
  };
}

export function clearSenderIdState() {
  return async (dispatch) => {
    dispatch(clearStateSenderId());
  };
}

export function clearExculdeNumberState() {
  return async (dispatch) => {
    dispatch(clearStateExculdeNumber());
  };
}

export const messageSelector = (state) => state.message;

export default messageSlice.reducer;
