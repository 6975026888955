import "nprogress/nprogress.css";
import { lazy } from "react";

const lazyLoader = (importFunc) =>
  lazy(() =>
    importFunc().catch((err) => {
      console.error("Failed to load module:", err);
      // window.location.reload(true);
      return "div";
    })
  );

export default lazyLoader;
