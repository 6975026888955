import React, { useState } from "react";
import { useSelector } from "react-redux";
import calenderIcon from "../../../assets/img/calenderIcon.svg";
import {
  addTimeInDates,
  convertGmtToOffest,
  getBrowserGMTOffset,
  hasRoles,
  kindOf,
  showToast,
} from "../../../lib/utility";
import { getDatesFromDay } from "../../../lib/utils";
import { getLast10thDate } from "../../../Wrapper/CommonFun";
import timeOptions, { countries } from "../../../Wrapper/GmtTimeData";
import { authSelector } from "../../Auth/AuthSlice";
import CloseButton from "../../Common/CloseButton";
import FormEnter from "../../Common/FormEnter";
import Modal from "../../Common/Modal";
import SaveFilter from "../../Common/SaveFilter";
import UserFilter from "../../Common/UserFilter";
import Select from "../../Common/Select";

export default function SubmissionFilter({
  modal,
  closeModal,
  onApplyFilter,
  onResetFilter,
}) {
  let { startDate, endDate } = getLast10thDate();
  const initialValues = {
    gmtOffset: getBrowserGMTOffset(),
    startDate: endDate.split(" ")[0],
    endDate: startDate.split(" ")[0],
    time: null,
    month: "",
    date: "",
    senderId: "",
    status: "%",
    country: "%",
  };

  const [filterData, setFilterData] = useState(initialValues);
  const [daySelector, setDaySelector] = useState("10");

  const { user } = useSelector(authSelector);
  const isSupers = hasRoles("admin,superadmin,system,seller,manager", user);

  const [selectUsers, setSelectUsers] = useState({
    allowed: isSupers,
    userIds: "",
    next: false,
  });

  const getFilterObj = () => {
    return addTimeInDates(
      {
        startDate: filterData.startDate,
        endDate: filterData.endDate,
        status: filterData.status,
        time: filterData.time,
        date: filterData.date,
        country: filterData.country,
        month: filterData.month,
        ...(isSupers ? { clientId: selectUsers.userIds } : {}),
        ...(filterData.senderId !== ""
          ? { senderId: filterData.senderId }
          : {}),
      },
      convertGmtToOffest(filterData.gmtOffset)
    );
  };
  const handleApplyClick = () => {
    if (filterData.startDate !== "" && filterData.endDate !== "") {
      kindOf(onApplyFilter, "function") && onApplyFilter(getFilterObj());
      modalClose();
    } else {
      showToast(
        "Please select " +
          (!filterData.time
            ? "Start and End date"
            : filterData.time === "Monthly"
            ? "Month"
            : "Da"),
        "warning"
      );
    }
  };
  const handleResetClick = () => {
    setFilterData(initialValues);
    kindOf(onResetFilter, "function") && onResetFilter();
    modalClose();
  };
  const handleFilterSubmit = () => {
    setSelectUsers((prev) => ({ ...prev, next: false }));
  };
  const handleSaveFilter = () => {
    handleApplyClick();
  };
  const handleTimeChange = (e) => {
    let time = e.target.value;
    setFilterData((prev) => ({
      ...prev,
      time: time === "Custom" ? null : time,
    }));
  };
  const handleFilterDataChange = (e) => {
    const { value, id } = e.target;

    setFilterData((prev) => ({
      ...prev,
      ...(id === "campaign" && value !== ""
        ? { checkA: "campaign" }
        : { checkA: "multiple" }),
      ...(id === "checkA" ? { campaign: "" } : {}),
      [id]: value,
      ...(filterData.campaign === "" &&
      (id === "checkA" || id === "campaign") &&
      value === ""
        ? { checkA: "multiple" }
        : {}),
    }));
  };
  const modalClose = () => {
    setSelectUsers({
      allowed: isSupers,
      userIds: "",
      next: false,
    });
    closeModal();
  };
  const handleDayChange = (e) => {
    let value = e.target.value;

    let { startDate, endDate } = getDatesFromDay(value);
    setFilterData((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
    setDaySelector(value);
  };

  return (
    <>
      <UserFilter
        modalClose={modalClose}
        handleResetClick={handleResetClick}
        onNextClick={(value) => setSelectUsers(value)}
        onApplyFilter={onApplyFilter}
        userModalParams={{}}
      />
      {(!selectUsers.allowed ||
        (selectUsers.allowed &&
          selectUsers.userIds !== "" &&
          selectUsers.next)) && (
        <Modal show={modal} close={modalClose} justify="center">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center fs-22">
              <img src={calenderIcon} loading="lazy" />
              Filter
            </h6>
            <div className="d-flex gap-2">
              <div className="dropdown">
                <select
                  className="form-select"
                  value={daySelector}
                  onChange={handleDayChange}
                >
                  <option value="0">Today</option>
                  <option value="1">Yesterday</option>
                  <option value="3">Last 3 Days</option>
                  <option value="7">Last 7 Days</option>
                  <option value="10">Last 10 Days</option>
                  <option value="15">Last 15 Days</option>
                  <option value="30">Last 30 Days</option>
                </select>
              </div>
              <CloseButton theme={"dark"} onClick={modalClose} />
            </div>
          </div>
          <FormEnter onSubmit={handleFilterSubmit}>
            <div className="modal-body py-2 px-3 bg-white">
              <div className="row filter g-3">
                <div className="col-md-12">
                  <label className="form-label">GMT</label>
                  <select
                    className="form-select"
                    id="gmtOffset"
                    value={filterData.gmtOffset}
                    onChange={handleFilterDataChange}
                  >
                    {timeOptions.map((gmt) => (
                      <option value={gmt.value} key={gmt.value}>
                        {gmt.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Time</label>
                  <select
                    className="form-select text-capitalize"
                    onChange={handleTimeChange}
                    value={filterData.time || "Custom"}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Country</label>
                  <Select
                    options={[{ code: "%", name: "ALL" }, ...countries].map(
                      (country) => ({
                        id: country.code,
                        label: country.name,
                      })
                    )}
                    onChange={(value) =>
                      handleFilterDataChange({
                        target: { id: "country", value },
                      })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Sender</label>
                  <input
                    type="text"
                    className="form-control"
                    value={filterData.senderId}
                    id="senderId"
                    onChange={handleFilterDataChange}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="form-label">Status</label>
                  <select
                    className="form-select text-capitalize"
                    value={filterData.status}
                    id="status"
                    onChange={handleFilterDataChange}
                  >
                    <option value="%">All</option>
                    <option value="Q">Queued</option>
                    <option value="C">Processing</option>
                    <option value="F">NonResponding</option>
                  </select>
                </div>
                {filterData.time === "Daily" && (
                  <div className="col-12 col-md-6">
                    <label className="form-label">Date</label>
                    <input
                      type="date"
                      className="form-control text-uppercase"
                      id="date"
                      value={filterData.date}
                      onChange={handleFilterDataChange}
                      required
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                )}
                {filterData.time === "Monthly" && (
                  <div className="col-12 col-md-6">
                    <label className="form-label">Month</label>
                    <input
                      type="month"
                      className="form-control text-capitalize"
                      id="month"
                      value={filterData.month}
                      onChange={handleFilterDataChange}
                      required
                      max={new Date()
                        .toISOString()
                        .split("T")[0]
                        .split("-")
                        .slice(0, 2)
                        .join("-")}
                    />
                  </div>
                )}
                {filterData.time === null && (
                  <>
                    <div className="col-12 col-md-6">
                      <label className="form-label">From date</label>
                      <input
                        type="date"
                        className="form-control text-uppercase"
                        id="startDate"
                        value={filterData.startDate}
                        onChange={handleFilterDataChange}
                        required
                        max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="form-label">To date</label>
                      <input
                        type="date"
                        className="form-control text-uppercase"
                        id="endDate"
                        value={filterData.endDate}
                        onChange={handleFilterDataChange}
                        required
                        max={new Date().toISOString().split("T")[0]}
                        {...(filterData.startDate
                          ? {
                              min: new Date(filterData.startDate)
                                .toISOString()
                                .split("T")[0],
                            }
                          : {})}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer d-block bg-white">
              <div className="row g-3">
                <div className="col-6 mt-0">
                  <button
                    type="button"
                    className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                    onClick={handleResetClick}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 mt-0">
                  <button
                    type="submit"
                    className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                    onClick={handleFilterSubmit}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </FormEnter>
        </Modal>
      )}
      {selectUsers.userIds !== "" && !selectUsers.next && (
        <SaveFilter
          handleCancelClick={modalClose}
          handleSaveFilter={handleSaveFilter}
          filterData={getFilterObj()}
          filterName={"SUBMISSION"}
        />
      )}
    </>
  );
}
