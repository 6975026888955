type GMTTime = {
  label: string;
  value: string;
};
type CountriesCode = {
  code: number;
  name: string;
};
type Currency = {
  label: string;
  value: string;
};

const timeOptions: GMTTime[] = [
  {
    label: "International Date Line West(GMT-12:00)",
    value: "GMT-12:00#1",
  },
  {
    label: "Midway Island, Samoa(GMT-11:00)",
    value: "GMT-11:00#1",
  },
  {
    label: "Hawaii(GMT-10:00)",
    value: "GMT-10:00#1",
  },
  {
    label: "Alaska(GMT-09:00)",
    value: "GMT-09:00#1",
  },
  {
    label: "Pacific Time(US &amp;amp; Canada)(GMT-08:00)",
    value: "GMT-08:00#1",
  },
  {
    label: "Tijuana Baja California(GMT-08:00)",
    value: "GMT-08:00#2",
  },
  {
    label: "Arizona(GMT-07:00)",
    value: "GMT-07:00#1",
  },
  {
    label: "Chihuahua, La paz, Mazatlan(GMT-07:00)",
    value: "GMT-07:00#2",
  },
  {
    label: "Mountain Time(US &amp;amp; Canada)(GMT-07:00)",
    value: "GMT-07:00#3",
  },
  {
    label: "Central America(GMT-06:00)",
    value: "GMT-06:00#1",
  },
  {
    label: "Central Time(US &amp;amp; Canada)(GMT-06:00)",
    value: "GMT-06:00#2",
  },
  {
    label: "Guadalajara, Mexico City, Monterrey(GMT-06:00)",
    value: "GMT-06:00#3",
  },
  {
    label: "Saskatchewan(GMT-06:00)",
    value: "GMT-06:00#4",
  },
  {
    label: "Bogota, Lima, Quito, Rio Branco(GMT-05:00)",
    value: "GMT-05:00#1",
  },
  {
    label: "Eastern Time(US &amp;amp; Canada)(GMT-05:00)",
    value: "GMT-05:00#2",
  },
  {
    label: "Indiana(East)(GMT-05:00)",
    value: "GMT-05:00#3",
  },
  {
    label: "Caracas(GMT-04:30)",
    value: "GMT-04:30#1",
  },
  {
    label: "Atlentic Time(Canada)(GMT-04:00)",
    value: "GMT-04:00#2",
  },
  {
    label: "La Paz(GMT-04:00)",
    value: "GMT-04:00#3",
  },
  {
    label: "Manaus(GMT-04:00)",
    value: "GMT-04:00#4",
  },
  {
    label: "Santiago(GMT-04:00)",
    value: "GMT-04:00#5",
  },
  {
    label: "Newfoundland(GMT-03:30)",
    value: "GMT-03:30#1",
  },
  {
    label: "Brasilia(GMT-03:00)",
    value: "GMT-03:00#2",
  },
  {
    label: "Buenos Aires, Georgetown(GMT-03:00)",
    value: "GMT-03:00#3",
  },
  {
    label: "Greenland(GMT-03:00)",
    value: "GMT-03:00#4",
  },
  {
    label: "Montevideo(GMT-03:00)",
    value: "GMT-03:00#5",
  },
  {
    label: "mid-Atlantic(GMT-02:00)",
    value: "GMT-02:00#1",
  },
  {
    label: "Azores(GMT-01:00)",
    value: "GMT-01:00#1",
  },
  {
    label: "Cape Verde Is(GMT-01:00)",
    value: "GMT-01:00#2",
  },
  {
    label: "Casablanca, Monrovia, Reykjavik(GMT)",
    value: "GMT#1",
  },
  {
    label: "Greenwich Mean Time:Bublin,Edinburgh,Lisban,London(GMT)",
    value: "GMT#2",
  },
  {
    label: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna(GMT+01:00)",
    value: "GMT+01:00#1",
  },
  {
    label: "Belgrade, Bratislava, Budapest, Ljubljana, Prague(GMT+01:00)",
    value: "GMT+01:00#2",
  },
  {
    label: "Brassels, Copenhegen, Mardrid, Paris(GMT+01:00)",
    value: "GMT+01:00#3",
  },
  {
    label: "Sarajevo, Skopje, Warsaw,Zagreb(GMT+01:00)",
    value: "GMT+01:00#4",
  },
  {
    label: "West Central Africa(GMT+01:00)",
    value: "GMT+01:00#5",
  },
  {
    label: "Amman(GMT+02:00)",
    value: "GMT+02:00#1",
  },
  {
    label: "Athens, Bucharest, Istanbul(GMT+02:00)",
    value: "GMT+02:00#2",
  },
  {
    label: "Beirut(GMT+02:00)",
    value: "GMT+02:00#3",
  },
  {
    label: "Cairo(GMT+02:00)",
    value: "GMT+02:00#4",
  },
  {
    label: "Harare, Pretoria(GMT+02:00)",
    value: "GMT+02:00#5",
  },
  {
    label: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius(GMT+02:00)",
    value: "GMT+02:00#6",
  },
  {
    label: "Jerusalem(GMT+02:00)",
    value: "GMT+02:00#7",
  },
  {
    label: "Minsk(GMT+02:00)",
    value: "GMT+02:00#8",
  },
  {
    label: "Windhoek(GMT+02:00)",
    value: "GMT+02:00#9",
  },
  {
    label: "Baghdad(GMT+03:00)",
    value: "GMT+03:00#1",
  },
  {
    label: "Kuwait, Riyadh(GMT+03:00)",
    value: "GMT+03:00#2",
  },
  {
    label: "Moscow, St. Petersburg, Volgograd(GMT+03:00)",
    value: "GMT+03:00#3",
  },
  {
    label: "Nairobi(GMT+03:00)",
    value: "GMT+03:00#4",
  },
  {
    label: "Tbilisi(GMT+03:00)",
    value: "GMT+03:00#5",
  },
  {
    label: "Tehran(GMT+03:30)",
    value: "GMT+03:30#6",
  },
  {
    label: "Abu Dhabi, Muscat(GMT+04:00)",
    value: "GMT+04:00#1",
  },
  {
    label: "Baku(GMT+04:00)",
    value: "GMT+04:00#2",
  },
  {
    label: "Yerevan(GMT+04:00)",
    value: "GMT+04:00#3",
  },
  {
    label: "Kabul(GMT+04:30)",
    value: "GMT+04:30#4",
  },
  {
    label: "Ekaterinburg(GMT+05:00)",
    value: "GMT+05:00#1",
  },
  {
    label: "Islamabad, Karachi, Tashkent(GMT+05:00)",
    value: "GMT+05:00#2",
  },
  {
    label: "Chennai, Kolkata, Mumbai, New Delhi(GMT+05:30)",
    value: "GMT+05:30#1",
  },
  {
    label: "Sri Jayawardenepura(GMT+05:30)",
    value: "GMT+05:30#2",
  },
  {
    label: "Kathmandu(GMT+05:45)",
    value: "GMT+05:45#1",
  },
  {
    label: "Almaty, Novosibirsk(GMT+06:00)",
    value: "GMT+06:00#1",
  },
  {
    label: "Astana, Dhaka(GMT+06:00)",
    value: "GMT+06:00#2",
  },
  {
    label: "Yangon(Ranfoon)(GMT+06:30)",
    value: "GMT+06:30#1",
  },
  {
    label: "Bangkok, Hanoi, Jakarta(GMT+07:00)",
    value: "GMT+07:00#1",
  },
  {
    label: "Krasnoyarsk(GMT+07:00)",
    value: "GMT+07:00#2",
  },
  {
    label: "Beijing, Chongqing, Hong Kong, Urumqi(GMT+08:00)",
    value: "GMT+08:00#1",
  },
  {
    label: "Irkutsk, Ulaan, Bataar(GMT+08:00)",
    value: "GMT+08:00#2",
  },
  {
    label: "Kuala Lumpur, Singapore(GMT+08:00)",
    value: "GMT+08:00#3",
  },
  {
    label: "Perth(GMT+08:00)",
    value: "GMT+08:00#4",
  },
  {
    label: "Taipei(GMT+08:00)",
    value: "GMT+08:00#5",
  },
  {
    label: "Osaka, Sapporo, Tokyo(GMT+09:00)",
    value: "GMT+09:00#1",
  },
  {
    label: "Seoul(GMT+09:00)",
    value: "GMT+09:00#2",
  },
  {
    label: "Yakutsk(GMT+09:00)",
    value: "GMT+09:00#3",
  },
  {
    label: "Adelaide(GMT+09:30)",
    value: "GMT+09:30#1",
  },
  {
    label: "Darwin(GMT+09:30)",
    value: "GMT+09:30#2",
  },
  {
    label: "Brisbane(GMT+10:00)",
    value: "GMT+10:00#1",
  },
  {
    label: "Canberra, Melbourne, Sydney(GMT+10:00)",
    value: "GMT+10:00#2",
  },
  {
    label: "Guam, Port Moresby(GMT+10:00)",
    value: "GMT+10:00#3",
  },
  {
    label: "Hobert(GMT+10:00)",
    value: "GMT+10:00#4",
  },
  {
    label: "Viadivostok(GMT+10:00)",
    value: "GMT+10:00#5",
  },
  {
    label: "Magadan, Soloman Is., New Caledonia(GMT+11:00)",
    value: "GMT+11:00#1",
  },
  {
    label: "Auckland, Wellington(GMT+12:00)",
    value: "GMT+12:00#1",
  },
  {
    label: "Fiji, Kamchatka, Marshall Is.(GMT+12:00)",
    value: "GMT+12:00#2",
  },
  {
    label: "Nuku'alofa(GMT+13:00)",
    value: "GMT+13:00#1",
  },
];
export const countries: CountriesCode[] = [
  { code: 93, name: "Afghanistan" },
  { code: 355, name: "Albania" },
  { code: 213, name: "Algeria" },
  { code: 376, name: "Andorra" },
  { code: 244, name: "Angola" },
  { code: 264, name: "Anguilla" },
  { code: 6721, name: "Antarctica" },
  { code: 268, name: "Antigua and Barbuda" },
  { code: 54, name: "Argentina" },
  { code: 374, name: "Armenia" },
  { code: 297, name: "Aruba" },
  { code: 247, name: "Ascension" },
  { code: 61, name: "Australia" },
  { code: 43, name: "Austria" },
  { code: 994, name: "Azerbaijan" },
  { code: 242, name: "Bahamas" },
  { code: 973, name: "Bahrain" },
  { code: 880, name: "Bangladesh" },
  { code: 246, name: "Barbados" },
  { code: 375, name: "Belarus" },
  { code: 32, name: "Belgium" },
  { code: 501, name: "Belize" },
  { code: 229, name: "Benin" },
  { code: 441, name: "Bermuda" },
  { code: 975, name: "Bhutan" },
  { code: 591, name: "Bolivia" },
  { code: 387, name: "Bosnia and Herzegovina" },
  { code: 267, name: "Botswana" },
  { code: 55, name: "Brazil" },
  { code: 246, name: "BritishIndianOceanTerritory" },
  { code: 284, name: "British Virgin Islands" },
  { code: 673, name: "Brunei" },
  { code: 359, name: "Bulgaria" },
  { code: 226, name: "Burkina Faso" },
  { code: 257, name: "Burundi" },
  { code: 855, name: "Cambodia" },
  { code: 237, name: "Cameroon" },
  { code: 1, name: "Canada" },
  { code: 238, name: "Cape Verde" },
  { code: 345, name: "Cayman Islands" },
  { code: 236, name: "Central African Republic" },
  { code: 235, name: "Chad" },
  { code: 56, name: "Chile" },
  { code: 86, name: "China" },
  { code: 57, name: "Colombia" },
  { code: 269, name: "Comoros" },
  { code: 243, name: "Congo Democratic Republic" },
  { code: 242, name: "Congo Republic" },
  { code: 682, name: "Cook Islands" },
  { code: 506, name: "Costa Rica" },
  { code: 225, name: "Cote d'Ivoire" },
  { code: 385, name: "Croatia" },
  { code: 53, name: "Cuba" },
  { code: 357, name: "Cyprus" },
  { code: 420, name: "Czech Republic" },
  { code: 45, name: "Denmark" },
  { code: 253, name: "Djibouti" },
  { code: 767, name: "Dominica" },
  { code: 1809, name: "Dominican Republic" },
  { code: 670, name: "East Timor" },
  { code: 593, name: "Ecuador" },
  { code: 20, name: "Egypt" },
  { code: 503, name: "El Salvador" },
  { code: 240, name: "Equatorial Guinea" },
  { code: 291, name: "Eritrea" },
  { code: 372, name: "Estonia" },
  { code: 251, name: "Ethiopia" },
  { code: 500, name: "Falkland Islands" },
  { code: 298, name: "Faroe Islands" },
  { code: 679, name: "Fiji" },
  { code: 358, name: "Finland" },
  { code: 33, name: "France" },
  { code: 594, name: "French Guiana" },
  { code: 689, name: "French Polynesia" },
  { code: 241, name: "Gabon" },
  { code: 220, name: "Gambia" },
  { code: 970, name: "Gaza Strip" },
  { code: 995, name: "Georgia" },
  { code: 49, name: "Germany" },
  { code: 233, name: "Ghana" },
  { code: 350, name: "Gibraltar" },
  { code: 30, name: "Greece" },
  { code: 299, name: "Greenland" },
  { code: 473, name: "Grenada" },
  { code: 590, name: "Guadeloupe" },
  { code: 502, name: "Guatemala" },
  { code: 224, name: "Guinea" },
  { code: 245, name: "Guinea-Bissau" },
  { code: 592, name: "Guyana" },
  { code: 509, name: "Haiti" },
  { code: 504, name: "Honduras" },
  { code: 852, name: "Hong Kong" },
  { code: 36, name: "Hungary" },
  { code: 354, name: "Iceland" },
  { code: 91, name: "India" },
  { code: 62, name: "Indonesia" },
  { code: 964, name: "Iraq" },
  { code: 98, name: "Iran" },
  { code: 353, name: "Ireland" },
  { code: 972, name: "Israel" },
  { code: 39, name: "Italy" },
  { code: 1876, name: "Jamaica" },
  { code: 81, name: "Japan" },
  { code: 962, name: "Jordan" },
  { code: 7, name: "Kazakhstan" },
  { code: 254, name: "Kenya" },
  { code: 686, name: "Kiribati" },
  { code: 965, name: "Kuwait" },
  { code: 996, name: "Kyrgyzstan" },
  { code: 856, name: "Laos" },
  { code: 371, name: "Latvia" },
  { code: 961, name: "Lebanon" },
  { code: 226, name: "Lesotho" },
  { code: 231, name: "Liberia" },
  { code: 423, name: "Liechtenstein" },
  { code: 370, name: "Lithuania" },
  { code: 352, name: "Luxembourg" },
  { code: 218, name: "Libya" },
  { code: 853, name: "Macau" },
  { code: 389, name: "Macedonia Republic" },
  { code: 261, name: "Madagascar" },
  { code: 265, name: "Malawi" },
  { code: 60, name: "Malaysia" },
  { code: 960, name: "Maldives" },
  { code: 223, name: "Mali" },
  { code: 356, name: "Malta" },
  { code: 692, name: "Marshall Islands" },
  { code: 596, name: "Martinique" },
  { code: 222, name: "Mauritania" },
  { code: 230, name: "Mauritius" },
  { code: 262, name: "Mayotte" },
  { code: 52, name: "Mexico" },
  { code: 691, name: "Micronesia" },
  { code: 373, name: "Moldova" },
  { code: 377, name: "Monaco" },
  { code: 976, name: "Mongolia" },
  { code: 382, name: "Montenegro" },
  { code: 664, name: "Montserrat" },
  { code: 212, name: "Morocco" },
  { code: 258, name: "Mozambique" },
  { code: 95, name: "Myanmar" },
  { code: 264, name: "Namibia" },
  { code: 674, name: "Nauru" },
  { code: 31, name: "Netherlands" },
  { code: 599, name: "Netherlands Antilles" },
  { code: 977, name: "Nepal" },
  { code: 687, name: "New Caledonia" },
  { code: 64, name: "New Zealand" },
  { code: 505, name: "Nicaragua" },
  { code: 227, name: "Niger" },
  { code: 234, name: "Nigeria" },
  { code: 683, name: "Niue" },
  { code: 6723, name: "Norfolk Island" },
  { code: 850, name: "North Korea" },
  { code: 44, name: "Northern Ireland" },
  { code: 670, name: "Northern Mariana Islands" },
  { code: 47, name: "Norway" },
  { code: 968, name: "Oman" },
  { code: 92, name: "Pakistan" },
  { code: 680, name: "Palau" },
  { code: 507, name: "Panama" },
  { code: 675, name: "Papua New Guinea" },
  { code: 595, name: "Paraguay" },
  { code: 51, name: "Peru" },
  { code: 63, name: "Philippines" },
  { code: 48, name: "Poland" },
  { code: 351, name: "Portugal" },
  { code: 974, name: "Qatar" },
  { code: 262, name: "Reunion" },
  { code: 40, name: "Romania" },
  { code: 7, name: "Russia" },
  { code: 250, name: "Rwanda" },
  { code: 590, name: "Saint Barthelemy" },
  { code: 290, name: "Saint Helena" },
  { code: 869, name: "Saint Kitts and Nevis" },
  { code: 758, name: "Saint Lucia" },
  { code: 590, name: "Saint Martin(French side)" },
  { code: 508, name: "Saint Pierre and Miquelon" },
  { code: 784, name: "Saint Vincent" },
  { code: 685, name: "Samoa" },
  { code: 239, name: "Sao Tomeand Principe" },
  { code: 966, name: "Saudi Arabia" },
  { code: 221, name: "Senegal" },
  { code: 381, name: "Serbia" },
  { code: 248, name: "Seychelles" },
  { code: 232, name: "Sierra Leone" },
  { code: 65, name: "Singapore" },
  { code: 421, name: "Slovakia" },
  { code: 386, name: "Slovenia" },
  { code: 677, name: "Solomon Islands" },
  { code: 252, name: "Somalia" },
  { code: 27, name: "South Africa" },
  { code: 82, name: "South Korea" },
  { code: 34, name: "Spain" },
  { code: 94, name: "Sri Lanka" },
  { code: 249, name: "Sudan" },
  { code: 597, name: "Suriname" },
  { code: 268, name: "Swaziland" },
  { code: 46, name: "Sweden" },
  { code: 41, name: "Switzerland" },
  { code: 963, name: "Syria" },
  { code: 886, name: "Taiwan" },
  { code: 992, name: "Tajikistan" },
  { code: 255, name: "Tanzania" },
  { code: 66, name: "Thailand" },
  { code: 228, name: "Togo" },
  { code: 690, name: "Tokelau" },
  { code: 676, name: "Tonga" },
  { code: 868, name: "Trinidad and Tobago" },
  { code: 216, name: "Tunisia" },
  { code: 90, name: "Turkey" },
  { code: 993, name: "Turkmenistan" },
  { code: 649, name: "Turks and Caicos Islands" },
  { code: 688, name: "Tuvalu" },
  { code: 256, name: "Uganda" },
  { code: 380, name: "Ukraine" },
  { code: 971, name: "UAE" },
  { code: 44, name: "United Kingdom" },
  { code: 1, name: "USA" },
  { code: 598, name: "Uruguay" },
  { code: 998, name: "Uzbekistan" },
  { code: 678, name: "Vanuatu" },
  { code: 58, name: "Venezuela" },
  { code: 84, name: "Vietnam" },
  { code: 340, name: "Virgin Islands" },
  { code: 681, name: "Wallis and Futuna" },
  { code: 970, name: "West Bank" },
  { code: 967, name: "Yemen" },
  { code: 260, name: "Zambia" },
  { code: 263, name: "Zimbabwe" },
];
export const currencyList: Currency[] = [
  { value: "AED", label: "AED" },
  { value: "AFN", label: "AFN" },
  { value: "ALL", label: "ALL" },
  { value: "AMD", label: "AMD" },
  { value: "ANG", label: "ANG" },
  { value: "AOA", label: "AOA" },
  { value: "ARS", label: "ARS" },
  { value: "AUD", label: "AUD" },
  { value: "AWG", label: "AWG" },
  { value: "AZN", label: "AZN" },
  { value: "BAM", label: "BAM" },
  { value: "BBD", label: "BBD" },
  { value: "BDT", label: "BDT" },
  { value: "BGN", label: "BGN" },
  { value: "BHD", label: "BHD" },
  { value: "BIF", label: "BIF" },
  { value: "BMD", label: "BMD" },
  { value: "BND", label: "BND" },
  { value: "BOB", label: "BOB" },
  { value: "BOV", label: "BOV" },
  { value: "BRL", label: "BRL" },
  { value: "BSD", label: "BSD" },
  { value: "BTN", label: "BTN" },
  { value: "BWP", label: "BWP" },
  { value: "BYN", label: "BYN" },
  { value: "BZD", label: "BZD" },
  { value: "CAD", label: "CAD" },
  { value: "CDF", label: "CDF" },
  { value: "CHE", label: "CHE" },
  { value: "CHF", label: "CHF" },
  { value: "CHW", label: "CHW" },
  { value: "CLF", label: "CLF" },
  { value: "CLP", label: "CLP" },
  { value: "CNY", label: "CNY" },
  { value: "COP", label: "COP" },
  { value: "COU", label: "COU" },
  { value: "CRC", label: "CRC" },
  { value: "CRD", label: "CRD" },
  { value: "CUC", label: "CUC" },
  { value: "CUP", label: "CUP" },
  { value: "CVE", label: "CVE" },
  { value: "CZK", label: "CZK" },
  { value: "DJF", label: "DJF" },
  { value: "DKK", label: "DKK" },
  { value: "DOP", label: "DOP" },
  { value: "DZD", label: "DZD" },
  { value: "EGP", label: "EGP" },
  { value: "ERN", label: "ERN" },
  { value: "ETB", label: "ETB" },
  { value: "EUR", label: "EUR" },
  { value: "FJD", label: "FJD" },
  { value: "FKP", label: "FKP" },
  { value: "GBP", label: "GBP" },
  { value: "GEL", label: "GEL" },
  { value: "GGP", label: "GGP" },
  { value: "GHS", label: "GHS" },
  { value: "GIP", label: "GIP" },
  { value: "GMD", label: "GMD" },
  { value: "GNF", label: "GNF" },
  { value: "GTQ", label: "GTQ" },
  { value: "GYD", label: "GYD" },
  { value: "HKD", label: "HKD" },
  { value: "HNL", label: "HNL" },
  { value: "HRK", label: "HRK" },
  { value: "HTG", label: "HTG" },
  { value: "HUF", label: "HUF" },
  { value: "IDR", label: "IDR" },
  { value: "ILS", label: "ILS" },
  { value: "IMP", label: "IMP" },
  { value: "INR", label: "INR" },
  { value: "IQD", label: "IQD" },
  { value: "IRR", label: "IRR" },
  { value: "ISK", label: "ISK" },
  { value: "JEP", label: "JEP" },
  { value: "JMD", label: "JMD" },
  { value: "JOD", label: "JOD" },
  { value: "JPY", label: "JPY" },
  { value: "KES", label: "KES" },
  { value: "KGS", label: "KGS" },
  { value: "KHR", label: "KHR" },
  { value: "KMF", label: "KMF" },
  { value: "KPW", label: "KPW" },
  { value: "KRW", label: "KRW" },
  { value: "KWD", label: "KWD" },
  { value: "KYD", label: "KYD" },
  { value: "KZT", label: "KZT" },
  { value: "LAK", label: "LAK" },
  { value: "LBP", label: "LBP" },
  { value: "LKR", label: "LKR" },
  { value: "LRD", label: "LRD" },
  { value: "LSL", label: "LSL" },
  { value: "LYD", label: "LYD" },
  { value: "MAD", label: "MAD" },
  { value: "MDL", label: "MDL" },
  { value: "MGA", label: "MGA" },
  { value: "MKD", label: "MKD" },
  { value: "MMK", label: "MMK" },
  { value: "MNT", label: "MNT" },
  { value: "MOP", label: "MOP" },
  { value: "MRO", label: "MRO" },
  { value: "MUR", label: "MUR" },
  { value: "MVR", label: "MVR" },
  { value: "MWK", label: "MWK" },
  { value: "MXN", label: "MXN" },
  { value: "MXV", label: "MXV" },
  { value: "MYR", label: "MYR" },
  { value: "MZN", label: "MZN" },
  { value: "NAD", label: "NAD" },
  { value: "NGN", label: "NGN" },
  { value: "NIO", label: "NIO" },
  { value: "NOK", label: "NOK" },
  { value: "NPR", label: "NPR" },
  { value: "NZD", label: "NZD" },
  { value: "OMR", label: "OMR" },
  { value: "PAB", label: "PAB" },
  { value: "PEN", label: "PEN" },
  { value: "PGK", label: "PGK" },
  { value: "PHP", label: "PHP" },
  { value: "PKR", label: "PKR" },
  { value: "PLN", label: "PLN" },
  { value: "PYG", label: "PYG" },
  { value: "QAR", label: "QAR" },
  { value: "RON", label: "RON" },
  { value: "RSD", label: "RSD" },
  { value: "RUB", label: "RUB" },
  { value: "RWF", label: "RWF" },
  { value: "SAR", label: "SAR" },
  { value: "SBD", label: "SBD" },
  { value: "SCR", label: "SCR" },
  { value: "SDG", label: "SDG" },
  { value: "SEK", label: "SEK" },
  { value: "SGD", label: "SGD" },
  { value: "SHP", label: "SHP" },
  { value: "SLL", label: "SLL" },
  { value: "SOS", label: "SOS" },
  { value: "SRD", label: "SRD" },
  { value: "SSP", label: "SSP" },
  { value: "STD", label: "STD" },
  { value: "SVC", label: "SVC" },
  { value: "SYP", label: "SYP" },
  { value: "SZL", label: "SZL" },
  { value: "THB", label: "THB" },
  { value: "TJS", label: "TJS" },
  { value: "TMT", label: "TMT" },
  { value: "TND", label: "TND" },
  { value: "TOP", label: "TOP" },
  { value: "TRY", label: "TRY" },
  { value: "TTD", label: "TTD" },
  { value: "TVD", label: "TVD" },
  { value: "TWD", label: "TWD" },
  { value: "TZS", label: "TZS" },
  { value: "UAH", label: "UAH" },
  { value: "UGX", label: "UGX" },
  { value: "USD", label: "USD" },
  { value: "USN", label: "USN" },
  { value: "UYI", label: "UYI" },
  { value: "UYU", label: "UYU" },
  { value: "UZS", label: "UZS" },
  { value: "VEF", label: "VEF" },
  { value: "VND", label: "VND" },
  { value: "VUV", label: "VUV" },
  { value: "WST", label: "WST" },
  { value: "XAF", label: "XAF" },
  { value: "XAG", label: "XAG" },
  { value: "XAU", label: "XAU" },
  { value: "XBA", label: "XBA" },
  { value: "XBB", label: "XBB" },
  { value: "XBC", label: "XBC" },
  { value: "XBD", label: "XBD" },
  { value: "XCD", label: "XCD" },
  { value: "XDR", label: "XDR" },
  { value: "XOF", label: "XOF" },
  { value: "XPD", label: "XPD" },
  { value: "XPF", label: "XPF" },
  { value: "XPT", label: "XPT" },
  { value: "XRD", label: "XRD" },
  { value: "XSU", label: "XSU" },
  { value: "XTS", label: "XTS" },
  { value: "XUA", label: "XUA" },
  { value: "XXX", label: "XXX" },
  { value: "YER", label: "YER" },
  { value: "ZAR", label: "ZAR" },
  { value: "ZMW", label: "ZMW" },
];
export default timeOptions;
