import React, { useEffect, useRef, useState } from "react";
import csvIcon from "../../../assets/img/csvicon.svg";
import doubleTick from "../../../assets/img/double-tick.svg";
import loadingProgress from "../../../assets/img/download-progress-loading.svg";
import pdfIcon from "../../../assets/img/pdficon.svg";
import tickCircleSolid from "../../../assets/img/tick-circle-solid.svg";
import xlsIcon from "../../../assets/img/xlsicon.svg";
import CloseButton from "../../../components/Common/CloseButton";
import { convertBytesToString } from "../../../lib/utility";

const DownloadLogCard = ({ download, index, deleteLog }) => {
  const [isDownloading, setIsDownloading] = useState(
    download.downloading ?? false
  );
  const [isFetching, setIsFetching] = useState(getPercentage());

  const timer = useRef();
  const iconMap = {
    pdf: pdfIcon,
    csv: csvIcon,
    xls: xlsIcon,
  };
  function getPercentage() {
    let curr = parseInt(
      ((Date.now() - download.startTime) / download.totalTime) * 100
    );

    if (curr >= 60) {
      curr = Math.floor(
        60 + (Date.now() - download.startTime - download.totalTime * 0.6) / 1000
      );
    }

    if (curr > 70) {
      curr = Number(
        67 +
          (Date.now() - download.startTime - download.totalTime * 0.7) /
            1000 /
            3
      ).toFixed(2);
    }
    if (curr > 75) {
      curr = Number(
        71 +
          (Date.now() - download.startTime - download.totalTime * 0.75) /
            1000 /
            5
      ).toFixed(2);
    }
    if (curr > 83) {
      curr = Number(
        78 +
          (Date.now() - download.startTime - download.totalTime * 0.78) /
            1000 /
            8
      ).toFixed(2);
    }
    if (curr > 90) {
      curr = Number(
        85 +
          (Date.now() - download.startTime - download.totalTime * 0.9) /
            1000 /
            40
      ).toFixed(2);
    }

    return !isDownloading ? 100 : download.size !== 0 ? 95 : curr;
  }
  const handleIsFetching = () => {
    timer.current = setInterval(() => {
      let percent = getPercentage();
      setIsFetching(percent);
      setIsDownloading(download.downloading);

      if (percent >= 95) {
        clearInterval(timer.current);
      }
    }, 1000);
  };
  let progressWidth = download.downloading
    ? isFetching > 94 || download.size !== 0
      ? 95
      : isFetching
    : 100;

  useEffect(() => {
    handleIsFetching();
    return () => {
      clearInterval(timer.current);
    };
  }, [download]);

  return (
    <div className="downloadCard mb-2 p-3">
      <style>{`.animate{animation: progress var(--time) 0s ease} @keyframes progress {
        0% {width: var(--width)} 100% {width: 100%}}`}</style>
      <div className="close-wrapper">
        <CloseButton onClick={deleteLog} height={12} color={"#444"} />
      </div>
      <div>
        <div className="d-flex align-items-center">
          {!isDownloading ? (
            <img
              src={tickCircleSolid}
              alt=""
              style={{ width: "35px", height: "35px" }}
              loading="lazy"
            />
          ) : (
            <img
              src={iconMap[download.type]}
              alt=""
              style={{ width: "50px", height: "50px" }}
              loading="lazy"
            />
          )}

          <div className="file-name-wrapper flex-grow-1">
            <div className="ps-1 d-flex align-items-center gap-3">
              <p className="file-name">{download.name}</p>
              <span className="bg-info">{download.type}</span>
            </div>
          </div>
        </div>
        <div className="mt-2 px-1">
          <div className="d-flex">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center gap-2">
                <p
                  className="small"
                  style={{ fontSize: "13px", margin: "4px" }}
                >
                  {progressWidth < 95
                    ? "Getting Your File..."
                    : !isDownloading
                    ? "Downloaded Successfully"
                    : "Downloading File..."}
                </p>
                {!isDownloading && (
                  <img
                    src={doubleTick}
                    style={{ width: "18px", height: "18px" }}
                    loading="lazy"
                  />
                )}
              </div>
              {download.size !== 0 && (
                <p style={{ fontSize: "13px", margin: "0px" }}>
                  {convertBytesToString(download.size)}
                </p>
              )}
            </div>
            <div className="d-flex align-items-center">
              {download.size === 0 && isFetching < 95 && isDownloading && (
                <p style={{ fontSize: "13px", margin: "0px" }}>
                  {progressWidth}%
                </p>
              )}
              {!(isFetching < 95) && isDownloading && download.size !== 0 && (
                <img
                  src={loadingProgress}
                  alt="Rotating"
                  className="rotate img-fluid ms-2"
                  style={{ width: "15px", height: "15px" }}
                  loading="lazy"
                />
              )}
            </div>
          </div>
          {
            <div className="progress mt-1" style={{ height: "6px" }}>
              <div
                className={`progress-bar ${
                  progressWidth >= 75
                    ? "bg-success"
                    : progressWidth >= 25
                    ? "bg-warning"
                    : "bg-danger"
                }`}
                role="progressbar"
                style={{
                  width: progressWidth + "%",
                }}
                aria-valuenow={progressWidth}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default DownloadLogCard;
