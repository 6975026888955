import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  companyData: [],
  isFetchingCompanyData: false,
  isAddingCompanyData: false,
  isEditingCompanyData: false,
  isDeletingCompanyData: false,
  error: null,
  companyUserData: [],
  isFetchingCompanyUserList: false,
  isFetchingUserBalance: false,
  isFetchingCompanyTxns: false,
};

export const getCompanyData = createAsyncThunk(
  "company/getData",
  async (params, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url =
        params !== undefined ? `/companies/filter${params}` : `/companies/list`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const addCompanyData = createAsyncThunk(
  "company/createData",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/companies`;
      const { data, status } = await axios.post(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editCompanyData = createAsyncThunk(
  "company/editData",
  async ({ id, udata }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/companies?companyId=${id}`;

      const { data, status } = await axios.put(url, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteCompanyData = createAsyncThunk(
  "company/deleteData",
  async ({ udata }, thunkAPI) => {
    // Adjusted to destructure the object
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      let url = `/companies?companyId=${udata}`; // Using udata from destructured object

      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const companyUserList = createAsyncThunk(
  "company/user-list",
  async ({ udata }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/companies/by-company?companyId=${udata}`;
      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const modifyCompanyBalance = createAsyncThunk(
  "company/modify-balance",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const queryParam = new URLSearchParams();
      if (udata.companyId) queryParam.set("companyId", udata.companyId);
      if (udata.type) queryParam.set("type", udata.type);
      if (udata.amount) queryParam.set("amount", udata.amount);
      if (udata.walletFlag) queryParam.set("walletFlag", udata.walletFlag);
      let url = `/companies/balance?${queryParam.toString()}`;

      const { data, status } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateFlag = createAsyncThunk(
  "company/flag",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/companies/refresh-flag?param=company`;

      const { data, status } = await axios.post(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCTxnHistory = createAsyncThunk(
  "company/transaction-history",
  async ({ companyId, startDate, endDate, statusType, types }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = "/companies/report";

      const params = new URLSearchParams();
      if (companyId) params.append("companyIds", companyId);
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);
      if (statusType) params.append("status", statusType);
      if (types) params.append("type", types);

      const finalUrl = `${url}?${params.toString()}`;
      const { data, status } = await axios.get(finalUrl, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearStateCompany: (state) => {
      state.companyData = [];
      state.isFetchingCompanyData = false;
      state.isAddingCompanyData = false;
      state.isEditingCompanyData = false;
      state.isDeletingCompanyData = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Get Company Data
    builder
      .addCase(getCompanyData.pending, (state) => {
        state.isFetchingCompanyData = true;
        state.error = null;
      })
      .addCase(getCompanyData.fulfilled, (state, action) => {
        state.isFetchingCompanyData = false;
        state.companyData = action.payload.data;
      })
      .addCase(getCompanyData.rejected, (state, action) => {
        state.isFetchingCompanyData = false;
        state.error = action.payload || "Failed to fetch company data.";
      });

    // Add Company Data
    builder
      .addCase(addCompanyData.pending, (state) => {
        state.isAddingCompanyData = true;
        state.error = null;
      })
      .addCase(addCompanyData.fulfilled, (state, action) => {
        state.isAddingCompanyData = false;
        state.companyData.push(action.payload);
      })
      .addCase(addCompanyData.rejected, (state, action) => {
        state.isAddingCompanyData = false;
        state.error = action.payload || "Failed to add company data.";
      });

    // Edit Company Data
    builder
      .addCase(editCompanyData.pending, (state) => {
        state.isEditingCompanyData = true;
        state.error = null;
      })
      .addCase(editCompanyData.fulfilled, (state, action) => {
        state.isEditingCompanyData = false;
      })
      .addCase(editCompanyData.rejected, (state, action) => {
        state.isEditingCompanyData = false;
        state.error = action.payload || "Failed to edit company data.";
      });

    // Delete Company Data
    builder
      .addCase(deleteCompanyData.pending, (state) => {
        state.isDeletingCompanyData = true;
        state.error = null;
      })
      .addCase(deleteCompanyData.fulfilled, (state, action) => {
        if (state.companyData.length === 1) state.companyData = [];
        state.isDeletingCompanyData = false;
      })
      .addCase(deleteCompanyData.rejected, (state, action) => {
        state.isDeletingCompanyData = false;
        state.error = action.payload || "Failed to delete company data.";
      });
    // company user list
    builder
      .addCase(companyUserList.pending, (state) => {
        state.isFetchingCompanyUserList = true;
        state.error = null;
      })
      .addCase(companyUserList.fulfilled, (state, action) => {
        state.isFetchingCompanyUserList = false;
        state.companyUserData = action.payload;
      })
      .addCase(companyUserList.rejected, (state, action) => {
        state.isFetchingCompanyUserList = false;
        state.error = action.payload || "Failed to  company user list.";
      });
    builder
      .addCase(modifyCompanyBalance.pending, (state) => {
        state.isFetchingUserBalance = true;
        state.error = null;
      })
      .addCase(modifyCompanyBalance.fulfilled, (state, action) => {
        state.isFetchingUserBalance = false;
        state.companyUserData = action.payload;
      })
      .addCase(modifyCompanyBalance.rejected, (state, action) => {
        state.isFetchingUserBalance = false;
        state.error = action.payload || "Failed to  company user list.";
      });
    builder
      .addCase(getCTxnHistory.pending, (state) => {
        state.isFetchingCompanyTxns = true;
        state.error = null;
      })
      .addCase(getCTxnHistory.fulfilled, (state, action) => {
        state.isFetchingCompanyTxns = false;
      })
      .addCase(getCTxnHistory.rejected, (state, action) => {
        state.isFetchingCompanyTxns = false;
        state.error = action.payload || "Failed to get company transactions.";
      });
  },
});

export const { clearStateCompany } = companySlice.actions;
export const companySelector = (state) => state.company;
export default companySlice.reducer;
