import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import addressBookSliceReducer from "../components/AddressBook/AddressBookSlice";
import GroupDataSliceReducer from "../components/AddressBook/GroupDataSlice";
import AlertSlice from "../components/Alert/AlertSlice";
import authSliceReducer from "../components/Auth/AuthSlice";
import BSFMSlice from "../components/BSFM/BSFMSlice";
import dashboardReducer from "../components/Dashboard/DashboardSlice";
import DltSlice from "../components/DLT/DltSlice";
import DownloadsSliceReducer from "../components/Downloads/DownloadsSlice";
import AutoScheduleSlice from "../components/Message/AutoSchedule/AutoScheduleSlice";
import BulkSmsSliceReducer from "../components/Message/BulkSms/BulkSmsSlice";
import MessageSliceReducer from "../components/Message/MessageSlice";
import resendSlice from "../components/Message/Resend/ResendSlice";
import SingleSmsSliceReducer from "../components/Message/SingleSms/SingleSmsSlice";
import NetworkSlice from "../components/Network/NetworkSlice";
import ProfileSliceReducer from "../components/Profile/ProfileSlice";
import ReportSliceReducer from "../components/Report/ReportSlice";
import RoutingSlice from "../components/Routing/RoutingSlice";
import SaleSliceReducer from "../components/Sales/SalesSlice";
import SMSCSlice from "../components/SMSC/SMSCSlice";
import SubscriptionSliceReducer from "../components/Subscription/SubscriptionSlice";
import TemplateSliceReducer from "../components/Templates/TemplateSlice";
import TwoWaySliceReducer from "../components/TwoWay/TwoWaySlice";
import UpdatePasswordReducer from "../components/UpdatePassword/UpdatePasswordSlice";
import UserSlice from "../components/User/UserSlice";
import BulkEditSlice from "../components/Utility/Edit/Bulk/BulkEditSlice";
import ScheduleEditSlice from "../components/Utility/Edit/Schedule/ScheduleEditSlice";
import WalletReducer from "../components/Wallet/WalletSlice";
import downloadProgressReducer from "../Layouts/Navbar/DownloadProgress/DownloadLogSlice";
import NotificationReducer from "../Layouts/Navbar/Notifications/NotificationSlice";
import { kindOf, showToast } from "../lib/utility";
import commonUtilitySlice from "./commonUtilitySlice";
import CompanySliceReducer from "../components/Company/CompanySlice";

const rootReducer = combineReducers({
  auth: authSliceReducer,
  message: MessageSliceReducer,
  sendSms: SingleSmsSliceReducer,
  sendBulk: BulkSmsSliceReducer,
  profile: ProfileSliceReducer,
  template: TemplateSliceReducer,
  addressBook: addressBookSliceReducer,
  Group: GroupDataSliceReducer,
  Downloads: DownloadsSliceReducer,
  twoway: TwoWaySliceReducer,
  report: ReportSliceReducer,
  subscription: SubscriptionSliceReducer,
  dashboard: dashboardReducer,
  wallet: WalletReducer,
  notification: NotificationReducer,
  updatePassword: UpdatePasswordReducer,
  scheduleEdit: ScheduleEditSlice,
  bulkEdit: BulkEditSlice,
  autoSchedule: AutoScheduleSlice,
  sales: SaleSliceReducer,
  bsfm: BSFMSlice,
  user: UserSlice,
  routing: RoutingSlice,
  smsc: SMSCSlice,
  alert: AlertSlice,
  dlt: DltSlice,
  network: NetworkSlice,
  resend: resendSlice,
  downloadProgress: downloadProgressReducer,
  commonUtility: commonUtilitySlice,
  company: CompanySliceReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      // serializableCheck: {
      //   // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   ignoredActions: [PERSIST, REGISTER, PURGE],
      // },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store, composeWithDevTools());

export const firstString = (i, ...args) => {
  let retString;

  for (let i = 0; i < args.length; i++) {
    if (typeof args[i] === "string" && args[i] !== "") {
      retString = args[i];
      break;
    }
  }
  return retString;
};

async function checkInternetConnection() {
  return await fetch("https://www.google.com/", {
    method: "HEAD",
    mode: "no-cors",
  })
    .then(() => true)
    .catch(() => false);
}

export const isFunction = (cb) => kindOf(cb, "function");
export const commonMiddleware = async (action, options = {}) => {
  const publicRoute =
    window.location.pathname == "/login" || window.location.pathname == "/";
  const guestRoute = window.location.pathname == "/update-password";
  const messageTypeMap = {
    create: ["Created", "Creating"],
    update: ["Updated", "Updating"],
    delete: ["Deleted", "Deleting"],
    get: ["Get", "Getting"],
  };
  let { type, name, cb, error, callBacks } = options;
  let status = action.payload?.status?.toString();
  let message = firstString(
    0,
    action.payload,
    action.payload?.message,
    action.payload?.data,
    action.payload?.data?.message
  );
  callBacks = callBacks === undefined ? {} : callBacks;

  if (
    action?.error &&
    !["404", "401", "503", "400", "409", "502", "429"].includes(status)
  ) {
    if (!(await checkInternetConnection())) {
      if (!globalThis.isOffline || Date.now() > +globalThis.isOffline + 15000) {
        showToast("Internet Disconnected", "warning");
        globalThis.isOffline = Date.now();
      }
      return;
    } else {
      if (action?.error?.message === "Netork Error") {
        showToast("Network Issue, Please Check Internet Connection", "warning");
      }
      globalThis.isOffline = false;
    }
  } else globalThis.isOffline = false;
  if (
    status === "401" ||
    (action.payload?.error === "Unauthorized" &&
      action.payload?.message?.includes("JWT"))
  ) {
    if (window.location.pathname != "/login" || window.location.pathname != "/")
      showToast(
        publicRoute
          ? message
          : guestRoute
          ? "Unauthorized"
          : "User Session Expired",
        "warning"
      );
    setTimeout(() => {
      localStorage.clear();
      persistor.purge();
      globalThis.profileData = null;
      if (!publicRoute) window.location.href = "/login";
      globalThis.profileData = null;
    }, 1000);

    return;
  }
  if (status === "503") {
    showToast(message ?? `Service for (${name}) is in Maintainance`, "warning");
    return;
  }
  if (status === "429") {
    showToast(message ?? "Too many request, wait for a while " + name, "warning");
    return;
  }
  if (status === "400") {
    showToast(message ?? "Bad Request for " + name, "warning");
    return;
  }
  if (status === "404") {
    !callBacks.noToast &&
      showToast(message ?? "No Data Found for " + name, "info");
    if (isFunction(callBacks.modal)) callBacks.modal();
    if (isFunction(callBacks.notFound)) callBacks.notFound();
    return;
  }
  if (status === "409") {
    !callBacks.noToast && showToast(message ?? "Duplicate  " + name, "warning");
    return;
  }
  if (["create", "update"].includes(type)) {
    if (message?.includes("Duplicate")) {
      showToast("Duplicate " + name, "warning");
      return;
    }
  }
  if (action.error || (action.payload && action.payload?.error)) {
    !callBacks.noToast &&
      showToast(
        message ??
          error ??
          "Error while " + messageTypeMap[type][1].toLowerCase() + " " + name,
        "error"
      );
    if (isFunction(callBacks.modal)) callBacks.modal();
    return;
  }

  if (type !== "get") {
    !callBacks.noToast &&
      showToast(
        message ?? "Successfully " + messageTypeMap[type][0] + " " + name,
        "success"
      );
    if (isFunction(callBacks.modal)) callBacks.modal();

    if (!callBacks.isLast) {
      setTimeout(() => {
        isFunction(callBacks.refresh) && callBacks.refresh();
      }, callBacks.refreshTime ?? 1200);
    }
  }

  isFunction(cb) && cb(action);
};
export const middleware = (action, name, cb, error, callBacks) => {
  commonMiddleware(action, {
    name,
    cb,
    error,
    callBacks,
    type: "get",
  });
};
export const middlewareCreate = (action, name, cb, error, callBacks) => {
  commonMiddleware(action, {
    name,
    cb,
    error,
    callBacks,
    type: "create",
  });
};
export const middlewareUpdate = (action, name, cb, error, callBacks) => {
  commonMiddleware(action, {
    name,
    cb,
    error,
    callBacks,
    type: "update",
  });
};
export const middlewareDelete = (action, name, cb, error, callBacks) => {
  commonMiddleware(action, {
    name,
    cb,
    error,
    callBacks,
    type: "delete",
  });
};

// https://api.dev101.broadnet.app/login/changePassword
