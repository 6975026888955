import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../redux/axios";

const initialState = {
  singleSmsData: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};

export const sendSms = createAsyncThunk(
  "sms/sendSms",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post("/sms/sendSms", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getQuickNumbers = createAsyncThunk(
  "sms/quick-get",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get("/sms/quickNumber", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const saveQuickNumbers = createAsyncThunk(
  "sms/save-quick",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.post(
        "/sms/saveNumbers?numbers=" + udata,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const singleSmsSlice = createSlice({
  name: "sendSms",
  initialState,
  reducers: {
    clearStateSingleSms: (state, { payload }) => {
      state.singleSmsData = null;
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(sendSms.fulfilled, (state, action) => {
        state.singleSmsData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(sendSms.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(sendSms.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      });
  },
});

export const { clearStateSingleSms } = singleSmsSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateSingleSms());
  };
}

export const singleSmsSelector = (state) => state.sendSms;

export default singleSmsSlice.reducer;
