import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  networkData: [], // Initial network data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  createNetwork: {},
  isSuccessCreateNetwork: false,
  isErrorCreateNetwork: false,
  isErrorMessageCreateNetwork: "",

  deleteNetwork: {},
  isSuccessDeleteNetwork: false,
  isErrorDeleteNetwork: false,
  isErrorMessageDeleteNetwork: "",

  editNetwork: {},
  isSuccessEditNetwork: false,
  isErrorEditNetwork: false,
  isErrorMessageEditNetwork: "",

  countries: [],
  mcc: [],
  mnc: [],

  isFetchingCountries: false,
  isFetchingMCC: false,
  isFetchingMNC: false,
};

export const refreshFlag = createAsyncThunk(
  "network/flag-refresh", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/network/refresh?param=network`;

      const { data, status } = await axios.post(
        url,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getNetworkData = createAsyncThunk(
  "network/get-all-network", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/network/search?`;

      if (udata) {
        url += udata;
      }

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createNetworkDataMultiple = createAsyncThunk(
  "network/create-network",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      let formData = new FormData();
      formData.append("fileMccMnc", udata);
      // formData.append("requestMccMnc", '{checkMcc:"multiple"}');

      const { data, status } = await axios.post("/network/add", formData, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createNetworkData = createAsyncThunk(
  "network/create-network",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      let formData = new FormData();
      // formData.append("fileMccMnc", null);
      formData.append("requestMccMnc", JSON.stringify(udata));

      const { data, status } = await axios.post("/network/add", formData, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteNetworkData = createAsyncThunk(
  "network/delete-network",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/network/delete?ids=${udata.ids}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editNetworkData = createAsyncThunk(
  "network/update-network",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/network/update`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getCountries = createAsyncThunk(
  "network/countries",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/network/countries`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMccs = createAsyncThunk(
  "network/mcc",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/network/mcc?cc=${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getMncs = createAsyncThunk(
  "network/mnc",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/network/mnc?mcc=${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const uploadUpdate = createAsyncThunk(
  "network/upload-updated",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      let formdata = new FormData();
      formdata.append("fileMccMnc", udata);

      const { data, status } = await axios.put(
        `/network/upload-update`,
        formdata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    clearStateNetwork: (state, { payload }) => {
      state.networkData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateNetwork: (state, { payload }) => {
      state.createNetwork = {};
      state.isFetching = false;
      state.isSuccessCreateNetwork = false;
      state.isErrorCreateNetwork = false;
      state.isErrorMessageCreateNetwork = "";
    },
    clearStateDeleteNetwork: (state, { payload }) => {
      state.deleteNetwork = {};
      state.isFetching = false;
      state.isSuccessDeleteNetwork = false;
      state.isErrorDeleteNetwork = false;
      state.isErrorMessageDeleteNetwork = "";
    },
    clearStateEditNetwork: (state, { payload }) => {
      state.editNetwork = {};
      state.isFetching = false;
      state.isSuccessEditNetwork = false;
      state.isErrorEditNetwork = false;
      state.isErrorMessageEditNetwork = "";
    },
    clearCountryMccMnc: (state, { payload }) => {
      state.countries = [];
      state.mcc = [];
      state.mnc = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNetworkData.fulfilled, (state, action) => {
        state.networkData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getNetworkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getNetworkData.rejected, (state, action) => {
        state.networkData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })

      //Create Network API
      .addCase(createNetworkData.fulfilled, (state, action) => {
        state.createNetwork = action.payload;
        state.isFetching = false;
        state.isSuccessCreateNetwork = true;
      })
      .addCase(createNetworkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createNetworkData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateNetwork = true;
        state.isErrorMessageCreateNetwork = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(deleteNetworkData.fulfilled, (state, action) => {
        state.deleteNetwork = action.payload;
        state.isFetching = false;
        state.isSuccessDeleteNetwork = true;

        if (state.networkData.length === 1) state.networkData = [];
      })
      .addCase(deleteNetworkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteNetworkData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorDeleteNetwork = true;
        state.isErrorMessageDeleteNetwork = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(editNetworkData.fulfilled, (state, action) => {
        state.editNetwork = action.payload;
        state.isFetching = false;
        state.isSuccessEditNetwork = true;
        state.isErrorMessageEditNetwork = action.payload.data;
      })
      .addCase(editNetworkData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editNetworkData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorEditNetwork = true;
        state.isErrorMessageEditNetwork = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isFetchingCountries = false;
      })
      .addCase(getCountries.pending, (state) => {
        state.isFetchingCountries = true;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.isFetchingCountries = false;
      })
      .addCase(getMncs.fulfilled, (state, action) => {
        state.isFetchingMNC = false;
      })
      .addCase(getMncs.pending, (state) => {
        state.isFetchingMNC = true;
      })
      .addCase(getMncs.rejected, (state, action) => {
        state.isFetchingMNC = false;
      })
      .addCase(getMccs.fulfilled, (state, action) => {
        state.isFetchingMCC = false;
      })
      .addCase(getMccs.pending, (state) => {
        state.isFetchingMCC = true;
      })
      .addCase(getMccs.rejected, (state, action) => {
        state.isFetchingMCC = false;
      });
  },
});

export const {
  clearStateNetwork,
  clearStateCreateNetwork,
  clearStateDeleteNetwork,
  clearStateEditNetwork,
  clearCountryMccMnc,
} = networkSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateNetwork());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateNetwork());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteNetwork());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditNetwork());
  };
}

export const networkSelector = (state) => state.network;

export default networkSlice.reducer;
