import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  smscData: [], // Initial user data is an array containing an empty object
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",

  isFetchingGroupMemberUpdate: false,

  flagData: [],
  statusData: [],

  createSmsc: {},
  isSuccessCreateSmsc: false,
  isErrorCreateSmsc: false,
  isErrorMessageCreateSmsc: "",

  smscLimitsData: [],
  smscLoopingData: [],
  smscHLRData: [],
  smscHLRStatusData: [],
  smscScheduleData: [],
  smscBlockingData: [],
  smscGroupData: [],
  smscGroupMemberData: [],
};

export const getSmscCustomData = createAsyncThunk(
  "smsc/get-all-smsc-custom", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/smsc/status?purpose=` + udata.purpose;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscData = createAsyncThunk(
  "smsc/get-all-smsc", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/smsc/smscEntries?purpose=edit`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.error("err ===>", err);
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSmscData = createAsyncThunk(
  "smsc/create-smsc",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscData = createAsyncThunk(
  "smsc/delete-smsc",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(`/smsc/delete/${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSingleSmscData = createAsyncThunk(
  "smsc/data-smsc-single",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/smsc/get?id=${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscData = createAsyncThunk(
  "smsc/update-smsc",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/smsc/update/${udata.id}`,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscFlag = createAsyncThunk(
  "smsc/get-smsc-flag", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/smsc/status?purpose=flag", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateSmscFlag = createAsyncThunk(
  "smsc/update-smsc-flag", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/smsc/flagBinding?smscId=" +
          udata.smscId.toString() +
          "&option=" +
          udata.option,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscStatus = createAsyncThunk(
  "smsc/get-smsc-status", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/smsc/status?purpose=status", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscLimitsData = createAsyncThunk(
  "smsc/limit-list", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/smsc/list`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const createSmscLimitsData = createAsyncThunk(
  "smsc/limit-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/limit/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscLimitsData = createAsyncThunk(
  "smsc/limit-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/deleteLimit/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscLimitsData = createAsyncThunk(
  "smsc/limit-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/smsc/updateLimit/${udata.id}`,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscLoopingData = createAsyncThunk(
  "smsc/looping-list",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/smsc/looping/list`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSmscLoopingData = createAsyncThunk(
  "smsc/looping-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/looping/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscLoopingData = createAsyncThunk(
  "smsc/looping-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/smsclooping/deleteLoopingRule/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscLoopingData = createAsyncThunk(
  "smsc/looping-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/smsc/looping/update`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateSmscHLRFlagData = createAsyncThunk(
  "smsc/hlr-flag-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/hlr/flagBinding?option=" + udata.option + "&smscId=" + udata.smscId,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscHLRStatusData = createAsyncThunk(
  "smsc/hlr-status-list",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/hlr/get-all?purpose=status", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscHLRData = createAsyncThunk(
  "smsc/hlr-list",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/hlr/get-all?purpose=list", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSmscHLRData = createAsyncThunk(
  "smsc/hlr-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/hlr/save-hlr-smsc", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscHLRData = createAsyncThunk(
  "smsc/hlr-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/hlr/delete-hlr-smsc/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscHLRSingleData = createAsyncThunk(
  "smsc/hlr-edit-single",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(`/hlr/get-hlr-smsc/${udata}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscHLRData = createAsyncThunk(
  "smsc/hlr-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/hlr/update-hlr-smsc/${udata.id}`,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscScheduleData = createAsyncThunk(
  "smsc/schedule-list", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("smsc/trafficSchedule/list", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const createSmscScheduleData = createAsyncThunk(
  "smsc/schedule-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/schedule/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscScheduleData = createAsyncThunk(
  "smsc/schedule-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/schedule/delete/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscScheduleData = createAsyncThunk(
  "smsc/schedule-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/smsc/schedule/update`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscBlockingData = createAsyncThunk(
  "smsc/blocking-list", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/smsc/blocking/list", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const createSmscBlockingData = createAsyncThunk(
  "smsc/blocking-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/blocking/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscBlockingData = createAsyncThunk(
  "smsc/blocking-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/blocking/delete/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscBlockingData = createAsyncThunk(
  "smsc/blocking-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/smsc/update/blocking`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscGroupData = createAsyncThunk(
  "smsc/group-list", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/smsc/group/list", {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const createSmscGroupData = createAsyncThunk(
  "smsc/group-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("/smsc/group/save", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscGroupData = createAsyncThunk(
  "smsc/group-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/group/delete/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscGroupData = createAsyncThunk(
  "smsc/group-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`/smsc/group/update`, udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSmscGroupMemberData = createAsyncThunk(
  "smsc/groupMember-list", // Action type
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("/smsc/groupMember/" + udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
export const createSmscGroupMemberData = createAsyncThunk(
  "smsc/groupMember-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/smsc/groupMember/save",
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscGroupMemberData = createAsyncThunk(
  "smsc/groupMember-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/groupmember/delete/{groupMemberId}?groupMemberId=${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscGroupMemberData = createAsyncThunk(
  "smsc/groupMember-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/smsc/groupmember/update?groupMemberID=` + udata.groupId,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSmscSpecialSettingData = createAsyncThunk(
  "smsc/specialSetting-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/smsc/specialSetting/save",
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSmscSpecialSettingData = createAsyncThunk(
  "smsc/specialSetting-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete(
        `/smsc/SpecialSetting/${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscSpecialSettingData = createAsyncThunk(
  "smsc/specialSetting-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(
        `/smsc/SpecialSetting/` + udata.smscId,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSmscSingleSpecialSettingData = createAsyncThunk(
  "smsc/specialSetting-edit-single",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      const { data, status } = await axios.get(
        `/smsc/SpecialSetting/` + udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const smscSlice = createSlice({
  name: "smsc",
  initialState,
  reducers: {
    clearStateSmsc: (state, { payload }) => {
      state.smscData = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    clearStateCreateSmsc: (state, { payload }) => {
      state.createSmsc = {};
      state.isFetching = false;
      state.isSuccessCreateSmsc = false;
      state.isErrorCreateSmsc = false;
      state.isErrorMessageCreateSmsc = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSmscData.fulfilled, (state, action) => {
        state.smscData = action.payload.data;
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(getSmscData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscData.rejected, (state, action) => {
        state.smscData = [];
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(createSmscData.fulfilled, (state, action) => {
        state.createSmsc = action.payload;
        state.isFetching = false;
        state.isSuccessCreateSmsc = true;
      })
      .addCase(createSmscData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscData.rejected, (state, action) => {
        state.isFetching = false;
        state.isErrorCreateSmsc = true;
        state.isErrorMessageCreateSmsc = action.payload
          ? action.payload.message
          : "Internal error";
      })
      .addCase(deleteSmscData.fulfilled, (state, action) => {
        state.isFetching = false;

        if (state.smscData.length === 1) state.smscData = [];
      })
      .addCase(deleteSmscData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscFlag.fulfilled, (state, action) => {
        state.isFetching = false;
        state.flagData = action.payload.data;
      })
      .addCase(getSmscFlag.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscFlag.rejected, (state, action) => {
        state.flagData = [];
        state.isFetching = false;
      })
      .addCase(getSmscStatus.fulfilled, (state, action) => {
        state.isFetching = false;
        state.statusData = action.payload.data;
      })
      .addCase(getSmscStatus.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscStatus.rejected, (state, action) => {
        state.statusData = [];
        state.isFetching = false;
      })
      .addCase(getSmscLimitsData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscLimitsData = action.payload.data;
      })
      .addCase(getSmscLimitsData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscLimitsData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscLimitsData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscLimitsData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscLimitsData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscLimitsData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscLimitsData.length === 1) state.smscLimitsData = [];
      })
      .addCase(deleteSmscLimitsData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscLimitsData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscLimitsData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscLimitsData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscLimitsData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscLoopingData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscLoopingData = action.payload.data;
      })
      .addCase(getSmscLoopingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscLoopingData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscLoopingData = [];
      })
      .addCase(createSmscLoopingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscLoopingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscLoopingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscLoopingData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscLoopingData.length === 1) state.smscLoopingData = [];
      })
      .addCase(deleteSmscLoopingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscLoopingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscLoopingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscLoopingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscLoopingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscHLRStatusData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscHLRStatusData = action.payload.data;
      })
      .addCase(getSmscHLRStatusData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscHLRStatusData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscHLRData = [];
      })
      .addCase(updateSmscHLRFlagData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(updateSmscHLRFlagData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateSmscHLRFlagData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscHLRData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscHLRData = action.payload.data;
      })
      .addCase(getSmscHLRData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscHLRData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscHLRData = [];
      })
      .addCase(createSmscHLRData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscHLRData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscHLRData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscHLRData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscHLRData.length === 1) state.smscHLRData = [];
      })
      .addCase(deleteSmscHLRData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscHLRData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscHLRSingleData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscHLRSingleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscHLRSingleData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscHLRData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscHLRData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscHLRData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscBlockingData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscBlockingData = action.payload.data;
      })
      .addCase(getSmscBlockingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscBlockingData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscBlockingData = [];
      })
      .addCase(createSmscBlockingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscBlockingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscBlockingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscBlockingData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscBlockingData.length === 1) state.smscBlockingData = [];
      })
      .addCase(deleteSmscBlockingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscBlockingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscBlockingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscBlockingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscBlockingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscScheduleData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscScheduleData = action.payload.data;
      })
      .addCase(getSmscScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscScheduleData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscScheduleData = [];
      })
      .addCase(createSmscScheduleData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscScheduleData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscScheduleData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscScheduleData.length === 1) state.smscScheduleData = [];
      })
      .addCase(deleteSmscScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscScheduleData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscScheduleData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscScheduleData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscScheduleData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscGroupData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscGroupData = action.payload.data;
      })
      .addCase(getSmscGroupData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscGroupData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscGroupData = [];
      })
      .addCase(createSmscGroupData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscGroupData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscGroupData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscGroupData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscGroupData.length === 1) state.smscGroupData = [];
      })
      .addCase(deleteSmscGroupData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscGroupData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscGroupData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscGroupData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscGroupData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getSmscGroupMemberData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.smscGroupMemberData = action.payload?.data?.members;
      })
      .addCase(getSmscGroupMemberData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getSmscGroupMemberData.rejected, (state, action) => {
        state.isFetching = false;
        state.smscGroupMemberData = [];
      })
      .addCase(createSmscGroupMemberData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscGroupMemberData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscGroupMemberData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscGroupMemberData.fulfilled, (state, action) => {
        state.isFetching = false;
        if (state.smscGroupMemberData.length === 1)
          state.smscGroupMemberData = [];
      })
      .addCase(deleteSmscGroupMemberData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscGroupMemberData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscGroupMemberData.fulfilled, (state, action) => {
        state.isFetchingGroupMemberUpdate = false;
      })
      .addCase(editSmscGroupMemberData.pending, (state) => {
        state.isFetchingGroupMemberUpdate = true;
      })
      .addCase(editSmscGroupMemberData.rejected, (state, action) => {
        state.isFetchingGroupMemberUpdate = false;
      })
      .addCase(createSmscSpecialSettingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(createSmscSpecialSettingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(createSmscSpecialSettingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscSpecialSettingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(deleteSmscSpecialSettingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteSmscSpecialSettingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscSpecialSettingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscSpecialSettingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscSpecialSettingData.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscSingleSpecialSettingData.fulfilled, (state, action) => {
        state.isFetching = false;
      })
      .addCase(editSmscSingleSpecialSettingData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(editSmscSingleSpecialSettingData.rejected, (state, action) => {
        state.isFetching = false;
      });
  },
});

export const {
  clearStateSmsc,
  clearStateCreateSmsc,
  clearStateDeleteSmsc,
  clearStateEditSmsc,
} = smscSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStateSmsc());
  };
}

export function clearStateCreate() {
  return async (dispatch) => {
    dispatch(clearStateCreateSmsc());
  };
}

export function clearStateDelete() {
  return async (dispatch) => {
    dispatch(clearStateDeleteSmsc());
  };
}

export function clearStateEdit() {
  return async (dispatch) => {
    dispatch(clearStateEditSmsc());
  };
}

export const smscSelector = (state) => state.smsc;

export default smscSlice.reducer;
