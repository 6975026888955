import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../Layouts/Container";
import { getLast10thDate } from "../../../Wrapper/CommonFun";
import csvIcon from "../../../assets/img/csvicon.svg";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import pdfIcon from "../../../assets/img/pdficon.svg";
import xlsIcon from "../../../assets/img/xlsicon.svg";
import { useFetch } from "../../../hooks/useFetch";
import { useDownloadData } from "../../../hooks/usePaginationNDownload";
import { hasRoles, showToast } from "../../../lib/utility";
import { middleware } from "../../../redux/store";
import { authSelector } from "../../Auth/AuthSlice";
import CloseButton from "../../Common/CloseButton";
import CustomSelect from "../../Common/CustomSelect";
import Modal from "../../Common/Modal";
import TableWithHeader from "../../Common/TableWithHeader";
import SubmissionTable from "../DataTable/SubmissionTable";
import SubmissionFilter from "../ReportFilterModal/SubmissionFilter";
import {
  getSavedFilter,
  reportSelector,
  submissionUserData,
} from "../ReportSlice";

const SubmissionReport = ({ modal, toggleModal, isSeller = false }) => {
  let { startDate, endDate } = getLast10thDate();

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [savedFilterList, setSavedFilterList] = useState([]);
  const [filterData, setFilterData] = useState({
    enddate: startDate.split(" ")[0] + " 23:59",
    startdate: endDate.split(" ")[0] + " 00:00",
    reportType: null,
    country: "%",
    operator: "%",
  });

  const downloadTypes = [
    { value: "csv", label: "CSV", image: csvIcon },
    { value: "pdf", label: "PDF", image: pdfIcon },
    { value: "xls", label: "XLSX", image: xlsIcon },
  ];

  const dispatch = useDispatch();
  const { submissionUser, errorMessageSubmissionUser, isFetching } =
    useSelector(reportSelector);
  const { user } = useSelector(authSelector);

  const { downloadContent, fileType, setFileType } = useDownloadData(
    submissionUser,
    {
      reportName: "Submission Report",
      mappable: (data) =>
        data?.map((row) => ({
          Date: row.startDate ?? "-",
          Count: row.totalSmsSend ?? 0,
          ...(filterData.country && filterData.country !== "%"
            ? { Country: row.country }
            : {}),
          ...(filterData.status && filterData.status !== "%"
            ? { Status: row.messageStatus }
            : {}),
        })),
    }
  );

  const handleResetFilter = (filterData) => {
    getSubmissionDataOnce();
    setFilterData((prev) => filterData ?? prev);
  };
  const handleApplyFilter = (obj, searchValue) => {
    let filterObj = {
      messageStatus: obj.status !== "%" ? obj.status : undefined,
      senderId: obj.senderID !== "" ? obj.senderID : undefined,
      country: obj.country !== "%" ? obj.country : undefined,
      reportType: obj.time,
    };
    if (obj.clientId) {
      filterObj.clientId = obj.clientId;
    }
    if (obj.time === null) {
      filterObj.startdate = obj.startDate.split(" ")[0] + " 00:00";
      filterObj.enddate = obj.endDate.split(" ")[0] + " 23:59";
    } else if (obj.time === "Monthly") {
      filterObj.startdate = obj.month + "-01 00:00";
      filterObj.enddate = "2020-01-01 00:00";
    } else {
      filterObj.startdate = obj.date + " 00:00";
      filterObj.enddate = "2020-01-01 00:00";
    }

    setFilterData(filterObj);
    getSubmissionDataOnce(filterObj, searchValue);
  };
  function getSubmissionDataOnce(obj) {
    dispatch(
      submissionUserData({
        ...(hasRoles("admin,superadmin,system,seller,manager", user)
          ? { clientId: "%" }
          : {}),
        ...filterData,
        ...obj,
      })
    ).then((action) =>
      middleware(action, "Submission Report", (action) => {
        if (action.error || (action.payload && action.payload?.error)) {
          showToast(
            errorMessageSubmissionUser ||
              action.payload?.message ||
              "Error while getting  Report",
            "warning"
          );
          return;
        }
      })
    );
  }
  const handleFileTypeChange = (e) => {
    setFileType(e);
  };
  const handleSavedFilterClick = (values) => {
    values.searchValue && setSearchVal(values.searchValue);
    handleApplyFilter(values, values.searchValue);
  };
  const getSavedFiltersList = () => {
    dispatch(getSavedFilter("SUBMISSION")).then((action) =>
      middleware(action, "Saved Filter", () => {
        setSavedFilterList(action.payload.data);
      })
    );
  };
  useFetch(() => {
    getSubmissionDataOnce();
    getSavedFiltersList();
  });

  const RenderHeader = ({ children }) => (
    <>
      {isSeller && (
        <div className="d-flex justify-content-between mb-3">
          <p className="fs-24 fw-600 text-dark m-0">Submission Report</p>
          <div className="d-flex">
            <div className="d-flex dwn-btn-border">
              <div
                style={{
                  position: "relative",
                  zIndex: 5,
                  borderRight: "1px solid #b5b5b5",
                  minWidth: 70,
                }}
              >
                <CustomSelect
                  selectData={{
                    fileType,
                    handleFileTypeChange,
                    downloadTypes,
                  }}
                />
              </div>
              <button
                type="button"
                className="btn dwn-btn"
                onClick={downloadContent}
              >
                Download
                <img
                  src={DownloadIcon}
                  alt=""
                  loading="lazy"
                  className="ms-2"
                />
              </button>
            </div>
          </div>
        </div>
      )}
      <TableWithHeader
        name={"Submission"}
        search={{
          searchVal,
          setSearchVal,
          saveFilterKey: "SUBMISSION",
          filterData,
          refreshFilter: getSavedFiltersList,
        }}
        setShowFilterModal={setShowFilterModal}
        showSavedFilter={savedFilterList}
        handleSavedFilterClick={handleSavedFilterClick}
        customChildren={
          <>{!isSeller && <CloseButton onClick={toggleModal} theme="dark" />}</>
        }
        customChildrenPlacement={"last"}
      >
        {children}
      </TableWithHeader>
    </>
  );

  const RenderTable = () => (
    <div
      className="commonTableHeader bg-white"
      style={{
        border: "1px solid #dedede",
        borderTop: "none",
        borderRadius: "0 0 10px 10px",
      }}
    >
      {!isSeller && (
        <div className="d-flex justify-content-end m-3">
          <div className="d-flex dwn-btn-border">
            <div
              style={{
                position: "relative",
                zIndex: 5,
                borderRight: "1px solid #b5b5b5",
                minWidth: 70,
              }}
            >
              <CustomSelect
                selectData={{
                  fileType,
                  handleFileTypeChange,
                  downloadTypes,
                }}
              />
            </div>
            <button
              type="button"
              className="btn dwn-btn"
              onClick={downloadContent}
            >
              Download
              <img src={DownloadIcon} alt="" loading="lazy" className="ms-2" />
            </button>
          </div>
        </div>
      )}
      <SubmissionTable
        submissionTableData={submissionUser}
        isFetching={isFetching}
        country={filterData.country}
        status={filterData.messageStatus}
      />
    </div>
  );
  return isSeller ? (
    <Container>
      <RenderHeader>
        <RenderTable />
      </RenderHeader>
      {showFilterModal && (
        <SubmissionFilter
          modal={showFilterModal}
          closeModal={() => {
            setShowFilterModal(false);
            getSavedFiltersList();
          }}
          onResetFilter={handleResetFilter}
          onApplyFilter={handleApplyFilter}
        />
      )}
    </Container>
  ) : (
    <Modal justify="center" width={950} show={modal} close={toggleModal}>
      <RenderHeader>
        <RenderTable />
      </RenderHeader>
      {showFilterModal && (
        <SubmissionFilter
          modal={showFilterModal}
          closeModal={() => {
            setShowFilterModal(false);
            getSavedFiltersList();
          }}
          onResetFilter={handleResetFilter}
          onApplyFilter={handleApplyFilter}
        />
      )}
    </Modal>
  );
};

export default SubmissionReport;
