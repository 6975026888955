import React from "react";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const Container = ({ children }) => {
  return (
    <div className="d-flex layoutContainer">
      <Sidebar />
      <div className="flex-grow-1 main">
        <Navbar />
        <div className="contents position-relative">{children}</div>
      </div>
    </div>
  );
};

export default Container;
