import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../redux/axios";

const initialState = {
  isFetchingRoutingGet: false,
  isFetchingCopyRoute: false,
  isFetchingUserList: false,
  isFetchingSearchBasic: false,
  isFetchingCheckExisting: false,
  isFetchingCheckExistingSender: false,
  isFetchingSaveRoute: false,
  isFetchingSearchLookup: false,
  isFetchingSearchOptional: false,
  isFetchingSender: false,
  isFetchingPackage: false,

  isFetchingModifyBasic: false,
  isFetchingDeleteBasic: false,
  isFetchingModifyHLR: false,
  isFetchingDeleteHLR: false,
  isFetchingModifyOptional: false,
  isFetchingDeleteOptional: false,

  isFetchingImportRoute: false,
  isFetchingExportRoute: false,
};

export const importRouteData = createAsyncThunk(
  "routing/import-data",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        `routes/import?isHlrEnabled=${udata.hlr}&isOptionalRoute=${udata.optional}&isMmsEnabled=${udata.mms}&shouldReplaceExisting=${udata.shouldReplaceExisting}`,
        {
          file: udata.file,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const exportRouteData = createAsyncThunk(
  "routing/export-data",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status, headers } = await axios.post(
        `routes/export`,
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status, headers };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const listUsers = createAsyncThunk(
  "routing/list-users",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(
        `routes/user-list?purpose=${udata}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getHLRList = createAsyncThunk(
  "routing/get-hlr",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(
        `routes/get-hlr?ids=${udata.ids}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getBasicList = createAsyncThunk(
  "routing/get-basic",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(
        `routes/get-basic?ids=${udata.ids}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getOptionalList = createAsyncThunk(
  "routing/get-optional",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get(
        `routes/get-optional?ids=${udata.ids}`,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchBasicRouting = createAsyncThunk(
  "routing/search-basic",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/search-basic", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchLookupRouting = createAsyncThunk(
  "routing/search-lookup",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/search-lookup", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchOptionalRouting = createAsyncThunk(
  "routing/search-optional",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "routes/search-optional",
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const modifyBasicRoutes = createAsyncThunk(
  "routing/basic-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put("routes/basic-update", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteBasicRoutes = createAsyncThunk(
  "routing/basic-delete",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.delete("routes/basic-delete", {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
        data: udata,
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const undoBasicRoute = createAsyncThunk(
  "routing/basic-undo",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/basic-undo", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const previousBasicRoute = createAsyncThunk(
  "routing/basic-previous",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "routes/basic-previous",
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const updateOptionalRoutes = createAsyncThunk(
  "routing/optional-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(
        "routes/optional-update",
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const undoOptionalRoutes = createAsyncThunk(
  "routing/optional-undo",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/optional-undo", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const previousOptionalRoutes = createAsyncThunk(
  "routing/optional-previous",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "routes/optional-previous",
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const modifyHLRRoutes = createAsyncThunk(
  "routing/hlr-update",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put("routes/hlr-update", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const undoHLRRoutes = createAsyncThunk(
  "routing/hlr-undo",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/hlr-undo", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const previousHLRRoutes = createAsyncThunk(
  "routing/hlr-previous",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/hlr-previous", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const hlrBasicRouteList = createAsyncThunk(
  "routing/hlr-basic",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/hlr-basic", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const hlrOptionalRouteList = createAsyncThunk(
  "routing/hlr-optional",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/hlr-optional", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const copyRouting = createAsyncThunk(
  "routing/copy-route",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/copy-route", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const checkExisting = createAsyncThunk(
  "routing/check-existing",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post(
        "/routes/check-existing",
        udata,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const saveRoute = createAsyncThunk(
  "routing/save",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/save", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createPackageRoutingData = createAsyncThunk(
  "routing/package",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/package", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deletePackageRoutingData = createAsyncThunk(
  "routing/package",
  async (id, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const { data, status } = await axios.delete(`routes/package/${id}`, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editRoutingDataPackage = createAsyncThunk(
  "routing/package/edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.put(
        `routes/package/${udata.id}`,
        udata,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getpackageRoutingData = createAsyncThunk(
  "routing/package",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("routes/package", {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
        udata,
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const senderRouteCheckExisting = createAsyncThunk(
  "routes/check-sender",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/check-sender/", udata, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const getSenderRoutingData = createAsyncThunk(
  "routing/sender-get",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.get("routes/sender", {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
        udata,
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const deleteSenderRoutingData = createAsyncThunk(
  "routing/sender-delte",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const { data, status } = await axios.delete(
        `routes/sender?ids=${udata}`,
        {
          headers: {
            Authorization: `Bearer ${tokenStr}`,
          },
        }
      );

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const editSenderRoutingData = createAsyncThunk(
  "routing/sender-edit",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;
      const { data, status } = await axios.put(`routes/sender`, udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { ...data, status };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const createSenderRoutingData = createAsyncThunk(
  "routes/sender-create",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/sender", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const searchSenderRoutingData = createAsyncThunk(
  "routes/sender-search",
  async (udata, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const tokenStr = auth.user.accessToken;

      const { data, status } = await axios.post("routes/sender/search", udata, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
        },
      });

      if (status.toString().startsWith("2")) {
        return { data, status };
      } else {
        return thunkAPI.rejectWithValue({ data, status });
      }
    } catch (err) {
      if (!err.response) {
        console.error("err ===>", err);
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const routingSlice = createSlice({
  name: "routing",
  initialState,
  reducers: {
    clearStateRouting: (state) => {
      state.isFetchingRoutingGet = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(copyRouting.pending, (state) => {
        state.isFetchingCopyRoute = true;
      })
      .addCase(copyRouting.fulfilled, (state) => {
        state.isFetchingCopyRoute = false;
      })
      .addCase(copyRouting.rejected, (state) => {
        state.isFetchingCopyRoute = false;
      })
      .addCase(listUsers.pending, (state) => {
        state.isFetchingUserList = true;
      })
      .addCase(listUsers.fulfilled, (state) => {
        state.isFetchingUserList = false;
      })
      .addCase(listUsers.rejected, (state) => {
        state.isFetchingUserList = false;
      })
      .addCase(searchBasicRouting.pending, (state) => {
        state.isFetchingSearchBasic = true;
      })
      .addCase(searchBasicRouting.fulfilled, (state) => {
        state.isFetchingSearchBasic = false;
      })
      .addCase(searchBasicRouting.rejected, (state) => {
        state.isFetchingSearchBasic = false;
      })
      .addCase(searchLookupRouting.pending, (state) => {
        state.isFetchingSearchLookup = true;
      })
      .addCase(searchLookupRouting.fulfilled, (state) => {
        state.isFetchingSearchLookup = false;
      })
      .addCase(searchLookupRouting.rejected, (state) => {
        state.isFetchingSearchLookup = false;
      })
      .addCase(checkExisting.pending, (state) => {
        state.isFetchingCheckExisting = true;
      })
      .addCase(checkExisting.fulfilled, (state) => {
        state.isFetchingCheckExisting = false;
      })
      .addCase(checkExisting.rejected, (state) => {
        state.isFetchingCheckExisting = false;
      })
      .addCase(saveRoute.pending, (state) => {
        state.isFetchingSaveRoute = true;
      })
      .addCase(saveRoute.fulfilled, (state) => {
        state.isFetchingSaveRoute = false;
      })
      .addCase(saveRoute.rejected, (state) => {
        state.isFetchingSaveRoute = false;
      })
      .addCase(searchOptionalRouting.pending, (state) => {
        state.isFetchingSearchOptional = true;
      })
      .addCase(searchOptionalRouting.fulfilled, (state) => {
        state.isFetchingSearchOptional = false;
      })
      .addCase(searchOptionalRouting.rejected, (state) => {
        state.isFetchingSearchOptional = false;
      })
      .addCase(getpackageRoutingData.pending, (state) => {
        state.isFetchingPackage = true;
      })
      .addCase(getpackageRoutingData.fulfilled, (state) => {
        state.isFetchingPackage = false;
      })
      .addCase(getpackageRoutingData.rejected, (state) => {
        state.isFetchingPackage = false;
      })
      .addCase(getSenderRoutingData.pending, (state) => {
        state.isFetchingSender = true;
      })
      .addCase(getSenderRoutingData.fulfilled, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(getSenderRoutingData.rejected, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(searchSenderRoutingData.pending, (state) => {
        state.isFetchingSender = true;
      })
      .addCase(searchSenderRoutingData.fulfilled, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(searchSenderRoutingData.rejected, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(createSenderRoutingData.pending, (state) => {
        state.isFetchingSender = true;
      })
      .addCase(createSenderRoutingData.fulfilled, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(createSenderRoutingData.rejected, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(senderRouteCheckExisting.pending, (state) => {
        state.isFetchingSender = true;
      })
      .addCase(senderRouteCheckExisting.fulfilled, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(senderRouteCheckExisting.rejected, (state) => {
        state.isFetchingSender = false;
      })
      .addCase(modifyBasicRoutes.pending, (state) => {
        state.isFetchingModifyBasic = true;
      })
      .addCase(modifyBasicRoutes.fulfilled, (state) => {
        state.isFetchingModifyBasic = false;
      })
      .addCase(modifyBasicRoutes.rejected, (state) => {
        state.isFetchingModifyBasic = false;
      })
      .addCase(deleteBasicRoutes.pending, (state) => {
        state.isFetchingDeleteBasic = true;
      })
      .addCase(deleteBasicRoutes.fulfilled, (state) => {
        state.isFetchingDeleteBasic = false;
      })
      .addCase(deleteBasicRoutes.rejected, (state) => {
        state.isFetchingDeleteBasic = false;
      })
      .addCase(modifyHLRRoutes.pending, (state) => {
        state.isFetchingModifyHLR = true;
      })
      .addCase(modifyHLRRoutes.fulfilled, (state) => {
        state.isFetchingModifyHLR = false;
      })
      .addCase(modifyHLRRoutes.rejected, (state) => {
        state.isFetchingModifyHLR = false;
      })
      .addCase(updateOptionalRoutes.pending, (state) => {
        state.isFetchingModifyOptional = true;
      })
      .addCase(updateOptionalRoutes.fulfilled, (state) => {
        state.isFetchingModifyOptional = false;
      })
      .addCase(updateOptionalRoutes.rejected, (state) => {
        state.isFetchingModifyOptional = false;
      })
      .addCase(exportRouteData.pending, (state) => {
        state.isFetchingExportRoute = true;
      })
      .addCase(exportRouteData.fulfilled, (state) => {
        state.isFetchingExportRoute = false;
      })
      .addCase(exportRouteData.rejected, (state) => {
        state.isFetchingExportRoute = false;
      })
      .addCase(importRouteData.pending, (state) => {
        state.isFetchingImportRoute = true;
      })
      .addCase(importRouteData.fulfilled, (state) => {
        state.isFetchingImportRoute = false;
      })
      .addCase(importRouteData.rejected, (state) => {
        state.isFetchingImportRoute = false;
      });
  },
});

export const { clearStateRouting } = routingSlice.actions;

export function clearStateRoutingAction() {
  return async (dispatch) => {
    dispatch(clearStateRouting());
  };
}

export const routingSelector = (state) => state.routing;
export default routingSlice.reducer;
