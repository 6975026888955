import React, { useState } from "react";
import { BsEye, BsGlobe2, BsKey, BsPerson } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  createLandingInfo,
  updateLandingInfo,
} from "../../../components/Auth/AuthSlice";
import CloseButton from "../../../components/Common/CloseButton";
import LoaderComponent from "../../../components/Common/LoaderComponent";
import Modal from "../../../components/Common/Modal";
import {
  getProfileData,
  profileSelector,
} from "../../../components/Profile/ProfileSlice";
import { storeItem } from "../../../lib/utility";
import {
  middleware,
  middlewareCreate,
  middlewareUpdate,
} from "../../../redux/store";
import "../CustomizePanel/CustomizePanel.scss";

export default function CustomizePanelFilter({ modal, toggleModal }) {
  const [logo, setLogo] = useState(null);
  const [icons, setIcons] = useState([]);
  const [content, setContent] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const { isFetchingLoginPanelSave } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { getProfile } = useSelector(profileSelector);
  const refId = getProfile.referenceID;
  const isButtonDisabled =
    logo === null || icons.length === 0 || content === "";

  const handleNextClick = () => {
    setShowPreview((prev) => !prev);
  };

  const modalClose = () => {
    setContent("");
    setLogo(null);
    setIcons([]);
    setShowPreview(false);
    toggleModal();
  };

  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleIconsChange = (e) => {
    setIcons(e.target.files);
  };

  const getProfileDataUpdated = () => {
    dispatch(getProfileData()).then((action) =>
      middleware(action, "profile", (action) => {
        globalThis.profileData = action.payload;
        storeItem("profileData", action.payload);
      })
    );
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    dispatch(updateLandingInfo({ content, logo, icons, refId })).then(
      (action) =>
        middlewareUpdate(
          action,
          "Landing Info",
          () => {
            getProfileDataUpdated();
          },
          undefined,
          {
            refresh: toggleModal,
          }
        )
    );
  };

  const handleSubmitCreate = (e) => {
    e.preventDefault();
    dispatch(createLandingInfo({ content, logo, icons, refId })).then(
      (action) =>
        middlewareCreate(
          action,
          "Landing Info",
          () => {
            getProfileDataUpdated();
          },
          undefined,
          {
            close: toggleModal,
          }
        )
    );
  };

  return (
    <>
      {!showPreview && (
        <Modal
          show={modal}
          close={modalClose}
          justify="center"
          width={550}
          overflow="auto"
        >
          <div
            className="modal-header"
            style={{ position: "sticky", top: 0, zIndex: 5 }}
          >
            <h6 className="modal-title d-flex align-items-center gap-2 fs-22">
              <FaUsers />
              Customize Admin Login Panel
            </h6>
            <CloseButton theme={"dark"} onClick={modalClose} />
          </div>
          <div className="modal-body bg-white">
            {isFetchingLoginPanelSave ? (
              <LoaderComponent />
            ) : (
              <>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="content" className="col-form-label h1">
                      Content
                    </label>
                  </div>
                  <div className="col">
                    <input
                      className="input-style form-control col-9"
                      id="content"
                      type="text"
                      onChange={(e) => setContent(e.target.value)}
                      placeholder="Enter your content here"
                      required
                    />
                  </div>
                </div>
                <div className="row align-items-center mb-4">
                  <div className="col-3">
                    <label htmlFor="logo" className="col-form-label h1">
                      Logo
                    </label>
                  </div>
                  <div className="col">
                    <input
                      className="input-style form-control col-9"
                      id="logo"
                      type="file"
                      accept="image/*"
                      onChange={handleLogoChange}
                      required
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-3">
                    <label htmlFor="icons" className="col-form-label h1">
                      Icons
                    </label>
                  </div>
                  <div className="col">
                    <input
                      className="input-style form-control col-9"
                      id="icons"
                      type="file"
                      accept="image/*"
                      onChange={handleIconsChange}
                      multiple
                      required
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer bg-white">
            <div className="row g-3 w-100">
              <div className="col-md-6 mt-0">
                {refId ? (
                  <button
                    className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                    onClick={handleSubmitUpdate}
                    disabled={isButtonDisabled}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                    disabled={isButtonDisabled}
                    onClick={refId ? handleSubmitUpdate : handleSubmitCreate}
                  >
                    {refId ? "Update" : "Create"}
                  </button>
                )}
              </div>
              <div className="col-md-6 mt-0">
                <button
                  type="button"
                  className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                  onClick={handleNextClick}
                  disabled={isButtonDisabled}
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {showPreview && (
        <Modal show={modal} close={modalClose} justify="center" width={1100}>
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center fs-22">
              Preview
            </h6>
            <CloseButton theme={"dark"} onClick={modalClose} />
          </div>

          <div className="modal-body py-2 px-3 bg-white">
            {isFetchingLoginPanelSave ? (
              <LoaderComponent />
            ) : (
              showPreview && (
                <>
                  <div className="row border border-secondary">
                    <div className="col-xl-8 col-lg-7 col-md-6 d-none d-md-block p-5 text-white">
                      <div className="w-100 h-100 d-flex flex-column justify-content-between">
                        {logo && (
                          <img
                            src={URL.createObjectURL(logo)}
                            alt=""
                            style={{ width: "80px" }}
                            loading="lazy"
                          />
                        )}
                        <div
                          className="slogan d-flex flex-column gap-4"
                          style={{
                            maxWidth: "550px",
                          }}
                        >
                          <h1 className="fw-700" style={{ color: "#334163" }}>
                            {content}
                          </h1>
                          {content && <h4>Since 2003</h4>}
                        </div>
                        <div className="flag-container">
                          {Array.from(icons).map((icon, index) => (
                            <div key={index} className="flag-elements">
                              <img
                                src={URL.createObjectURL(icon)}
                                alt=""
                                style={{ width: "55px" }}
                                loading="lazy"
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-sm-12 col-md-6 p-5 auth-bg position-relative bg-primary">
                      <div className="auth-right position-relative bg-transparent">
                        <div className="signin-form">
                          <h2 className="text-center text-light-gray">
                            Sign In
                          </h2>
                          <p className="fs-16 fw-400 text-light-gray">
                            Sign in to stay connected
                          </p>
                          <form>
                            <div className="mb-3">
                              <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0">
                                  <BsPerson />
                                </span>
                                <input
                                  type="text"
                                  placeholder="Username"
                                  className="form-control"
                                  id="username"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="mb-3 password-toggle">
                              <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0">
                                  <BsKey />
                                </span>
                                <input
                                  type="password"
                                  className="form-control password-field"
                                  id="password"
                                  placeholder="Password"
                                  disabled
                                />
                                <span className="toggle-password">
                                  <BsEye />
                                </span>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="input-group mb-3">
                                <span className="input-group-text bg-white border-end-0">
                                  <BsGlobe2 />
                                </span>
                                <div className="dropdown form-control p-0">
                                  <select
                                    className="form-select border-0"
                                    disabled
                                  >
                                    <option value="action-1">English</option>
                                  </select>
                                </div>
                              </div>

                              <div className="d-flex align-items-center justify-content-between">
                                <div className="mb-3 form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="rememberMe"
                                    disabled
                                  />
                                  <label
                                    className="form-check-label fs-16 fw-400 text-light-gray"
                                    htmlFor="rememberMe"
                                  >
                                    Remember me
                                  </label>
                                </div>
                                <div className="mb-3">
                                  <p
                                    style={{ cursor: "pointer" }}
                                    to="/forgot"
                                    className="forget-pass m-0 fs-16 fw-600 text-gray text-decoration-none"
                                  >
                                    Forgot password?
                                  </p>
                                </div>
                              </div>
                              <button
                                className="btn cbtn fw-600 br-12 w-100 bg-blue"
                                disabled
                              >
                                Sign In
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
          <div
            className="modal-footer d-block bg-white"
            style={{ position: "sticky", bottom: 0, zIndex: 5 }}
          >
            <div className="row g-3">
              <div className="col-6 mt-0">
                <button
                  type="button"
                  className="btn fs-16 fw-500 br-12 w-100 px-4 py-2 border_lightgray_1 me-2"
                  onClick={() => setShowPreview(false)}
                >
                  Edit
                </button>
              </div>
              <div className="col-6 mt-0">
                <button
                  type="submit"
                  className="cbtn text-white btn w-100 fs-16 fw-500 br-12 px-4 py-2 border_lightgray_1"
                  onClick={refId ? handleSubmitUpdate : handleSubmitCreate}
                >
                  {refId ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
