import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../redux/axios";

const initialState = {
  notificationData: [],
  isFetching: false,
  notificationAlertToAdmin: {},
  isFetchingAlert: false,
};

export const getNotificationData = createAsyncThunk(
  "notification/get",
  async (force, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/login/notification`;

      const { data, status } = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        if (Array.isArray(data)) {
          return { data: data.reverse(), status };
        }
        return { data, status };
      } else if (status.toString().startsWith("4")) {
        thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


export const postNotificationAlertToAdmin = createAsyncThunk(
  "notification/get/alert/to/admin",
  async ({ body, username }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/login/notification?username=${username}`;
      const { data, status } = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${tokenStr}`,
          Username: username,
        },
      });
      if (status.toString().startsWith("2")) {
        if (Array.isArray(data)) {
          return { data: data.reverse(), status };
        }
        return { data, status };
      } else if (status.toString().startsWith("4")) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/delete", // Action type
  async (notificationId, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url = `/login/notification/`;

      if (notificationId) {
        url += notificationId;
      }

      const { data, status } = await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (status.toString().startsWith("2")) {
        return notificationId;
      } else if (status.toString().startsWith("4")) {
        thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const markAsReadNotification = createAsyncThunk(
  "notification/read", // Action type
  async (notificationId, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      let tokenStr = auth.user.accessToken;
      let url =
        `/login/notification?id=` + notificationId + "&read=yes&reference=no";

      const { data, status } = await axios.put(
        url,
        { read: "yes", reference: "no" },
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (status.toString().startsWith("2")) {
        return notificationId;
      } else if (status.toString().startsWith("4")) {
        thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearStatenotification: (state, { payload }) => {
      state.notificationData = [];
      state.isFetching = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markAsReadNotification.fulfilled, (state, { payload }) => {
        state.notificationData = state.notificationData.map((ntf) => {
          if (ntf.id === payload) {
            return { ...ntf, read: "yes" };
          }
          return ntf;
        });
      })
      .addCase(getNotificationData.fulfilled, (state, action) => {
        state.notificationData = action.payload.data;
        state.isFetching = false;
      })
      .addCase(getNotificationData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getNotificationData.rejected, (state, action) => {
        state.notificationData = [];
        state.isFetching = false;
      })
      // .addCase(getNotificationAlertToAdmin.fulfilled, (state, action) =>{
      //   state.notificationAlertToAdmin = action.payload.data;
      //   state.isFetchingAlert = false
      // })
      // .addCase(getNotificationAlertToAdmin.pending, (state, action) =>{
      //   state.isFetching = true
      // })
      // .addCase(getNotificationAlertToAdmin.rejected, (state, action) =>{
      //   state.notificationAlertToAdmin = {};
      //   state.isFetchingAlert = false
      // })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isFetching = false;
        state.notificationData = state.notificationData.filter(
          (ntf) => ntf.id !== action.payload
        );
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.notificationData = [];
        state.isFetching = false;
      });
  },
});

export const { clearStatenotification } = notificationSlice.actions;

export function clearState() {
  return async (dispatch) => {
    dispatch(clearStatenotification());
  };
}

export const notificationSelector = (state) => state.notification;

export default notificationSlice.reducer;
